/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, forkJoin } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { FIAT } from '../../../shared/constants/fiat.constant';
import { CustomError } from '../../../shared/models/error.model';
import { ResponseSuccess } from '../../../shared/models/generic-response.model';
import { Page } from '../../../shared/models/page.model';
import { ToastService } from '../../../shared/services/toast.service';
import { pushTagAction, setAnalysisCountAction } from '../../../shared/store/actions/shared.action';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { Analysis } from '../../models/analysis.model';
import { UpdateResult } from '../../models/bulk.model';
import { CurrencyOccurences } from '../../models/currency.model';
import {
  PriceUpdateDto,
  Transaction,
  TransactionFilters,
  TransactionTypeActivation,
  TransactionWarningAggregation,
} from '../../models/transaction.model';
import { WarningOccurences } from '../../models/warning.model';
import { AnalysisService } from '../../services/analysis.service';
import { TransactionService } from '../../services/transaction.service';
import {
  getAnalysisAction,
  loadAggregatedWarningsAction,
  loadLastAnalysisAction,
  loadLastAnalysisWarningsAction,
  loadTokensWithNoPriceAction,
  loadTokensWithPriceAction,
  loadUnmatchedTransactionsWithoutLabelAction,
  matchTransactionsAction,
  refreshAnalysisAction,
  setAggregatedWarningsAction,
  setAnalysisAction,
  setAnalysisErrorAction,
  setAnalysisProgressAction,
  setIsAnalysisFailedAction,
  setLastAnalysisAction,
  setLastAnalysisWarningsAction,
  setMatchedTransactionsAction,
  setTokensWithNoPriceAction,
  setTokensWithPriceAction,
  setUnmatchedTransactionsLabelsAction,
  setUnmatchedTransactionsWithoutLabelAction,
  startAnalysisAction,
  unmatchTransactionAction,
  updateTokensPricesAction
} from '../actions/analysis.action';
import * as fromAnalysis from '../selectors/analysis.selector';
import { TransactionTag } from '../../../shared/models/tag.model';

@Injectable()
export class AnalysisEffects {
  loadLastAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadLastAnalysisAction>>(loadLastAnalysisAction),
      switchMap(() =>
        this.analysisService.getLastAnalysis().pipe(
          switchMap((lastAnalysis: Analysis) => {
            const actions: any[] = [setLastAnalysisAction({ lastAnalysis })];

            if (lastAnalysis) {
              actions.push(loadLastAnalysisWarningsAction());
            }

            return actions;
          }),
          catchError((error: CustomError) => {
            const errorMessage: string =
              this.translateService.instant(error.errorCode) === error.errorCode
                ? error.message
                : this.translateService.instant(error.errorCode);

            this.toastService.error(errorMessage);

            return EMPTY;
          })
        )
      )
    )
  );

  startAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof startAnalysisAction>>(startAnalysisAction),
      withLatestFrom(
        this.sharedStore$.pipe(select(fromShared.selectAnalysisCount))
      ),
      switchMap(
        ([action, analysisCount]: [
          ReturnType<typeof startAnalysisAction>,
          number
        ]) =>
          this.analysisService.startAnalysis().pipe(
            switchMap((analysis: Analysis) => [
              setAnalysisAction({ analysis }),
              setAnalysisCountAction({ analysisCount: analysisCount + 1 }),
              refreshAnalysisAction(),
            ]),
            catchError((error: CustomError) => [
              setIsAnalysisFailedAction({ isAnalysisFailed: true }),
              setAnalysisErrorAction({ analysisError: error }),
            ])
          )
      )
    )
  );

  getAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getAnalysisAction>>(getAnalysisAction),
      switchMap((action: ReturnType<typeof getAnalysisAction>) =>
        this.analysisService.getAnalysis(action.analysisId).pipe(
          switchMap((analysis: Analysis) => {
            const actions = [];
            actions.push(setAnalysisAction({ analysis }));

            if (analysis?.started && !analysis?.completed) {
              actions.push(refreshAnalysisAction());
            } else {
              actions.push(
                setAnalysisProgressAction({ analysisProgress: 100 })
              );
            }

            return actions;
          })
        )
      )
    )
  );

  refreshAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof refreshAnalysisAction>>(refreshAnalysisAction),
      switchMap(() => this.analysisService.getAnalysis()),
      delay(2000),
      switchMap((analysis: Analysis) => {
        const actions = [];

        actions.push(setAnalysisAction({ analysis }));

        let progress = 0;
        if (analysis) {
          if (analysis.nbOfTransactionsToProcess > 0) {
            progress =
              (100.0 * analysis.nbOfTransactionsProcessed) /
              analysis.nbOfTransactionsToProcess;
          } else if (analysis.nbOfTransactionsProcessed === 0) {
            progress = 100;
          }

          actions.push(
            setAnalysisProgressAction({ analysisProgress: progress })
          );
        }

        if (!analysis?.completed) {
          actions.push(refreshAnalysisAction());
        }

        return actions;
      })
    )
  );

  loadTokensWithNoPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTokensWithNoPriceAction>>(
        loadTokensWithNoPriceAction
      ),
      withLatestFrom(
        this.analysisStore$.pipe(select(fromAnalysis.selectAnalysis))
      ),
      switchMap(
        ([action, analysis]: [
          ReturnType<typeof loadTokensWithNoPriceAction>,
          Analysis
        ]) =>
          this.transactionService.getTokensWithNoPrice().pipe(
            switchMap((tokensWithNoPrice: CurrencyOccurences[]) => {
              const actions = [];

              tokensWithNoPrice = tokensWithNoPrice
                .filter(
                  (token: CurrencyOccurences) =>
                    !action.shitcoins.includes(token.currency)
                )
                .map((token: CurrencyOccurences) => ({
                  ...token,
                  isUpdated: false,
                }));

              actions.push(setTokensWithNoPriceAction({ tokensWithNoPrice }));
              actions.push(loadTokensWithPriceAction());

              return actions;
            })
          )
      )
    )
  );

  loadTokensWithPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTokensWithPriceAction>>(
        loadTokensWithPriceAction
      ),
      withLatestFrom(
        this.analysisStore$.pipe(select(fromAnalysis.selectTokensWithNoPrice))
      ),
      switchMap(
        ([action, tokensWithNoPrices]: [
          ReturnType<typeof loadTokensWithPriceAction>,
          CurrencyOccurences[]
        ]) =>
          this.transactionService.getFilters().pipe(
            map((filters: TransactionFilters) => {
              let tokensWithPrice: string[] = [
                ...new Set([...filters.fromCurrency, ...filters.toCurrency]),
              ];

              let noPriceTokens: string[] = [];

              if (tokensWithNoPrices) {
                noPriceTokens = tokensWithNoPrices
                  .filter((token: CurrencyOccurences) => !token.isUpdated)
                  .map((token: CurrencyOccurences) => token.currency);
              }

              tokensWithPrice = tokensWithPrice.filter(
                (token: string) =>
                  token !== `` &&
                  !FIAT.includes(token) &&
                  !noPriceTokens.includes(token)
              );

              return setTokensWithPriceAction({ tokensWithPrice });
            })
          )
      )
    )
  );

  updateTokensPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateTokensPricesAction>>(
        updateTokensPricesAction
      ),
      withLatestFrom(
        this.analysisStore$.pipe(select(fromAnalysis.selectTokensWithNoPrice))
      ),
      switchMap(
        ([action, tokensWithNoPrice]: [
          ReturnType<typeof updateTokensPricesAction>,
          CurrencyOccurences[]
        ]) => {
          let updates$: Observable<UpdateResult>[] = [];

          updates$ = action.prices.map((price) =>
            this.transactionService.updateCurrencyPrice(
              price.currency,
              price.value
            )
          );

          return forkJoin(updates$).pipe(
            mergeMap(() => {
              tokensWithNoPrice = tokensWithNoPrice.map(
                (token: CurrencyOccurences) => {
                  const updatedPrice = action.prices.find(
                    (price: PriceUpdateDto) => price.currency === token.currency
                  );

                  if (updatedPrice) {
                    return {
                      ...token,
                      isUpdated: true,
                    };
                  } else {
                    return token;
                  }
                }
              );

              this.toastService.success(this.translateService.instant(`PriceChanged`));

              return [
                setTokensWithNoPriceAction({ tokensWithNoPrice }),
                loadTokensWithPriceAction(),
              ];
            }),
            catchError(() => {
              this.toastService.error(this.translateService.instant(`ErrorOccurred`));
              return EMPTY;
            })
          );
        }
      )
    )
  );

  loadUnmatchedTransactionsWithoutLabel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUnmatchedTransactionsWithoutLabelAction>>(
        loadUnmatchedTransactionsWithoutLabelAction
      ),
      switchMap((action: ReturnType<typeof loadUnmatchedTransactionsWithoutLabelAction>) =>
        this.transactionService.getUnmatchedTransactionsWithoutLabel(action.page).pipe(
          map((unmatchedTransactionsWithoutLabel: Page<Transaction>) =>
            setUnmatchedTransactionsWithoutLabelAction({
              unmatchedTransactionsWithoutLabel
            })
          )
        )
      )
    )
  );

  // createCompensatingTransaction$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType<ReturnType<typeof createCompensatingTransactionAction>>(
  //       createCompensatingTransactionAction
  //     ),
  //     withLatestFrom(
  //       this.analysisStore$.pipe(
  //         select(fromAnalysis.selectInsufficientBalances)
  //       )
  //     ),
  //     switchMap(
  //       ([action, insufficientBalances]: [
  //         ReturnType<typeof createCompensatingTransactionAction>,
  //         TokenAndPlatformBalanceDetail[]
  //       ]) =>
  //         this.transactionService
  //           .createCompensatingTransaction(action.balance, action.reason)
  //           .pipe(
  //             map((compensatingTransaction: Transaction) => {
  //               this.toastService.success(this.translateService.instant(
  //                 `CompensatingTransactionCreated`
  //               ));

  //               insufficientBalances.find(
  //                 (balance: TokenAndPlatformBalanceDetail) =>
  //                   balance.platform === compensatingTransaction.exchange &&
  //                   balance.token === compensatingTransaction.toCurrency
  //               ).compensed = true;

  //               return setInsufficientBalancesAction({
  //                 insufficientBalances: [...insufficientBalances],
  //               });
  //             })
  //           )
  //     )
  //   )
  // );

  setUnmatchedTransactionsLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setUnmatchedTransactionsLabelsAction>>(
        setUnmatchedTransactionsLabelsAction
      ),
      switchMap(
        (action: ReturnType<typeof setUnmatchedTransactionsLabelsAction>) => {
          const labelledTransactions: Transaction[] =
            action.unmatchedTransactions.filter(
              (transaction: Transaction) => transaction.subType
            );

          return this.transactionService
            .saveTransactions(labelledTransactions)
            .pipe(
              switchMap((updatedTransactions: Transaction[]) => {
                const msg: string =
                  this.translateService.instant(`ModifiedTransaction`);

                this.toastService.success(`${updatedTransactions.length} ${msg}`);

                return EMPTY;
              })
            );
        }
      )
    ), { dispatch: false }
  );

  matchTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof matchTransactionsAction>>(
        matchTransactionsAction
      ),
      withLatestFrom(
        this.analysisStore$.pipe(
          select(fromAnalysis.selectUnmatchedTransactionsWithoutLabel)
        ),
        this.analysisStore$.pipe(select(fromAnalysis.selectMatchedTransactions))
      ),
      switchMap(
        ([action, unmatchedTransactionsWithoutLabel, matchedTransactions]: [
          ReturnType<typeof matchTransactionsAction>,
          Page<Transaction>,
          Transaction[][]
        ]) =>
          this.transactionService.matchTransactions(action.transactions).pipe(
            switchMap((res: ResponseSuccess) => {
              if (res.success) {
                this.toastService.success(this.translateService.instant(`AssociatedTransactions`));

                const tag: TransactionTag = {
                  event: `bulk_operation`,
                  operation_type: `match`
                };

                return [
                  pushTagAction({ tag }),
                  loadUnmatchedTransactionsWithoutLabelAction({}),
                  setMatchedTransactionsAction({
                    matchedTransactions: [
                      ...matchedTransactions,
                      action.transactions,
                    ],
                  }),
                ];
              } else {
                this.toastService.error(this.translateService.instant(`AssociationFailed`));
                return EMPTY;
              }
            }),
            catchError((error: CustomError) => {
              const errorMessage: string =
                this.translateService.instant(
                  error.errorCode || error.message
                ) === error.errorCode
                  ? error.message
                  : this.translateService.instant(
                    error.errorCode || error.message
                  );

              this.toastService.error(errorMessage);

              return EMPTY;
            })
          )
      )
    )
  );

  unmatchTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof unmatchTransactionAction>>(
        unmatchTransactionAction
      ),
      withLatestFrom(
        this.analysisStore$.pipe(
          select(fromAnalysis.selectMatchedTransactions)
        ),
        this.analysisStore$.pipe(
          select(fromAnalysis.selectUnmatchedTransactionsWithoutLabel)
        )
      ),
      switchMap(
        ([action, matchedTransactions, unmatchedTransactions]: [
          ReturnType<typeof unmatchTransactionAction>,
          Transaction[][],
          Page<Transaction>
        ]) =>
          this.transactionService.unmatchTransaction(action.transaction).pipe(
            switchMap((res: ResponseSuccess) => {
              if (res.success) {
                matchedTransactions.splice(
                  matchedTransactions.indexOf(res.content)
                );

                return [
                  setMatchedTransactionsAction({
                    matchedTransactions: {
                      ...matchedTransactions,
                    },
                  }),
                  loadUnmatchedTransactionsWithoutLabelAction({}),
                ];
              } else {
                this.toastService.error(
                  this.translateService.instant(`ERRORS.GENERIC`)
                );
              }

              return EMPTY;
            }),
            catchError((error: CustomError) => {
              const errorMessage: string =
                this.translateService.instant(
                  error.errorCode || error.message
                ) === error.errorCode
                  ? error.message
                  : this.translateService.instant(
                    error.errorCode || error.message
                  );

              this.toastService.error(errorMessage);

              return EMPTY;
            })
          )
      )
    )
  );

  loadLastAnalysisWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadLastAnalysisWarningsAction>>(
        loadLastAnalysisWarningsAction
      ),
      switchMap((action: ReturnType<typeof loadLastAnalysisWarningsAction>) =>
        this.transactionService.getTransactionsWarningsByType().pipe(
          map((lastAnalysisWarnings: WarningOccurences[]) => {
            // Not really aesthetic : used to init at 0 all warnings
            const warnings: WarningOccurences[] = [
              { type: `INSUFFICIENT_BALANCE`, occurences: 0 },
              { type: `UNKNOWN_PRICE`, occurences: 0 },
              { type: `WITHDRAWAL_TO_CATEGORIZE`, occurences: 0 },
            ];

            lastAnalysisWarnings.forEach((warning: WarningOccurences) => {
              warnings.forEach((w: WarningOccurences) => {
                if (w.type === warning.type) {
                  w.occurences = warning.occurences;
                }
              });
            });

            return setLastAnalysisWarningsAction({
              lastAnalysisWarnings: warnings,
            });
          })
        )
      )
    )
  );

  loadAggregatedWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAggregatedWarningsAction>>(
        loadAggregatedWarningsAction
      ),
      withLatestFrom(
        this.analysisStore$.pipe(select(fromAnalysis.selectShowNoneCriticalBalances))
      ),
      switchMap(([action, showNoneCriticalBalances]: [ReturnType<typeof loadAggregatedWarningsAction>, boolean]) =>
        this.transactionService.getAggregatedWarnings(showNoneCriticalBalances).pipe(
          map((aggregatedWarnings: TransactionWarningAggregation[]) => setAggregatedWarningsAction({
            aggregatedWarnings,
          }))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly analysisService: AnalysisService,
    private readonly transactionService: TransactionService,
    private readonly snackBar: MatSnackBar,
    private readonly analysisStore$: Store<fromAnalysis.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService
  ) { }

  getPlatformErrorCount(transactionTypes: TransactionTypeActivation[]): number {
    let count = 0;
    for (const transactionType of transactionTypes) {
      if (!transactionType.active) {
        count++;
      }
    }

    return count;
  }

  removeUnmatchedTransactionsHeaders(
    unmatchedTransactions: Transaction[]
  ): Transaction[] {
    for (let i = 0; i < unmatchedTransactions.length; i++) {
      const isRowHeader = !(unmatchedTransactions[i].id);

      if (isRowHeader && !unmatchedTransactions[i + 1]?.id) {
        unmatchedTransactions.splice(i, 1);
      }
    }

    return unmatchedTransactions;
  }
}
