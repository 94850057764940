<div
  class="report-status"
  [ngClass]="{
  'report-status--finished': assessment?.state === 'COMPLETED',
  'report-status--processing': assessment?.state !== 'COMPLETED',
}"
>
  <ng-container *ngIf="assessment?.state === 'COMPLETED'">
    <img src="/assets/img/icons/success-check.svg" alt="" />
    <p class="body-12-med">{{ 'DataComputed' | translate }}</p>
  </ng-container>

  <ng-container *ngIf="assessment?.state !== 'COMPLETED'">
    <img class="rotating" src="/assets/img/icons/report-processing.svg" alt="" />

    <div>
      <p class="body-12-med">{{ 'DataComputation' | translate }}</p>
      <p *ngIf="assessment?.eta > 0" class="eta body-12">{{ 'In' | translate }} {{ formatETA(assessment?.eta) }}</p>
    </div>
  </ng-container>
</div>
