<div class="container">
  <mat-chip-listbox class="custom-chip-listbox" hideSingleSelectionIndicator>
    <mat-chip-option
      [routerLink]="'/profile/information'"
      [selected]="currentPath === '/profile/information'"
      value="information"
      selected
      disableRipple
      >{{ 'MyInformations' | translate }}</mat-chip-option
    >
    <mat-chip-option
      [routerLink]="'/profile/preferences'"
      [selected]="currentPath === '/profile/preferences'"
      value="preferences"
      disableRipple
      >{{ 'Preferences' | translate }}</mat-chip-option
    >
    <mat-chip-option
      [routerLink]="'/profile/offer'"
      [selected]="currentPath === '/profile/offer'"
      value="offer"
      disableRipple
      >{{ 'MyOffer' | translate }}</mat-chip-option
    >
    <mat-chip-option
      [routerLink]="'/profile/sponsorship'"
      [selected]="currentPath === '/profile/sponsorship'"
      value="sponsorship"
      disableRipple
      >{{ 'Sponsorship' | translate }}
      <div class="sponsorship-gain">
        <p class="body-12-med">15€</p>
      </div>
    </mat-chip-option>
  </mat-chip-listbox>

  <div
    *ngIf="userPlan"
    class="user-plan pointer"
    [matTooltip]="'Vous êtes actuellement en formule ' + ('PLANS.' + userPlan | translate)"
    matTooltipPosition="below"
  >
    <p class="body-14-med">{{ 'Plan' | translate }} {{ 'PLANS.' + userPlan | translate }}</p>
  </div>
</div>
<mat-divider></mat-divider>
