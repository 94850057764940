<section id="main-container">
  <div id="circle-orbit-container">
    <div id="central-point">
      <img src="/assets/img/logos/_manual_ledger.svg" alt="Waltio" width="80" height="40" />
    </div>

    <!-- Circles closest to the central point -->
    <div id="inner-orbit">
      <div class="inner-orbit-circles">
        <img src="/assets/img/icons/coins/btc.svg" alt="BTC" />
      </div>
      <div class="inner-orbit-circles inner-orbit-circles--second">
        <img src="/assets/img/icons/coins/eth.svg" alt="ETH" />
      </div>
      <div class="inner-orbit-circles inner-orbit-circles--third">
        <img src="/assets/img/icons/coins/busd.svg" alt="BUSD" />
      </div>
    </div>

    <!-- Circles second closest to the central point -->
    <div id="middle-orbit">
      <div class="middle-orbit-circles">
        <img src="/assets/img/icons/coins/matic.svg" alt="MATIC" />
      </div>
      <div class="middle-orbit-circles middle-orbit-circles--second">
        <img src="/assets/img/icons/coins/dot.svg" alt="DOT" />
      </div>
      <div class="middle-orbit-circles middle-orbit-circles--third">
        <img src="/assets/img/icons/coins/xtz.svg" alt="XTZ" />
      </div>
    </div>

    <!-- Circles furthest away to the central point -->
    <div id="outer-orbit">
      <div class="outer-orbit-circles">
        <img src="/assets/img/icons/coins/sol.svg" alt="SOL" />
      </div>
      <div class="outer-orbit-circles outer-orbit-circles--second">
        <img src="/assets/img/icons/coins/atom.svg" alt="ATOM" />
      </div>
      <div class="outer-orbit-circles outer-orbit-circles--third">
        <img src="/assets/img/icons/coins/usdt.svg" alt="USDT" />
      </div>
    </div>
  </div>
</section>
