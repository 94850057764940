<div class="container">
  <ng-container *ngIf="!validatedPayment">
    <div class="loading">
      <img class="waltio-logo" src="/assets/img/logos/accounts/_MANUAL_LEDGER.png" alt="" width="26px" height="26px" />
      <img class="rotating" src="/assets/img/icons/loading-primary.svg" alt="" />
    </div>
    <p class="body-14-bold">Vérification du paiement...</p>
  </ng-container>

  <ng-container *ngIf="validatedPayment">
    <img class="success" src="/assets/img/icons/check-full.svg" alt="" width="26px" height="26px" />

    <div class="payment-success">
      <p class="body-14-bold">Payment confirmé</p>
      <p class="body-14">Merci pour votre confiance. Vous pouvez maintenant profiter de votre abonnement Waltio.</p>
    </div>
  </ng-container>
</div>
