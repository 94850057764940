import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { UploadErrors } from '../../models/upload.model';
import { Analysis } from '../../models/analysis.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';
import { CurrencyOccurences } from '../../models/currency.model';
import { Transaction, TransactionWarningAggregation } from '../../models/transaction.model';
import { WarningOccurences } from '../../models/warning.model';
import * as analysisReducer from '../reducers/analysis.reducer';
import { Page } from '../../../shared/models/page.model';

export const ANALYSIS_FEATURE_NAME = `analysis`;

export interface State {
  taxAssistant: analysisReducer.State;
}

export const selectTaxAssistantState: MemoizedSelector<
  State,
  analysisReducer.State
> = createFeatureSelector<analysisReducer.State>(ANALYSIS_FEATURE_NAME);

export const selectAnalysis: MemoizedSelector<State, Analysis> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.analysis
);

export const selectIsAnalysisFailed: MemoizedSelector<State, boolean> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.isAnalysisFailed
  );

export const selectAnalysisError: MemoizedSelector<State, any> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.analysisError
);

export const selectUploadErrors: MemoizedSelector<State, UploadErrors[]> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.uploadErrors
  );

export const selectTokensWithNoPrice: MemoizedSelector<
  State,
  CurrencyOccurences[]
> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.prices.tokensWithNoPrice
);

export const selectTokensWithPrice: MemoizedSelector<State, string[]> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.prices.tokensWithPrice
  );

export const selectUnmatchedTransactionsWithoutLabel: MemoizedSelector<
  State,
  Page<Transaction>
> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.unmatchedTransactionsWithoutLabel
);

export const selectCurrentStep: MemoizedSelector<State, number> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.currentStep
  );

export const selectInsufficientBalances: MemoizedSelector<
  State,
  TokenAndPlatformBalanceDetail[]
> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.insufficientBalances
);

export const selectAnalysisProgress: MemoizedSelector<State, number> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.analysisProgress
  );

export const selectGdprConsent: MemoizedSelector<State, boolean> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.gdprConsent
  );

export const selectLastAnalysis: MemoizedSelector<State, Analysis> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.lastAnalysis
  );

export const selectLastAnalysisWarnings: MemoizedSelector<
  State,
  WarningOccurences[]
> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.lastAnalysisWarnings
);

export const selectMatchedTransactions: MemoizedSelector<
  State,
  Transaction[][]
> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.matchedTransactions
);

export const selectFetchingAnalysis: MemoizedSelector<State, boolean> =
  createSelector(
    selectTaxAssistantState,
    (state: analysisReducer.State) => state.fetchingAnalysis
  );

export const selectAggregatedWarnings: MemoizedSelector<
  State,
  TransactionWarningAggregation[]
> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.aggregatedWarnings
);


export const selectShowNoneCriticalBalances: MemoizedSelector<
  State,
  boolean
> = createSelector(
  selectTaxAssistantState,
  (state: analysisReducer.State) => state.showNoneCriticalBalances
);
