import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LossHarvestingDetails } from '../models/loss-harvesting.model';
import {
  TaxReport,
  TaxReportSummary,
  TransactionSnapshotGraphDetails,
} from '../models/report.model';

@Injectable({
  providedIn: `root`,
})
export class ReportService {
  constructor(private http: HttpClient) { }

  /**
   * Get tax report
   *
   * @param fiscalYear
   */
  getTaxReport(fiscalYear: number, reportId?: string): Observable<TaxReport> {
    let url = `${environment.apiUrl}/v1/tax/transaction/report?fiscalYear=${fiscalYear}`;

    if (reportId) {
      url = url.concat(`&reportId=${reportId}`);
    }

    return this.http.get<TaxReport>(url);
  }

  /**
   * Start tax report process
   *
   * @param fiscalYear
   */
  startTaxReport(fiscalYear: number): Observable<TaxReport> {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': `application/json`,
    });
    return this.http.post<TaxReport>(
      `${environment.apiUrl}/v1/tax/transaction/report?fiscalYear=${fiscalYear}`,
      ``,
      {
        headers,
      }
    );
  }
  /**
   * Download attachment
   *
   * @param attachmentName
   * @param reportId
   */
  downloadReportAttachment(
    attachmentName: string,
    reportId: string,
    format: string
  ): Observable<any> {
    format = format || ``;
    return this.http.get(
      `${environment.apiUrl}/v1/tax/transaction/report/dl/${format}?name=${attachmentName}&reportId=${reportId}`,
      { responseType: `blob` }
    );
  }

  /**
   * Get tax reports page
   *
   * @returns tax reports page
   */
  getLastReportsByFiscalYear(): Observable<Map<string, TaxReport>> {
    return this.http
      .get<Record<string, TaxReport>>(
        `${environment.apiUrl}/v1/tax/transaction/report/v2/list`
      )
      .pipe(
        map(
          (lastReports: Record<string, TaxReport>) =>
            new Map<string, TaxReport>(Object.entries(lastReports))
        )
      );
  }

  /**
   *  Get report graph details
   *
   * @param fiscalYear
   * @returns graph details
   */
  getReportGraph(
    fiscalYear: number,
    reportId?: string
  ): Observable<TransactionSnapshotGraphDetails[]> {
    let url = `${environment.apiUrl}/v1/tax/transaction/report/graph?fiscalYear=${fiscalYear}`;

    if (reportId) {
      url = url.concat(`&reportId=${reportId}`);
    }

    return this.http.get<TransactionSnapshotGraphDetails[]>(url);
  }

  /**
   * Get tax loss harvesting details
   *
   * @param portfolioValue
   * @param reportId
   * @returns
   */
  getTaxLossHarvesting(
    portfolioValue: number,
    capitalLoss: number,
    buyingPrice: number,
    reportId: string
  ): Observable<LossHarvestingDetails> {
    capitalLoss = capitalLoss * -1;

    let url = `${environment.apiUrl}/v1/tax/lossHarvesting?portfolioValue=${portfolioValue}&reportId=${reportId}`;

    if (capitalLoss !== undefined) {
      url = url.concat(`&capitalLoss=${capitalLoss}`);
    }

    if (buyingPrice !== undefined) {
      url = url.concat(`&buyingPrice=${buyingPrice}`);
    }

    return this.http.get<LossHarvestingDetails>(url);
  }

  getReportSummary(reportId: string): Observable<TaxReportSummary> {
    return this.http
      .get<TaxReportSummary>(
        `${environment.apiUrl}/v1/tax/transaction/report/summary?reportId=${reportId}`
      )
      .pipe(
        map((reportSummary: TaxReportSummary) => {
          const amountByLabelsForDeposits = new Map<string, number>([]);

          amountByLabelsForDeposits.set(`CASHBACK`, 0);
          amountByLabelsForDeposits.set(`MASTERNODE_AND_STAKING`, 0);
          amountByLabelsForDeposits.set(`CFD_GAIN`, 0);
          amountByLabelsForDeposits.set(`OTHER_GAIN`, 0);

          reportSummary.amountByLabelsForDeposits = new Map<string, number>(
            Object.entries(reportSummary.amountByLabelsForDeposits)
          );

          // Parsing into Map of Map 🤯
          const amountByLabelAndCurrencyForDepositsMap = new Map<string, Map<string, number>>();
          Object.entries(reportSummary.amountByLabelAndCurrencyForDeposits).forEach(([label, currencies]: [string, Map<string, number>]) => {
            const currenciesMap = new Map<string, number>(Object.entries(currencies));
            amountByLabelAndCurrencyForDepositsMap.set(label, currenciesMap);
          });
          reportSummary.amountByLabelAndCurrencyForDeposits = amountByLabelAndCurrencyForDepositsMap;

          const amountByLabelAndCurrencyForWithdrawalsMap = new Map<string, Map<string, number>>();
          Object.entries(reportSummary.amountByLabelAndCurrencyForWithdrawals).forEach(([label, currencies]: [string, Map<string, number>]) => {
            const currenciesMap = new Map<string, number>(Object.entries(currencies));
            amountByLabelAndCurrencyForWithdrawalsMap.set(label, currenciesMap);
          });
          reportSummary.amountByLabelAndCurrencyForWithdrawals = amountByLabelAndCurrencyForWithdrawalsMap;


          reportSummary.amountByLabelsForWithdrawals = new Map<string, number>(
            Object.entries(reportSummary.amountByLabelsForWithdrawals)
          );

          reportSummary.pnLByLabelsForWithdrawals = new Map<string, number>(
            Object.entries(reportSummary.pnLByLabelsForWithdrawals)
          );

          reportSummary.amountByLabelsForDeposits.forEach(
            (amount: number, label: string) => {
              amountByLabelsForDeposits.set(label, amount);
            }
          );

          reportSummary.amountByLabelsForDeposits = amountByLabelsForDeposits;

          return reportSummary;
        })
      );
  }
}
