import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TransactionsBreakdownTooltipComponent } from '../../../taxation/components/transactions-breakdown-tooltip/transactions-breakdown-tooltip.component';
import { LegalFormsComponent } from '../../../taxation/pages/legal-forms-page/legal-forms-page.component';
import { SidenavService } from '../../services/sidenav.service';
import { SidenavComponent } from '../sidenav/sidenav.component';
import * as fromPayment from '../../../taxation/store/selectors/payment.selector';
import { loadAvailableAddonsAction, loadAvailablePlansAction } from '../../../taxation/store/actions/payment.action';

@Component({
  selector: `app-layout`,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    MatProgressBarModule,
    MatMenuModule,
    MatListModule,
    MatDividerModule,
    TransactionsBreakdownTooltipComponent,
    SidenavComponent,
    LegalFormsComponent
  ],
  templateUrl: `./layout.component.html`,
  styleUrls: [`./layout.component.scss`],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @ViewChild(`rightSidenav`) rightSidenav: MatSidenav;

  constructor(
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly sidenavService: SidenavService
  ) {
  }

  ngOnInit(): void {
    this.paymentStore$.dispatch(loadAvailablePlansAction());
    this.paymentStore$.dispatch(loadAvailableAddonsAction());
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.rightSidenav);
  }
}
