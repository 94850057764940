import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { User, UserPlan } from '../../models/user.model';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: `app-profile-header`,
  standalone: true,
  imports: [CommonModule, MatChipsModule, RouterModule, TranslateModule, MatDividerModule, MatTooltipModule],
  templateUrl: `./profile-header.component.html`,
  styleUrls: [`./profile-header.component.scss`]
})
export class ProfileHeaderComponent implements OnInit, OnDestroy {
  currentPath = ``;
  userPlan: UserPlan;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly router: Router, private readonly authStore$: Store<fromAuth.State>) { }

  ngOnInit(): void {
    this.currentPath = this.router.url;

    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((event: any) => event instanceof NavigationEnd),
      map((event: any) => {
        this.currentPath = event.url;
      })
    ).subscribe();

    this.authStore$.pipe(
      takeUntil(this.destroy$),
      select(fromAuth.selectUser),
      map((user: User) => {
        this.userPlan = user.plan;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
