<div class="container">
  <div>
    <section class="header">
      <img src="/assets/img/logos/_manual_ledger.svg" alt="waltio" />
    </section>

    <mat-divider></mat-divider>

    <ng-container *ngIf="assessment?.state !== 'ABORTED'">
      <section class="report-status">
        <app-report-status [assessment]="assessment"></app-report-status>
      </section>

      <mat-divider></mat-divider>
    </ng-container>

    <nav>
      <app-navigation-tile *ngFor="let link of links" [link]="link"></app-navigation-tile>
    </nav>
  </div>

  <div>
    <app-plan-status *ngIf="user?.plan !== 'PLN800'" [assessment]="assessment" [plan]="user.plan"></app-plan-status>

    <app-profile-sidenav [user]="user" [matMenuTriggerFor]="menu"></app-profile-sidenav>

    <mat-menu #menu="matMenu" yPosition="above" class="custom-menu">
      <div class="menu">
        <div class="item" [routerLink]="'/profile/information'">
          <p class="body-14-med">{{ 'MyInformations' | translate }}</p>
        </div>
        <div class="item body-14-med" [routerLink]="'/profile/preferences'">
          <p class="body-14-med">{{ 'Preferences' | translate }}</p>
        </div>
        <div class="item" [routerLink]="'/profile/offer'">
          <p class="body-14-med">{{ 'MyPlan' | translate }}</p>
        </div>
        <div class="item" [routerLink]="'/profile/sponsorship'">
          <p class="body-14-med">{{ 'Sponsorship' | translate }}</p>
          <div class="sponsorship-chip">30€</div>
        </div>
        <a class="item body-14-med" [href]="'https://help.waltio.co/' + language" target="_blank">
          <p class="body-14-med">{{ 'HelpCenter' | translate }}</p>
        </a>
      </div>

      <mat-divider></mat-divider>

      <div class="menu">
        <div class="action" (click)="logout()">
          <div class="action__icon-logout"></div>
          <p class="body-14-med">{{ 'Logout' | translate }}</p>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
