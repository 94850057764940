import { Action, createReducer, on } from '@ngrx/store';
import {
  AvailableAddons,
  AvailablePlans,
  Payment,
  PaymentEstimateV3,
  PendingCharge,
} from '../../models/payment.model';
import * as PaymentActions from '../actions/payment.action';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { SubscriptionDetails } from '../../models/subscription.model';
import { AddOnDetails } from '../../models/addon.model';

export interface State {
  availablePlans: AvailablePlans;
  availableAddons: AvailableAddons;

  planEstimate: PaymentEstimateV3;
  addonEstimate: PaymentEstimateV3;

  subscriptionDetails: SubscriptionDetails;
  addonsDetails: AddOnDetails[];

  payments: Payment[];

  pendingCharge: PendingCharge;

  validatedPayment: Payment;
  voucherResponse: GenericResponse;
}

export const initialState: State = {
  availablePlans: null,
  subscriptionDetails: null,
  planEstimate: null,

  availableAddons: null,
  addonsDetails: null,
  addonEstimate: null,

  payments: null,

  pendingCharge: null,
  validatedPayment: null,
  voucherResponse: null,
};

const reducer = createReducer(
  initialState,
  on(PaymentActions.setPaymentsAction, (state: State, { payments }: any) => ({
    ...state,
    payments,
  })),
  on(
    PaymentActions.setValidatedPaymentAction,
    (state: State, { payment }: any) => ({
      ...state,
      validatedPayment: payment,
    })
  ),
  on(
    PaymentActions.setAvailablePlansAction,
    (state: State, { availablePlans }: any) => ({
      ...state,
      availablePlans,
    })
  ),
  on(
    PaymentActions.setAvailableAddonsAction,
    (state: State, { availableAddons }: any) => ({
      ...state,
      availableAddons,
    })
  ),
  on(
    PaymentActions.setSubscriptionDetailsAction,
    (state: State, { subscriptionDetails }: any) => ({
      ...state,
      subscriptionDetails,
    })
  ),
  on(
    PaymentActions.setVoucherResponseAction,
    (state: State, { voucherResponse }: any) => ({
      ...state,
      voucherResponse,
    })
  ),
  on(
    PaymentActions.setAddonsDetailsAction,
    (state: State, { addonsDetails }: any) => ({
      ...state,
      addonsDetails,
    })
  ),
  on(
    PaymentActions.setAddonEstimateAction,
    (state: State, { addonEstimate }: any) => ({
      ...state,
      addonEstimate,
    })
  ),
  on(
    PaymentActions.setPendingChargeAction,
    (state: State, { pendingCharge }: any) => ({
      ...state,
      pendingCharge,
    })
  ),
  on(
    PaymentActions.setPlanEstimateAction,
    (state: State, { planEstimate }: any) => ({
      ...state,
      planEstimate,
    })
  )
);

export const paymentReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
