<div
  class="form-input"
  [ngClass]="{
    'form-input--copied': copied,
    'form-input--copiable': copiable
  }"
  (mouseenter)="tooltipLabel = 'Copy'; tooltip?.show()"
  (mouseleave)="copied = false; tooltip?.hide()"
  (click)="copyValue()"
>
  {{ value }}

  <div
    *ngIf="copiable"
    class="copy"
    #tooltip="matTooltip"
    [matTooltip]="tooltipLabel | translate"
    matTooltipPosition="left"
  ></div>
</div>
