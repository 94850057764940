import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import * as assessmentReducer from '../reducers/assessment.reducer';
import { TaxableCession } from '../../models/taxable-cession.model';
import { Assessment } from '../../models/assessment.model';

export const ASSESSMENT_FEATURE_NAME = `assessment`;

export interface State {
  assessment: assessmentReducer.State;
}

export const selectAssessmentState: MemoizedSelector<
  State,
  assessmentReducer.State
> = createFeatureSelector<assessmentReducer.State>(ASSESSMENT_FEATURE_NAME);

export const selectTaxableCessions: MemoizedSelector<State, TaxableCession[]> = createSelector(
  selectAssessmentState,
  (state: assessmentReducer.State) => state.taxableCessions
);

export const selectAssessment: MemoizedSelector<State, Assessment> =
  createSelector(
    selectAssessmentState,
    (state: assessmentReducer.State) => {
      const assessment = state.assessment;

      if (assessment?.summary) {
        const amountByLabelsForDeposits = new Map<string, number>([]);
        amountByLabelsForDeposits.set(`CASHBACK`, 0);
        amountByLabelsForDeposits.set(`MASTERNODE_AND_STAKING`, 0);
        amountByLabelsForDeposits.set(`CFD_GAIN`, 0);
        amountByLabelsForDeposits.set(`OTHER_GAIN`, 0);

        assessment.summary.amountByLabelsForDeposits = new Map<string, number>(
          Object.entries(assessment.summary.amountByLabelsForDeposits)
        );

        // Parsing into Map of Map 🤯
        const amountByLabelAndCurrencyForDepositsMap = new Map<string, Map<string, number>>();
        Object.entries(assessment.summary.amountByLabelAndCurrencyForDeposits).forEach(([label, currencies]: [string, Map<string, number>]) => {
          const currenciesMap = new Map<string, number>(Object.entries(currencies));
          amountByLabelAndCurrencyForDepositsMap.set(label, currenciesMap);
        });
        assessment.summary.amountByLabelAndCurrencyForDeposits = amountByLabelAndCurrencyForDepositsMap;

        const amountByLabelAndCurrencyForWithdrawalsMap = new Map<string, Map<string, number>>();
        Object.entries(assessment.summary.amountByLabelAndCurrencyForWithdrawals).forEach(([label, currencies]: [string, Map<string, number>]) => {
          const currenciesMap = new Map<string, number>(Object.entries(currencies));
          amountByLabelAndCurrencyForWithdrawalsMap.set(label, currenciesMap);
        });
        assessment.summary.amountByLabelAndCurrencyForWithdrawals = amountByLabelAndCurrencyForWithdrawalsMap;

        assessment.summary.amountByLabelsForWithdrawals = new Map<string, number>(
          Object.entries(assessment.summary.amountByLabelsForWithdrawals)
        );

        assessment.summary.pnLByLabelsForWithdrawals = new Map<string, number>(
          Object.entries(assessment.summary.pnLByLabelsForWithdrawals)
        );

        assessment.summary.amountByLabelsForDeposits.forEach(
          (amount: number, label: string) => {
            amountByLabelsForDeposits.set(label, amount);
          }
        );

        assessment.summary.amountByLabelsForDeposits = amountByLabelsForDeposits;
      }

      return assessment;
    }
  );