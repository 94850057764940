<div class="container" [routerLink]="'/profile/offer'">
  <p class="body-12-med plan">{{ 'Plan' | translate | uppercase }} {{ 'PLANS.' + plan | translate | uppercase }}</p>

  <p class="body-12-med">
    {{ assessment?.nbOfTransactionsOnFiscalYear || assessment?.nbOfTransactionsOnCurrentUserYear }}
    {{ 'Transactions' | translate | lowercase }}

    <ng-container *ngIf="maximumNbOfTransactions > 0">
      {{ 'On' | translate | lowercase }}
      {{ maximumNbOfTransactions }}
    </ng-container>
  </p>

  <div class="plan-progress">
    <div #planProgress class="plan-progress__fill"></div>
  </div>

  <div class="upgrade-plan">
    <p class="body-12-med">{{ 'UpgradePlan' | translate }}</p>
    <img src="/assets/img/icons/next.svg" alt="next" />
  </div>
</div>
