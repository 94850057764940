import { Action, createReducer, on } from '@ngrx/store';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import * as AccountActions from '../actions/account.action';
import { UserAccount } from '../../models/user-account.model';

export interface State {
  userAccounts: UserAccount[];
  transactionsCount: Map<string, number>;
  api: {
    isConnecting: boolean;
    isConnected: boolean;
    errorMessage: string;
  };
  availableChains: string[];
  resolvedName: NameResolverResponse;
  timezones: TimezoneDetails[];
}

export const initialState: State = {
  userAccounts: null,
  transactionsCount: null,
  api: {
    isConnecting: false,
    isConnected: false,
    errorMessage: ``,
  },
  availableChains: null,
  resolvedName: null,
  timezones: [],
};

const reducer = createReducer(
  initialState,
  on(AccountActions.resetAction, () => ({
    ...initialState,
  })),
  on(AccountActions.setUserAccountsAction, (state: State, { userAccounts }: any) => ({
    ...state,
    userAccounts,
  })),
  on(
    AccountActions.setTransactionsCountAction,
    (state: State, { transactionsCount }: any) => ({
      ...state,
      transactionsCount,
    })
  ),
  on(AccountActions.createApiAction, (state: State) => ({
    ...state,
    api: {
      ...state.api,
      isConnecting: true,
      isConnected: false,
      errorMessage: ``,
    },
  })),
  on(AccountActions.setApiConnectedAction, (state: State) => ({
    ...state,
    api: {
      ...state.api,
      isConnecting: true,
      isConnected: true,
      errorMessage: ``,
    },
  })),
  on(
    AccountActions.setApiErrorMessageAction,
    (state: State, { apiErrorMessage }: any) => ({
      ...state,
      api: {
        ...state.api,
        isConnecting: false,
        isConnected: false,
        errorMessage: apiErrorMessage,
      },
    })
  ),
  on(
    AccountActions.setAvailableChainsAction,
    (state: State, { availableChains }: any) => ({
      ...state,
      availableChains,
      api: {
        ...state.api,
        isConnecting: false,
        isConnected: true,
      },
    })
  ),
  on(
    AccountActions.setResolvedNameAction,
    (state: State, { resolvedName }: any) => ({
      ...state,
      api: {
        ...state.api,
        isConnecting: false,
        isConnected: false,
        errorMessage: ``,
      },
      resolvedName,
    })
  ),
  on(AccountActions.loadTimezonesAction, (state: State) => ({
    ...state,
    timezones: [],
  })),
  on(AccountActions.setTimezonesAction, (state: State, { timezones }: any) => ({
    ...state,
    timezones,
  }))
);

export const accountReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
