import { Action, createReducer, on } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { DEFAULT_TRANSACTIONS_FILTERS } from '../../constants/transactions.constant';
import {
  Transaction,
  TransactionFilters,
} from '../../models/transaction.model';
import { SearchDTO } from '../../models/transactions-filters.model';
import * as TransactionActions from '../actions/transaction.action';
import { TransactionStatsAggregation } from '../../models/transaction-stats-aggregation.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';

export interface State {
  selectedTransaction: Transaction;
  checkedTransactions: Transaction[];
  pageIndex: number;
  filters: TransactionFilters;

  transactionsStats: TransactionStatsAggregation;
  transactionsPage: Page<Transaction>;
  appliedFilters: SearchDTO;
  negativeBalances: TokenAndPlatformBalanceDetail[];
}

export const initialState: State = {
  selectedTransaction: null,
  checkedTransactions: [],
  transactionsPage: null,
  pageIndex: 0,
  filters: null,
  appliedFilters: DEFAULT_TRANSACTIONS_FILTERS,
  transactionsStats: null,
  negativeBalances: null,
};

const reducer = createReducer(
  initialState,
  on(
    TransactionActions.setTransactionsPageAction,
    (state: State, { transactionsPage }: any) => ({
      ...state,
      transactionsPage,
    })
  ),
  on(
    TransactionActions.setCheckedTransactionsAction,
    (state: State, { checkedTransactions }: any) => ({
      ...state,
      checkedTransactions,
    })
  ),
  on(TransactionActions.setFiltersAction, (state: State, { filters }: any) => ({
    ...state,
    filters,
  })),
  on(
    TransactionActions.setSelectedTransactionAction,
    (state: State, { selectedTransaction }: any) => ({
      ...state,
      selectedTransaction,
    })
  ),
  on(
    TransactionActions.setAppliedFiltersAction,
    (state: State, { appliedFilters }: any) => ({
      ...state,
      appliedFilters,
    })
  ),
  on(
    TransactionActions.setTransactionsStatsAction,
    (state: State, { transactionsStats }: any) => ({
      ...state,
      transactionsStats
    })
  ),
  on(
    TransactionActions.setNegativeBalancesAction,
    (state: State, { negativeBalances }: any) => ({
      ...state,
      negativeBalances
    })
  ),
);

export const transactionReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
