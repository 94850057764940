import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import * as fromAssessment from '../../../taxation/store/selectors/assessment.selector';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { Link } from '../../models/link.model';
import { pingOutUserAction } from '../../store/actions/shared.action';
import { NavigationTileComponent } from '../navigation-tile/navigation-tile.component';
import { PlanStatusComponent } from '../plan-status/plan-status.component';
import { ProfileSidenavComponent } from '../profile-sidenav/profile-sidenav.component';
import { ReportStatusComponent } from '../report-status/report-status.component';
import { TranslateModule } from '@ngx-translate/core';
import { logoutAction } from '../../../authentication/store/actions/authentication.action';
import { Assessment } from '../../../taxation/models/assessment.model';
import { User } from '../../../taxation/models/user.model';
import { unsyncAssessmentAction } from '../../../taxation/store/actions/assessment.action';

@Component({
  selector: `app-sidenav`,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
    ReportStatusComponent,
    NavigationTileComponent,
    ProfileSidenavComponent,
    PlanStatusComponent
  ],
  templateUrl: `./sidenav.component.html`,
  styleUrls: [`./sidenav.component.scss`]
})
export class SidenavComponent implements OnInit, OnDestroy {
  currentYear = moment().year() - 1;
  links: Link[] = [
    {
      label: `MyAccounts`,
      routeIcon: `/assets/img/icons/wallet.svg`,
      routeActiveIcon: `/assets/img/icons/wallet-active.svg`,
      route: `/accounts`
    },
    {
      label: `TaxReturn`,
      routeIcon: `/assets/img/icons/document.svg`,
      routeActiveIcon: `/assets/img/icons/document-active.svg`,
      route: `/report/${this.currentYear}`
    },
    {
      label: `MyTransactions`,
      routeIcon: `/assets/img/icons/transactions.svg`,
      routeActiveIcon: `/assets/img/icons/transactions-active.svg`,
      route: `/transactions`
    },
    {
      label: `TaxStrategy`,
      routeIcon: `/assets/img/icons/coins.svg`,
      routeActiveIcon: `/assets/img/icons/coins-active.svg`,
      route: `/tax-strategy`,
      // new: true
    }
  ];

  assessment: Assessment;
  user: User;
  language: string;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly assessmentStore$: Store<fromAssessment.State>,
    private readonly authStore: Store<fromAuth.State>,
    private readonly sharedStore: Store<fromShared.State>
  ) { }

  ngOnInit(): void {
    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectAssessment),
        map((assessment: Assessment) => {
          this.assessment = assessment;
        })
      )
      .subscribe();

    this.authStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectUser),
        map((user: User) => this.user = user)
      )
      .subscribe();

    this.sharedStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectLanguage),
        map((language: string) => (this.language = language))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.sharedStore.dispatch(pingOutUserAction());
    this.assessmentStore$.dispatch(unsyncAssessmentAction());
    this.assessmentStore$.dispatch(logoutAction());
  }

}
