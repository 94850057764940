<div class="container">
  <div class="profile">
    <img src="/assets/img/icons/profile-picture.svg" alt="pp" />
    <div class="profile__details">
      <p class="body-14-med email">{{ user?.email }}</p>
      <p class="body-14-med profile">{{ 'MyProfile' | translate }}</p>
    </div>
    <img class="unwrap" src="/assets/img/icons/unwrap.svg" alt="unwrap" />
  </div>
</div>
