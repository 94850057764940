import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import { UserAccount } from '../../models/user-account.model';
import * as accountReducer from '../reducers/account.reducer';

export const ACCOUNTS_FEATURE_NAME = `accounts`;

export interface State {
  accounts: accountReducer.State;
}

export const selectUploadState: MemoizedSelector<State, accountReducer.State> =
  createFeatureSelector<accountReducer.State>(ACCOUNTS_FEATURE_NAME);

export const selectUserAccounts: MemoizedSelector<State, UserAccount[]> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.userAccounts
);

export const selectTransactionsCount: MemoizedSelector<
  State,
  Map<string, number>
> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.transactionsCount
);

export const selectIsApiConnecting: MemoizedSelector<State, boolean> =
  createSelector(
    selectUploadState,
    (state: accountReducer.State) => state.api.isConnecting
  );

export const selectIsApiConnected: MemoizedSelector<State, boolean> =
  createSelector(
    selectUploadState,
    (state: accountReducer.State) => state.api.isConnected
  );

export const selectApiErrorMessage: MemoizedSelector<State, string> =
  createSelector(
    selectUploadState,
    (state: accountReducer.State) => state.api.errorMessage
  );

export const selectAvailableChains: MemoizedSelector<State, string[]> =
  createSelector(
    selectUploadState,
    (state: accountReducer.State) => state.availableChains
  );

export const selectResolvedName: MemoizedSelector<State, NameResolverResponse> =
  createSelector(
    selectUploadState,
    (state: accountReducer.State) => state.resolvedName
  );

export const selectTimezones: MemoizedSelector<State, TimezoneDetails[]> =
  createSelector(
    selectUploadState,
    (state: accountReducer.State) => state.timezones
  );
