import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LinkComponent } from '../link/link.component';

@Component({
  selector: `app-banner`,
  standalone: true,
  imports: [CommonModule, MatButtonModule, LinkComponent],
  templateUrl: `./banner.component.html`,
  styleUrls: [`./banner.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showBannerIcon = true;
  @Input() bannerTheme = `primary`;
  @Input() useLink = false;
  @Input() theme: string;
  @Input() cta: string;
  @Input() columnDisplay = false;

  @Output() ctaClick: EventEmitter<void> = new EventEmitter<void>();
}
