/* eslint-disable @typescript-eslint/naming-convention */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = sessionStorage.getItem(`waltio_user`);
    return next
      .handle(this.addUser(req, user))
      .pipe(
        catchError((error: any) =>
          throwError(error.error || error.message || `Server Error`)
        )
      );
  }

  addUser(req: HttpRequest<any>, user: string): HttpRequest<any> {
    if (user) {
      return req.clone({
        headers: req.headers.set(`waltio_user`, user),
      });
    } else {
      return req;
    }
  }
}
