import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import {
  Transaction,
  TransactionFilters,
} from '../../models/transaction.model';
import * as transactionReducer from '../reducers/transaction.reducer';
import { TransactionStatsAggregation } from '../../models/transaction-stats-aggregation.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';

export const TRANSACTIONS_FEATURE_NAME = `transactions`;

export interface State {
  transactions: transactionReducer.State;
}

export const selectTransactionState: MemoizedSelector<
  State,
  transactionReducer.State
> = createFeatureSelector<transactionReducer.State>(TRANSACTIONS_FEATURE_NAME);

export const selectTransactionsPage: MemoizedSelector<State, Page<Transaction>> =
  createSelector(
    selectTransactionState,
    (state: transactionReducer.State) => state.transactionsPage
  );

export const selectPageIndex: MemoizedSelector<State, number> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.pageIndex
);

// export const selectIsTransactionPageLoading: MemoizedSelector<State, boolean> =
//   createSelector(
//     selectTransactionState,
//     (state: transactionReducer.State) => !state.transactionPage
//   );

export const selectFilters: MemoizedSelector<State, TransactionFilters> =
  createSelector(
    selectTransactionState,
    (state: transactionReducer.State) => state.filters
  );

export const selectAppliedFilters: MemoizedSelector<State, any> =
  createSelector(
    selectTransactionState,
    (state: transactionReducer.State) => state.appliedFilters
  );

export const selectSelectedTransaction: MemoizedSelector<State, Transaction> =
  createSelector(
    selectTransactionState,
    (state: transactionReducer.State) => state.selectedTransaction
  );

export const selectCheckedTransactions: MemoizedSelector<State, Transaction[]> =
  createSelector(
    selectTransactionState,
    (state: transactionReducer.State) => state.checkedTransactions
  );


export const selectTransactionsStats: MemoizedSelector<State, TransactionStatsAggregation> =
  createSelector(
    selectTransactionState,
    (state: transactionReducer.State) => state.transactionsStats
  );

export const selectNegativeBalances: MemoizedSelector<State, TokenAndPlatformBalanceDetail[]> =
  createSelector(
    selectTransactionState,
    (state: transactionReducer.State) => state.negativeBalances
  );