
export const PLANS: Map<string, any> = new Map<string, any>([
  [`PLN000`, {
    maximumNbOfTransactions: 50,
    price: 0
  }],
  [`PLN300`, {
    maximumNbOfTransactions: 1000,
    price: 99.00,
    details: [
      `1000 transactions par an`,
      `DeFi, staking, farming, NFTs...`,
      `Déclaration des revenus`,
      `Déclaration des comptes à l’étranger`,
      `Certificat fiscal de déclaration`
    ]
  }],
  [`PLN500`, {
    maximumNbOfTransactions: 10000,
    price: 179.00,
    details: [
      `Tout dans la formule Starter :`,
      `10000 transactions par an`,
      `Synchronisation manuelle des APIs`,
      `Export de transactions Waltio`,
      `Outil de réduction d’impôt`
    ]
  }],
  [`PLN800`, {
    maximumNbOfTransactions: -1,
    price: 1199.00,
    details: [
      `Tout dans la formule Smart :`,
      `Transactions et plateformes illimitées`,
      `Contrôle fiscal et fonds bloqués`,
      `Synchronisations illimités des APIs`,
      `Support client prioritaire`
    ]
  }]
]);
