import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  AvailableAddons,
  AvailablePlans,
  Payment,
  PaymentEstimateV3,
  PendingCharge,
} from '../../models/payment.model';
import * as paymentReducer from '../reducers/payment.reducer';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { SubscriptionDetails } from '../../models/subscription.model';

export const PAYMENTS_FEATURE_NAME = `payments`;

export interface State {
  payments: paymentReducer.State;
}

export const selectPaymentState: MemoizedSelector<State, paymentReducer.State> =
  createFeatureSelector<paymentReducer.State>(PAYMENTS_FEATURE_NAME);

export const selectPayments: MemoizedSelector<State, Payment[]> =
  createSelector(
    selectPaymentState,
    (state: paymentReducer.State) => state.payments
  );

export const selectAvailablePlans: MemoizedSelector<State, AvailablePlans> =
  createSelector(
    selectPaymentState,
    (state: paymentReducer.State) => state.availablePlans
  );

export const selectAvailableAddons: MemoizedSelector<State, AvailableAddons> =
  createSelector(
    selectPaymentState,
    (state: paymentReducer.State) => state.availableAddons
  );

export const selectPlanEstimate: MemoizedSelector<State, PaymentEstimateV3> =
  createSelector(
    selectPaymentState,
    (state: paymentReducer.State) => state.planEstimate
  );

export const selectAddonEstimate: MemoizedSelector<State, PaymentEstimateV3> =
  createSelector(
    selectPaymentState,
    (state: paymentReducer.State) => state.addonEstimate
  );

export const selectPendingCharge: MemoizedSelector<State, PendingCharge> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.pendingCharge
);

export const selectValidatedPayment: MemoizedSelector<State, Payment> =
  createSelector(
    selectPaymentState,
    (state: paymentReducer.State) => state.validatedPayment
  );

export const selectSubscriptionDetails: MemoizedSelector<
  State,
  SubscriptionDetails
> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.subscriptionDetails
);

export const selectVoucherResponse: MemoizedSelector<State, GenericResponse> =
  createSelector(
    selectPaymentState,
    (state: paymentReducer.State) => state.voucherResponse
  );