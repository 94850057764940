/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { Account } from '../../models/account.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import { APIDetails, Upload } from '../../models/upload.model';
import { UserAccount } from '../../models/user-account.model';

// eslint-disable-next-line no-shadow
export enum AccountActions {
  CreateApi = `[Upload] Create API`,
  Reset = `[Upload] Reset`,
  SetApiConnected = `[Upload] Set Is Api Connected`,
  SetApiErrorMessage = `[Upload] Set Api Error Message`,
  LoadAvailableChains = `[Upload] Load Available Chains`,
  SetAvailableChains = `[Upload] Set Available Chains`,
  CreateSelectedChainsApis = `[Upload] Create Selected Chains Apis`,
  ResolveName = `[Upload] Resolve Name`,
  SetResolvedName = `[Upload] Set Resolved Name`,
  LoadUserAccounts = `[Upload] Load User Accounts`,
  SetUserAccounts = `[Upload] Set User Accounts`,
  LoadTransactionsCount = `[Upload] Load Uploads Transactions Count`,
  SetTransactionsCount = `[Upload] Set Uploads Transactions Count`,
  DeleteAccount = `[Upload] Delete Account`,
  RenameAccount = `[Upload] Rename Account`,
  DownloadFile = `[Upload] Download File`,
  GetOAuthSyncUrl = `[Upload] Get OAuth Sync Url`,
  CreateOAuthAPI = `[Upload] Create OAuth API`,
  LoadTimezones = `[Upload] Load Timezones`,
  SetTimezones = `[Upload] Set Timezones`,
}

export const resetAction = createAction(AccountActions.Reset);

export const createApiAction = createAction(
  AccountActions.CreateApi,
  props<{ api: APIDetails; account: Account, subAccount?: Account, alias?: string }>()
);

export const setApiConnectedAction = createAction(
  AccountActions.SetApiConnected
);

export const setApiErrorMessageAction = createAction(
  AccountActions.SetApiErrorMessage,
  props<{ apiErrorMessage: string }>()
);

export const loadAvailableChainsAction = createAction(
  AccountActions.LoadAvailableChains,
  props<{ apiId: string }>()
);

export const setAvailableChainsAction = createAction(
  AccountActions.SetAvailableChains,
  props<{ availableChains: string[] }>()
);

export const createSelectedChainsApisAction = createAction(
  AccountActions.CreateSelectedChainsApis,
  props<{ api: APIDetails; selectedChains: string[]; alias?: string }>()
);

export const resolveNameAction = createAction(
  AccountActions.ResolveName,
  props<{ account: Account, name: string }>()
);

export const setResolvedNameAction = createAction(
  AccountActions.SetResolvedName,
  props<{ resolvedName: NameResolverResponse }>()
);

export const loadUserAccountsAction = createAction(AccountActions.LoadUserAccounts);

export const setUserAccountsAction = createAction(
  AccountActions.SetUserAccounts,
  props<{ userAccounts: UserAccount[] }>()
);

export const loadTransactionsCountAction = createAction(
  AccountActions.LoadTransactionsCount
);

export const setTransactionsCountAction = createAction(
  AccountActions.SetTransactionsCount,
  props<{ transactionsCount: Map<string, number> }>()
);

export const downloadFileAction = createAction(
  AccountActions.DownloadFile,
  props<{ file: Upload }>()
);

export const deleteAccountAction = createAction(
  AccountActions.DeleteAccount,
  props<{ account: UserAccount }>()
);

export const renameAccountAction = createAction(
  AccountActions.RenameAccount,
  props<{ account: UserAccount; alias: string }>()
);

export const loadTimezonesAction = createAction(AccountActions.LoadTimezones);

export const setTimezonesAction = createAction(
  AccountActions.SetTimezones,
  props<{ timezones: TimezoneDetails[] }>()
);

export const getOAuthSyncUrlAction = createAction(
  AccountActions.GetOAuthSyncUrl,
  props<{ platform: string }>()
);

export const createOAuthAPIAction = createAction(
  AccountActions.CreateOAuthAPI,
);