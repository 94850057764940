/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Transaction } from '../../taxation/models/transaction.model';
import { Page } from '../models/page.model';
import { RouterStateUrl } from '../models/router-state-url.model';
import { NgxDatePipe } from '../pipes/ngx-date.pipe';
import { IntercomService } from './intercom.service';
import { SUBSCRIPTION_RENEW_MONTH, SUBSCRIPTION_RENEW_DAY } from '../../taxation/constants/payment.constant';
import { environment } from '../../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasicDialogComponent } from '../components/dialogs/basic-dialog/basic-dialog.component';

@Injectable({ providedIn: `root` })
export class UtilsService {
  constructor(
    private readonly ngxDatePipe: NgxDatePipe,
    private readonly translateService: TranslateService,
    private readonly intercomService: IntercomService,
    private readonly dateAdapter: DateAdapter<any>,
    private readonly dialog: MatDialog,
  ) { }

  /**
   * Transform input time to formatted string
   *
   * @param time in seconds
   * @returns output like "1h 6m 16s" or "3m 12s" or "42s"
   */
  formatTime(time: number): string {
    // Hours, minutes and seconds
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = Math.floor((time % 3600) % 60);

    let formattedTime = ``;

    const formattedHrs = mins > 0 ? hrs + `h ` : hrs + `h`;
    const formattedMins = secs > 0 ? mins + `m ` : mins + `m`;
    const formattedSecs = secs < 10 ? `0` + secs + `s` : secs + `s`;

    formattedTime += hrs > 0 ? formattedHrs : ``;
    formattedTime += mins > 0 ? formattedMins : ``;
    formattedTime += secs > 0 ? formattedSecs : ``;

    return formattedTime;
  }

  /**
   * Order transactions by date with a fake transaction
   *
   * @param transactions
   * @returns
   */
  getTransactionsOrderedByDate(transactionsPage: Page<Transaction>): Transaction[] {
    let orderedTransactions = [];
    if (transactionsPage.content.length > 0) {
      transactionsPage.content.sort((t1: Transaction, t2: Transaction) =>
        t1.transactionDate < t2.transactionDate ? 1 : -1
      );

      // Sort by date desc
      orderedTransactions = [...transactionsPage.content];

      orderedTransactions.splice(0, 0, {
        id: null,
        type: null,
        subType: null,
        transactionDate: this.ngxDatePipe.transform(
          orderedTransactions[0].transactionDate,
          `d LLLL yyyy`
        ),
      });

      for (let i = 0; i < orderedTransactions.length - 1; i++) {
        const currentDate = orderedTransactions[i].id
          ? this.ngxDatePipe.transform(
            orderedTransactions[i].transactionDate,
            `d LLLL yyyy`
          )
          : orderedTransactions[i].transactionDate;

        const nextDate = this.ngxDatePipe.transform(
          orderedTransactions[i + 1].transactionDate,
          `d LLLL yyyy`
        );

        if (currentDate !== nextDate) {
          orderedTransactions.splice(i + 1, 0, {
            id: null,
            transactionDate: this.ngxDatePipe.transform(
              orderedTransactions[i + 1].transactionDate,
              `d LLLL yyyy`
            ),
          });
        }
      }
    }

    return orderedTransactions;
  }

  /**
   * Get cookie by name
   *
   * @param name
   * @returns
   */
  getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(`;`)
        .map((c) => c.trim())
        .filter((cookie) => cookie.startsWith(`${name}=`))
        .map((cookie) =>
          decodeURIComponent(cookie.substring(nameLenPlus))
        )[0] || ``
    );
  }

  /**
   * Switch app language
   *
   * @param language
   */
  switchLanguage(language: string): void {
    this.translateService.use(language);
    moment.locale(language);
    this.dateAdapter.setLocale(language);
    this.intercomService.updateLanguage(language);
  }

  /**
   * Serialize route to get url, queryParams and params
   *
   * @param route
   * @returns url, queryParams and params
   */
  serializeRoute(route: string): RouterStateUrl {
    const [url, allParams] = route.split(`?`);
    const params = allParams?.split(`&`);
    const queryParams: Params = {};
    params?.forEach((param: string) => {
      const [key, value] = param.split(`=`);
      queryParams[key] = value;
    });

    return {
      url,
      queryParams,
      params: {},
    };
  }

  /**
   * Check if today is after the 1st October and if the associated fiscal year is not the current year
   * 
   * @param associatedFiscalYear 
   * @returns 
   */
  isFiscalYearSwitchable(associatedFiscalYear: number): boolean {
    const currentYear = moment().year();
    const today = moment();
    const renewDate = moment({
      year: currentYear,
      month: SUBSCRIPTION_RENEW_MONTH,
      date: SUBSCRIPTION_RENEW_DAY,
    });

    if (!environment.production) {
      return associatedFiscalYear !== currentYear;
    } else {
      return today >= renewDate && associatedFiscalYear !== currentYear;
    }
  }

  openDialog(component: any = BasicDialogComponent, width = `706px`, height = `680px`, data: any = {}, disableClose = false, hasBackdrop = true): MatDialogRef<any, any> {
    return this.dialog.open(component, {
      hasBackdrop,
      autoFocus: false,
      disableClose,
      width,
      height,
      panelClass: [`custom-dialog-container`],
      data,
    });
  }
}
