<h1 mat-dialog-title>{{ 'SecretDialogTitle' | translate }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="'SecretDialogContent' | translate"></p>
  <input matInput type="text" class="custom-input" [(ngModel)]="userMail" />
</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false" color="secondary">
    {{ 'Cancel' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="userMail" color="primary">
    {{ 'Validate' | translate }}
  </button>
</div>
