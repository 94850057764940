import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import * as fromUpload from '../../store/reducers/account.reducer';
import { createOAuthAPIAction } from '../../store/actions/account.action';

@Component({
  selector: `app-oauth-callback`,
  standalone: true,
  imports: [CommonModule],
  templateUrl: `./oauth-callback.component.html`,
})
export class OauthCallbackComponent implements OnInit {

  constructor(private readonly uploadStore$: Store<fromUpload.State>) { }

  ngOnInit(): void {
    this.uploadStore$.dispatch(createOAuthAPIAction());
  }
}
