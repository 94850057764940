import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../shared/models/generic-response.model';
import { environment } from '../../../environments/environment';
import { TokenConfidence } from '../models/token-confidence.model';

@Injectable({
  providedIn: `root`,
})
export class ShitcoinService {
  constructor(private http: HttpClient) { }

  /**
   * Get shitcoins list
   *
   * @returns
   */
  getShitcoins(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/v1/tax/shitcoin`);
  }

  /**
   * Delete shitcoin
   *
   * @param shitcoin
   * @returns
   */
  deleteShitcoin(shitcoin: string): Observable<GenericResponse> {
    return this.http.delete<GenericResponse>(
      `${environment.apiUrl}/v1/tax/shitcoin?shitcoin=${shitcoin}`
    );
  }

  /**
   * Add shitcoin
   *
   * @param shitcoin
   * @returns
   */
  addShitcoin(shitcoin: string): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(
      `${environment.apiUrl}/v1/tax/shitcoin?shitcoin=${shitcoin}`,
      {}
    );
  }

  /**
   * Get shitcoin community confidence
   *
   * @param currency
   * @returns
   */
  getTokenConfidence(currency: string): Observable<TokenConfidence> {
    return this.http.get<TokenConfidence>(
      `${environment.apiUrl}/v1/tax/shitcoin/community-confidence?currency=${currency}`
    );
  }
}
