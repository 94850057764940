/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { Account } from '../../models/account.model';
import { Analysis } from '../../models/analysis.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';
import { CurrencyOccurences } from '../../models/currency.model';
import { PriceUpdateDto, Transaction, TransactionWarningAggregation } from '../../models/transaction.model';
import { WarningOccurences } from '../../models/warning.model';

// eslint-disable-next-line no-shadow
export enum AnalysisActions {
  ResetAnalysis = `[Analysis] Reset Analysis`,
  GetAnalysis = `[Analysis] Get Analysis`,
  StartAnalysis = `[Analysis] Start Analysis`,
  RefreshAnalysis = `[Analysis] Refresh Analysis`,
  SetAnalysis = `[Analysis] Set Analysis`,
  SetIsAnalysisFailed = `[Analysis] Set Is Analysis Failed`,
  SetAnalysisError = `[Analysis] Set Analysis Error`,
  LoadAccounts = `[Analysis] Load Accounts`,
  SetAccounts = `[Analysis] Set Accounts`,
  LoadTokensWithNoPrice = `[Analysis] Load Tokens With No Price`,
  SetTokensWithNoPrice = `[Analysis] Set Tokens With No Price`,
  LoadTokensWithPrice = `[Analysis] Load Tokens With Price`,
  SetTokensWithPrice = `[Analysis] Set Tokens With Price`,
  UpdateTokensPrices = `[Analysis] Update Tokens Prices`,
  LoadUnmatchedTransactionsWithoutLabel = `[Analysis] Load Unmatched Transactions Without Label`,
  SetUnmatchedTransactionsWithoutLabel = `[Analysis] Set Unmatched Transactions Without Label`,
  MatchTransactions = `[Analysis] Match Transactions`,
  UnmatchTransaction = `[Analysis] Unmatch Transaction`,
  CreateCompensatingTransaction = `[Analysis] Create Compensating Transaction`,
  SetUnmatchedTransactionsLabels = `[Analysis] Set Unmatched Transactions Labels`,
  SetAssistantProgress = `[Analysis] Set Assistant Progress`,
  SetAnalysisProgress = `[Analysis] Set Analysis Progress`,
  SetGDPRConsent = `[Analysis] Set GDPR Consent`,
  LoadLastAnalysis = `[Analysis] Load Last Analysis`,
  SetLastAnalysis = `[Analysis] Set Last Analysis`,
  LoadLastAnalysisWarnings = `[Analysis] Load Last Analysis Warnings`,
  SetLastAnalysisWarnings = `[Analysis] Set Last Analysis Warnings`,
  SetMatchedTransactions = `[Analysis] Set Matched Transactions`,
  LoadAggregatedWarnings = `[Analysis] Load Aggregated Warnings`,
  SetAggregatedWarnings = `[Analysis] Set Aggregated Warnings`,
}

export const resetAnalysisAction = createAction(AnalysisActions.ResetAnalysis);

export const getAnalysisAction = createAction(
  AnalysisActions.GetAnalysis,
  props<{ analysisId: string }>()
);

export const startAnalysisAction = createAction(AnalysisActions.StartAnalysis);

export const refreshAnalysisAction = createAction(
  AnalysisActions.RefreshAnalysis
);

export const setAnalysisAction = createAction(
  AnalysisActions.SetAnalysis,
  props<{ analysis: Analysis }>()
);

export const setIsAnalysisFailedAction = createAction(
  AnalysisActions.SetIsAnalysisFailed,
  props<{ isAnalysisFailed: boolean }>()
);

export const setAnalysisErrorAction = createAction(
  AnalysisActions.SetAnalysisError,
  props<{ analysisError: any }>()
);

export const loadAccountsAction = createAction(AnalysisActions.LoadAccounts);

export const setAccountsAction = createAction(
  AnalysisActions.SetAccounts,
  props<{ accounts: Account[] }>()
);

export const loadTokensWithNoPriceAction = createAction(
  AnalysisActions.LoadTokensWithNoPrice,
  props<{ shitcoins: string[] }>()
);

export const setTokensWithNoPriceAction = createAction(
  AnalysisActions.SetTokensWithNoPrice,
  props<{ tokensWithNoPrice: CurrencyOccurences[] }>()
);

export const loadTokensWithPriceAction = createAction(
  AnalysisActions.LoadTokensWithPrice
);

export const setTokensWithPriceAction = createAction(
  AnalysisActions.SetTokensWithPrice,
  props<{ tokensWithPrice: string[] }>()
);

export const updateTokensPricesAction = createAction(
  AnalysisActions.UpdateTokensPrices,
  props<{ prices: PriceUpdateDto[] }>()
);

export const loadUnmatchedTransactionsWithoutLabelAction = createAction(
  AnalysisActions.LoadUnmatchedTransactionsWithoutLabel,
  props<{ page?: number }>()
);

export const setUnmatchedTransactionsWithoutLabelAction = createAction(
  AnalysisActions.SetUnmatchedTransactionsWithoutLabel,
  props<{ unmatchedTransactionsWithoutLabel: Page<Transaction> }>()
);

export const setAnalysisProgressAction = createAction(
  AnalysisActions.SetAnalysisProgress,
  props<{ analysisProgress: number }>()
);

export const matchTransactionsAction = createAction(
  AnalysisActions.MatchTransactions,
  props<{ transactions: Transaction[] }>()
);

export const unmatchTransactionAction = createAction(
  AnalysisActions.UnmatchTransaction,
  props<{ transaction: Transaction }>()
);

export const setUnmatchedTransactionsLabelsAction = createAction(
  AnalysisActions.SetUnmatchedTransactionsLabels,
  props<{
    unmatchedTransactions: Transaction[];
  }>()
);

export const createCompensatingTransactionAction = createAction(
  AnalysisActions.CreateCompensatingTransaction,
  props<{
    balance: TokenAndPlatformBalanceDetail;
    reason: string;
  }>()
);

export const setGDPRConsentAction = createAction(
  AnalysisActions.SetGDPRConsent,
  props<{
    gdprConsent: boolean;
  }>()
);

export const setLastAnalysisAction = createAction(
  AnalysisActions.SetLastAnalysis,
  props<{ lastAnalysis: Analysis }>()
);

export const loadLastAnalysisWarningsAction = createAction(
  AnalysisActions.LoadLastAnalysisWarnings
);

export const setLastAnalysisWarningsAction = createAction(
  AnalysisActions.SetLastAnalysisWarnings,
  props<{ lastAnalysisWarnings: WarningOccurences[] }>()
);

export const loadLastAnalysisAction = createAction(
  AnalysisActions.LoadLastAnalysis
);

export const setMatchedTransactionsAction = createAction(
  AnalysisActions.SetMatchedTransactions,
  props<{ matchedTransactions: Transaction[][] }>()
);

export const loadAggregatedWarningsAction = createAction(
  AnalysisActions.LoadAggregatedWarnings
);

export const setAggregatedWarningsAction = createAction(
  AnalysisActions.SetAggregatedWarnings,
  props<{ aggregatedWarnings: TransactionWarningAggregation[] }>()
);