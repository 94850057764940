import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { LossHarvestingDetails } from '../../models/loss-harvesting.model';
import * as reportReducer from '../reducers/report.reducer';
import {
  TaxReport,
  TaxReportSummary,
} from './../../models/report.model';

export const REPORTS_FEATURE_NAME = `reports`;

export interface State {
  reports: reportReducer.State;
}

export const selectReportState: MemoizedSelector<State, reportReducer.State> =
  createFeatureSelector<reportReducer.State>(REPORTS_FEATURE_NAME);

export const selectTaxReport: MemoizedSelector<State, TaxReport> =
  createSelector(
    selectReportState,
    (state: reportReducer.State) => state.taxReport
  );

export const selectIsLoading: MemoizedSelector<State, boolean> = createSelector(
  selectReportState,
  (state: reportReducer.State) => state.isLoading
);

export const selectIsGeneratingReport: MemoizedSelector<State, boolean> =
  createSelector(
    selectReportState,
    (state: reportReducer.State) => state.isGeneratingReport
  );

export const selectLastReports: MemoizedSelector<
  State,
  Map<string, TaxReport>
> = createSelector(
  selectReportState,
  (state: reportReducer.State) => state.lastReports
);

export const selectLossHarvestingDetails: MemoizedSelector<
  State,
  LossHarvestingDetails
> = createSelector(
  selectReportState,
  (state: reportReducer.State) => state.lossHarvestingDetails
);

export const selectReportSummary: MemoizedSelector<State, TaxReportSummary> =
  createSelector(
    selectReportState,
    (state: reportReducer.State) => state.reportSummary
  );
