<div
  class="banner"
  [ngClass]="{
    'banner--cta': cta,
    'banner--column': columnDisplay,
    'banner--primary': bannerTheme === 'primary',
    'banner--warning': bannerTheme === 'warning'
  }"
>
  <div class="content">
    <div *ngIf="showBannerIcon" class="banner__icon"></div>

    <div class="content__label">
      <p *ngIf="!useLink && title" class="body-14-bold">{{ title }}</p>
      <app-link *ngIf="useLink" [label]="title" [theme]="'strong'"></app-link>

      <p *ngIf="subtitle" class="body-14">{{ subtitle }}</p>
    </div>
  </div>

  <button *ngIf="cta" type="button" mat-flat-button color="primary" (click)="ctaClick.emit()">{{ cta }}</button>
</div>
