import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from "../../../models/dialog-data.model";
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: `app-basic-dialog`,
  standalone: true,
  templateUrl: `./basic-dialog.component.html`,
  styleUrls: [`./basic-dialog.component.scss`],
  imports: [CommonModule, MatDialogModule, TranslateModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
}
