import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { configurationGuard } from './shared/guards/configuration.guard';
import { mobileGuard } from './shared/guards/mobile.guard';
import { onboardingGuard } from './shared/guards/onboarding.guard';
import { OauthCallbackComponent } from './taxation/components/oauth-callback/oauth-callback.component';
import { ProfilePageComponent } from './taxation/pages/profile-page/profile-page.component';

export const routes: Routes = [
  {
    path: ``,
    component: LayoutComponent,
    canActivate: [AuthGuard, configurationGuard],
    children: [
      {
        path: ``,
        redirectTo: `/accounts`,
        pathMatch: `full`,
      },
      {
        path: `accounts`,
        title: `AccountsTitle`,
        loadComponent: () => import(`./taxation/pages/accounts-page/accounts-page.component`).then((m) => m.AccountsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `accounts/add`,
        title: `AccountsTitle`,
        loadComponent: () => import(`./taxation/pages/accounts-page/accounts-page.component`).then((m) => m.AccountsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `report/:year`,
        title: `ReportTitle`,
        loadComponent: () => import(`./taxation/pages/report-page/report-page.component`).then((m) => m.ReportPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `transactions/add`,
        title: `TransactionsTitle`,
        loadComponent: () =>
          import(
            `./taxation/pages/transactions-creator-page/transactions-creator-page.component`
          ).then((m) => m.TransactionsCreatorPageComponent),
        canActivate: [mobileGuard]
      },
      {
        path: `transactions`,
        title: `TransactionsTitle`,
        loadComponent: () => import(`./taxation/pages/transactions-page/transactions-page.component`).then((m) => m.TransactionsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `transactions/warnings`,
        title: `TransactionsTitle`,
        loadComponent: () => import(`./taxation/pages/transactions-page/transactions-page.component`).then((m) => m.TransactionsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `transactions/taxable`,
        title: `TransactionsTitle`,
        loadComponent: () => import(`./taxation/pages/transactions-page/transactions-page.component`).then((m) => m.TransactionsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `tax-strategy`,
        title: `TaxStrategyTitle`,
        loadComponent: () => import(`./taxation/pages/tax-strategy-page/tax-strategy-page.component`).then((m) => m.TaxStrategyPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `profile`,
        title: `ProfileTitle`,
        component: ProfilePageComponent,
        children: [
          {
            path: `information`,
            loadComponent: () => import(`./taxation/pages/information-page/information-page.component`).then((m) => m.InformationPageComponent),
          },
          {
            path: `preferences`,
            loadComponent: () => import(`./taxation/pages/preferences-page/preferences-page.component`).then((m) => m.PreferencesPageComponent),
          },
          {
            path: `offer`,
            loadComponent: () => import(`./taxation/pages/offer-page/offer-page.component`).then((m) => m.OfferPageComponent),
          },
          {
            path: `sponsorship`,
            loadComponent: () => import(`./taxation/pages/sponsorship-page/sponsorship-page.component`).then((m) => m.SponsorshipPageComponent),
          }
        ],
        canActivate: [mobileGuard]
      }
    ]
  },
  {
    path: `transactions/:id/negative-balances`,
    title: `TransactionsTitle`,
    loadComponent: () => import(`./taxation/pages/negative-balances-page/negative-balances-page.component`).then((m) => m.NegativeBalancesPageComponent),
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `transactions/:id/unknown-prices`,
    title: `TransactionsTitle`,
    loadComponent: () => import(`./taxation/pages/unknown-prices-page/unknown-prices-page.component`).then((m) => m.UnknownPricesPageComponent),
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `onboarding`,
    loadComponent: () =>
      import(`./taxation/pages/onboarding-page/onboarding-page.component`).then(
        (m) => m.OnboardingPageComponent
      ),
    canActivate: [onboardingGuard]
  },
  {
    path: `accept-transfer`,
    loadComponent: () =>
      import(
        `./shared/pages/email-accept-page/email-accept-page.component`
      ).then((m) => m.EmailAcceptPageComponent),
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `2fa`,
    loadComponent: () =>
      import(`./authentication/pages/two-fa-page/two-fa-page.component`).then(
        (m) => m.TwoFAPageComponent
      ),
  },
  {
    path: `coinbase-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `paymium-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `bitstamp-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `revolut-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `callback`,
    loadComponent: () =>
      import(`./authentication/components/callback/callback.component`).then(
        (m) => m.CallbackComponent
      ),
  },
  {
    path: `thank-you`,
    loadComponent: () => import(`./taxation/components/payment-callback/payment-callback.component`).then((m) => m.PaymentCallbackComponent),
  },
  { path: `**`, redirectTo: `/accounts` },
];
// export const routes: Routes = [
//   {
//     path: ``,
//     component: LayoutComponent,
//     canActivate: [AuthGuard, configurationGuard],
//     children: [
//       {
//         path: ``,
//         redirectTo: `/accounts`,
//         pathMatch: `full`,
//       },
//       {
//         path: `accounts`,
//         title: `AccountsTitle`,
//         component: AccountsPageComponent,
//         canActivate: [mobileGuard],
//       },
//       {
//         path: `files`,
//         title: `AccountsTitle`,
//         loadComponent: () =>
//           import(`./taxation/pages/files-page/files-page.component`).then(
//             (m) => m.FilesPageComponent
//           ),
//         canActivate: [mobileGuard, transactionsLimitGuard],
//       },
//       {
//         path: `transactions-creator`,
//         title: `TransactionsTitle`,
//         loadComponent: () =>
//           import(
//             `./taxation/pages/transactions-creator-page/transactions-creator-page.component`
//           ).then((m) => m.TransactionsCreatorPageComponent),
//         canActivate: [mobileGuard, transactionsGuard],
//       },
//       {
//         path: `analysis`,
//         title: `AnalysisTitle`,
//         loadComponent: () =>
//           import(`./taxation/pages/analysis-page/analysis-page.component`).then(
//             (m) => m.AnalysisPageComponent
//           ),
//         canActivate: [mobileGuard, assistantGuard],
//       },
//       {
//         path: `documents`,
//         title: `DocumentsTitle`,
//         loadComponent: () =>
//           import(
//             `./taxation/pages/documents-page/documents-page.component`
//           ).then((m) => m.DocumentsPageComponent),
//         canActivate: [mobileGuard]
//       },
//       {
//         path: `sponsorship`,
//         title: `SponsorshipTitle`,
//         loadComponent: () =>
//           import(
//             `./taxation/pages/sponsorship-page/sponsorship-page.component`
//           ).then((m) => m.SponsorshipPageComponent),
//         canActivate: [mobileGuard]
//       },
//       {
//         path: `reports`,
//         title: `ReportTitle`,
//         loadComponent: () =>
//           import(`./taxation/pages/report-page/report-page.component`).then(
//             (m) => m.ReportPageComponent
//           ),
//         canActivate: [mobileGuard, subscriptionGuard],
//       },
//       {
//         path: `dashboard`,
//         title: `ProfileTitle`,
//         loadComponent: () =>
//           import(`./taxation/pages/profile-page/profile-page.component`).then(
//             (m) => m.ProfilePageComponent
//           ),
//         canActivate: [mobileGuard]
//       },
//     ],
//   },
//   {
//     path: `oauth-checking`,
//     loadComponent: () =>
//       import(
//         `./taxation/pages/oauth-checking-page/oauth-checking-page.component`
//       ).then((m) => m.OauthCheckingPageComponent),
//     canActivate: [AuthGuard, configurationGuard],
//   },
// {
//   path: `oauth-success`,
//   loadComponent: () =>
//     import(
//       `./taxation/pages/oauth-success-page/oauth-success-page.component`
//     ).then((m) => m.OauthSuccessPageComponent),
//   canActivate: [AuthGuard, configurationGuard],
// },
//   {
//     path: `transfer`,
//     loadComponent: () =>
//       import(
//         `./shared/pages/email-transfer-page/email-transfer-page.component`
//       ).then((m) => m.EmailTransferPageComponent),
//     canActivate: [AuthGuard, configurationGuard],
//   },
//   {
//     path: `2fa`,
//     loadComponent: () =>
//       import(`./authentication/pages/two-fa-page/two-fa-page.component`).then(
//         (m) => m.TwoFAPageComponent
//       ),
//     canActivate: [twoFAGuard],
//   },
//   {
//     path: `partner/:id`,
//     loadComponent: () =>
//       import(
//         `./taxation/pages/partner-page/partner-page.component`
//       ).then((m) => m.PartnerPageComponent),
//     canActivate: [partnerGuard],
//   },
//   {
//     path: `mobile-go-desktop`,
//     loadComponent: () =>
//       import(
//         `./shared/pages/errors/mobile-error-page/mobile-error-page.component`
//       ).then((m) => m.MobileErrorPageComponent),
//   },
//   {
//     path: `mobile-welcome`,
//     loadComponent: () =>
//       import(
//         `./shared/pages/mobile-welcome-page/mobile-welcome-page.component`
//       ).then((m) => m.MobileWelcomePageComponent),
//   },
//   {
//     path: `callback`,
//     loadComponent: () =>
//       import(`./authentication/components/callback/callback.component`).then(
//         (m) => m.CallbackComponent
//       ),
//   },

// ];
