import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-link`,
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: `./link.component.html`,
  styleUrls: [`./link.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {
  @Input() label: string;
  @Input() theme = `light`;
}
