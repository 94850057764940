
import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromShared from '../../shared/store/selectors/shared.selector';
import * as fromAuthentication from '../../authentication/store/selectors/authentication.selector';
import { combineLatest } from 'rxjs';
import { User } from '../../taxation/models/user.model';
import { filter, map } from 'rxjs/operators';
import { goToAction } from '../store/actions/shared.action';

export const onboardingGuard: CanActivateFn = () => {
  const sharedStore = inject(Store<fromShared.State>);
  const authenticationStore = inject(Store<fromAuthentication.State>);

  return combineLatest(
    [
      authenticationStore.select(fromAuthentication.selectUser),
      sharedStore.select(fromShared.selectIsOnboardingCompleted)
    ]
  ).pipe(
    filter(([user, isOnboardingCompleted]: [User, boolean]) => !!(user)),
    map(([user, isOnboardingCompleted]: [User, boolean]) => {
      const res = user.needToSignTCs && !isOnboardingCompleted;

      if (!res) {
        sharedStore.dispatch(goToAction({ url: `` }));
      }

      return res;
    })
  );
};