import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-french-tax-grid-cell`,
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule],
  templateUrl: `./french-tax-grid-cell.component.html`,
  styleUrls: [`./french-tax-grid-cell.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrenchTaxGridCellComponent {
  @ViewChild(`tooltip`) tooltip: MatTooltip;

  @Input() value: string;

  tooltipLabel = `Copy`;
  copied = false;

  constructor(public readonly clipboardService: ClipboardService) { }

  copyValue(): void {
    this.copied = true;
    this.clipboardService.copyFromContent(this.value);
    this.tooltipLabel = `Copied`;
    this.tooltip.show();

  }
}
