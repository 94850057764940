import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: `app-french-tax-form-input`,
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule],
  templateUrl: `./french-tax-form-input.component.html`,
  styleUrls: [`./french-tax-form-input.component.scss`]
})
export class FrenchTaxFormInputComponent {
  @ViewChild(`tooltip`) tooltip: MatTooltip;

  @Input() value: string;
  @Input() copiable = false;

  tooltipLabel = `Copy`;
  copied = false;

  constructor(public readonly clipboardService: ClipboardService) { }

  copyValue(): void {
    if (this.copiable) {
      this.copied = true;
      this.clipboardService.copyFromContent(this.value);
      this.tooltipLabel = `Copied`;
      this.tooltip.show();
    }
  }
}
