import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { Assessment } from '../../../taxation/models/assessment.model';

@Component({
  selector: `app-report-status`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MomentModule],
  templateUrl: `./report-status.component.html`,
  styleUrls: [`./report-status.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportStatusComponent {
  @Input() assessment: Assessment;

  formatETA(time: number): string {
    // Hours, minutes and seconds
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = Math.floor((time % 3600) % 60);

    const hrsTxt = hrs > 0 ? `${hrs} h ` : ``;
    const minsTxt = mins > 0 ? `${mins} min ` : ``;
    const secsTxt = secs > 0 ? `${secs} s ` : ``;

    return `${hrsTxt}${minsTxt}${secsTxt}`;
  }
}
