<section class="container">
  <section class="header">
    <h1>Formulaire des comptes à l'étranger</h1>

    <div>
      <mat-button-toggle-group class="custom-button-toggle-group" [(ngModel)]="currentForm">
        <mat-button-toggle value="3916-bis" disableRipple checked>{{ 'Form3916Bis' | translate }}</mat-button-toggle>
        <mat-button-toggle value="2086" disableRipple [disabled]="assessment?.state !== 'COMPLETED'">
          <span *ngIf="!hasPaid" class="demo">{{ 'Demo' | translate }}</span>
          Formulaire 2086
        </mat-button-toggle>
      </mat-button-toggle-group>

      <app-banner
        *ngIf="currentForm === '3916-bis'"
        [title]="'Vos comptes ouverts à l’étranger doivent être déclarés via le formulaire 3916 BIS'"
        [subtitle]="
          'Les plateformes d’échanges non-françaises doivent être déclarées lors de l’ouverture et de la fermeture de cette dernière.'
        "
      ></app-banner>
      <app-banner
        *ngIf="currentForm === '2086'"
        [title]="'Vos transactions imposables doivent être déclarés via le formulaire 2086'"
        [subtitle]="'L’ensemble des cessions imposables effectuées lors de l’année doivent être déclarées.'"
      ></app-banner>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section *ngIf="currentForm === '3916-bis'" class="content">
    <p class="body-14-med">Sélectionnez une plateforme pour voir le remplissage du formulaire</p>

    <div *ngIf="accounts.size > 0" class="platform-select-container">
      <img
        class="platform-logo"
        width="35"
        height="35"
        *ngIf="selectedPlatform"
        [src]="'/assets/img/logos/accounts/' + selectedPlatform + '.png'"
        [default]="'/assets/img/logos/accounts/default.svg'"
        alt=""
      />

      <mat-select
        [(value)]="selectedPlatform"
        disableOptionCentering
        class="custom-select-variant"
        customFocus
        defaultClass="custom-select-variant"
        panelClass="custom-select-variant-panel"
      >
        <mat-option color="primary">
          <ngx-mat-select-search
            [formControl]="platformSearchControl"
            [placeholderLabel]="'Search' | translate"
            [noEntriesFoundLabel]="''"
          ></ngx-mat-select-search>
        </mat-option>

        <mat-divider class="option-divider"></mat-divider>

        <ng-container *ngIf="usedPlatforms.size > 0">
          <p class="body-12-med">Mes plateformes ajoutées</p>
          <mat-option *ngFor="let platform of usedPlatformsOptions$ | async | keyvalue" [value]="platform.key">
            {{ accounts.get(platform.key)?.name }}
          </mat-option>
        </ng-container>

        <ng-container *ngIf="platforms.size > 0">
          <mat-divider *ngIf="usedPlatforms.size > 0" class="option-divider"></mat-divider>
          <p *ngIf="usedPlatforms.size > 0" class="body-12-med">Autres plateformes</p>
          <mat-option *ngFor="let platform of platformsOptions$ | async | keyvalue" [value]="platform.key">
            {{ accounts.get(platform.key)?.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </div>

    <div *ngIf="accounts.size > 0" class="form-3916-bis">
      <div class="form-3916-bis__header">
        <p class="body-14">Prévisualisation de la déclaration d'impôts</p>
      </div>
      <mat-divider></mat-divider>
      <div class="form-3916-bis__content">
        <div class="form-3916-bis__content__title">
          <p class="body-14-bold">
            3 - INFORMATIONS RELATIVES AU COMPTE D'ACTIFS NUMERIQUES ouvert, détenu, utilisé ou clos à l'étranger
          </p>
          <p class="body-14-bold">
            Désignation du compte d'actifs numériques ouvert, détenu, utilisé ou clos à l'étranger
          </p>
        </div>
        <div class="form-items">
          <div class="form-item">
            <p class="body-14">Numéro du compte (*)</p>
            <app-french-tax-form-input [value]="'Renseigner votre numéro de compte'"></app-french-tax-form-input>
          </div>
          <div class="form-item">
            <p class="body-14">Date d'ouverture (au format jj/mm/aaaa) (*)</p>
            <app-french-tax-form-input [value]="'Date douverture du compte'"></app-french-tax-form-input>
          </div>
          <div class="form-item">
            <p class="body-14">Date de fermeture (au format jj/mm/aaaa)</p>
            <app-french-tax-form-input
              [value]="'À remplir si le compte a été fermé cette année'"
            ></app-french-tax-form-input>
          </div>
          <div class="form-item">
            <p class="body-14">Désignation de l’organisme gestionnaire du compte (*)</p>
            <app-french-tax-form-input
              [copiable]="platformsMap.get(selectedPlatform)?.designation"
              [value]="platformsMap.get(selectedPlatform)?.designation || 'DoNotFill' | translate"
            ></app-french-tax-form-input>
          </div>
        </div>
        <div class="form-items">
          <div class="form-item m-l-25">
            <p class="body-14">Pays (*)</p>
            <app-french-tax-form-input
              [copiable]="platformsMap.get(selectedPlatform)?.country"
              [value]="platformsMap.get(selectedPlatform)?.country || 'DoNotFill' | translate"
            ></app-french-tax-form-input>
          </div>
          <div class="form-item m-l-25">
            <p class="body-14">Complément d'adresse</p>
            <app-french-tax-form-input
              [copiable]="platformsMap.get(selectedPlatform)?.additionalAddress"
              [value]="platformsMap.get(selectedPlatform)?.additionalAddress || 'DoNotFill' | translate"
            ></app-french-tax-form-input>
          </div>
          <div class="form-item m-l-25">
            <p class="body-14">Rue</p>
            <app-french-tax-form-input
              [copiable]="platformsMap.get(selectedPlatform)?.street"
              [value]="platformsMap.get(selectedPlatform)?.street || 'DoNotFill' | translate"
            ></app-french-tax-form-input>
          </div>
          <div class="form-item m-l-25">
            <p class="body-14">Indicatif pays - Code postal - Ville</p>
            <app-french-tax-form-input
              [copiable]="platformsMap.get(selectedPlatform)?.zipCode"
              [value]="platformsMap.get(selectedPlatform)?.zipCode || 'DoNotFill' | translate"
            ></app-french-tax-form-input>
          </div>
          <div class="form-item">
            <p class="body-14">URL du site internet de l'organisme gestionnaire du compte</p>
            <app-french-tax-form-input
              [copiable]="platformsMap.get(selectedPlatform)?.url"
              [value]="platformsMap.get(selectedPlatform)?.url || 'DoNotFill' | translate"
            ></app-french-tax-form-input>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="content-2086" *ngIf="currentForm === '2086'">
    <div class="title">
      <div class="title__infos">
        <p class="body-14-med">{{ taxableCessions?.length }} {{ 'TaxableTransfer' | translate }}</p>
        <p *ngIf="taxableCessions?.length > 0" class="body-14-med">{{ 'BoxClick' | translate }}</p>
      </div>

      <button *ngIf="!hasPaid" mat-flat-button color="primary-flash" disableRipple>
        {{ 'Subscribe' | translate }}
      </button>
    </div>

    <section
      class="cessions"
      *ngIf="taxableCessions?.length > 0"
      [ngClass]="{
        'cessions--demo': !hasPaid
      }"
    >
      <!-- First grid -->
      <div class="grid">
        <!-- First row -->
        <div
          *ngFor="let cession of displayedCessions; let first = first; let last = last; let index = index"
          class="grid-header"
          [ngClass]="{
            first: first,
            'border-top-left': first,
            'border-top-right': last
          }"
        >
          <p class="body-14-bold">{{ 'Cession' | translate }} {{ taxableCessions?.indexOf(cession) + 1 }}</p>
        </div>

        <div class="grid-header grid-header--code border-top-left">
          <p class="body-14-bold">211</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell
          *ngFor="let cession of displayedCessions"
          [value]="cession['211']"
        ></app-french-tax-grid-cell>

        <div class="grid-header grid-header--code border-bottom-left">
          <p class="body-14-bold">212</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell
          *ngFor="let cession of displayedCessions; let last = last"
          [ngClass]="{
            'border-bottom-right': last
          }"
          [value]="cession['212']"
        >
        </app-french-tax-grid-cell>
      </div>

      <!-- Second grid -->
      <div class="grid">
        <div class="grid-header grid-header--code border-top-left">
          <p class="body-14-bold">213</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell
          *ngFor="let cession of displayedCessions; let last = last"
          [ngClass]="{
            'border-top-right': last
          }"
          [value]="cession['213']"
        >
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code">
          <p class="body-14-bold">214</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell *ngFor="let cession of displayedCessions" [value]="cession['214']">
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code">
          <p class="body-14-bold">215</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell *ngFor="let cession of displayedCessions" [value]="cession['215']">
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code">
          <p class="body-14-bold">216</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell *ngFor="let cession of displayedCessions" [value]="cession['216']">
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code">
          <p class="body-14-bold">217</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell *ngFor="let cession of displayedCessions" [value]="cession['217']">
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code border-bottom-left">
          <p class="body-14-bold">218</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell
          *ngFor="let cession of displayedCessions; let last = last"
          [ngClass]="{
            'border-bottom-right': last
          }"
          [value]="cession['218']"
        >
        </app-french-tax-grid-cell>
      </div>

      <!-- Last grid -->
      <div class="grid">
        <div class="grid-header grid-header--code border-top-left">
          <p class="body-14-bold">220</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell
          *ngFor="let cession of displayedCessions; let last = last"
          [ngClass]="{
            'border-top-right': last
          }"
          [value]="cession['220']"
        >
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code">
          <p class="body-14-bold">221</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell *ngFor="let cession of displayedCessions" [value]="cession['221']">
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code">
          <p class="body-14-bold">222</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell *ngFor="let cession of displayedCessions" [value]="cession['222']">
        </app-french-tax-grid-cell>

        <div class="grid-header grid-header--code border-bottom-left">
          <p class="body-14-bold">223</p>
          <img class="pointer" src="/assets/img/icons/info-banner-grey.svg" alt="" />
        </div>
        <app-french-tax-grid-cell
          *ngFor="let cession of displayedCessions; let last = last"
          [ngClass]="{
            'border-bottom-right': last
          }"
          [value]="cession['223']"
        >
        </app-french-tax-grid-cell>
      </div>
    </section>
  </section>

  <section class="cessions-paginator" *ngIf="currentForm === '2086' && taxableCessions?.length > 5">
    <p>Page {{ cessionsPageIndex / 5 + 1 }} sur {{ maxCessionsPage }}</p>

    <div class="ctas">
      <button mat-flat-button color="primary" [disabled]="cessionsPageIndex === 0" (click)="updateCessionPage(false)">
        {{ 'PaginatorPreviousPageLabel' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="cessionsPageIndex / 5 + 1 === maxCessionsPage"
        (click)="updateCessionPage()"
      >
        {{ 'PaginatorNextPageLabel' | translate }}
      </button>
    </div>
  </section>
</section>
