import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountListResponse } from '../../taxation/models/account.model';
import { environment } from '../../../environments/environment';
import { UserAccount } from '../../taxation/models/user-account.model';

@Injectable({
  providedIn: `root`,
})
export class AccountService {
  constructor(private readonly http: HttpClient) { }

  /**
   * Get list of all supported accounts
   * 
   * @returns accounts list response
   */
  getAccounts(): Observable<AccountListResponse> {
    return this.http.get<AccountListResponse>(
      `${environment.apiUrl}/v1/tax/account/list`
    );
  }

  getUserAccounts(): Observable<UserAccount[]> {
    return this.http.get<UserAccount[]>(`${environment.apiUrl}/v1/tax/account/user/list`);
  }
}
