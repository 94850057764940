<div
  class="toast"
  [ngClass]="{
  'toast--success': data.type === 'success',
  'toast--error': data.type === 'error',
}"
>
  <img *ngIf="data.type === 'success'" src="/assets/img/icons/success.svg" alt="" />
  <img *ngIf="data.type === 'error'" src="/assets/img/icons/error.svg" alt="" />

  <p class="body-14">{{ data.message }}</p>

  <img class="pointer" src="/assets/img/icons/close-white.svg" alt="" (click)="snackBarRef.closeWithAction()" />
</div>
