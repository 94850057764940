/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { AvailableAddons, AvailablePlans, CheckoutType, Payment, PaymentEstimateV3, PaymentMethod, PendingCharge } from '../../models/payment.model';
import { UserAddon, UserPlan } from '../../models/user.model';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { AddOnDetails } from '../../models/addon.model';

// eslint-disable-next-line no-shadow
export enum PaymentActions {
  // Plan
  LoadAvailablePlans = `[Payment] Load Available Plans`,
  SetAvailablePlans = `[Payment] Set Available Plans`,
  LoadPlanEstimate = `[Payment] Load Plan Estimate`,
  SetPlanEstimate = `[Payment] Set Plan Estimate`,
  UpgradePlan = `[Payment] Upgrade Plan`,

  // Addon
  LoadAvailableAddons = `[Payment] Load Available Addons`,
  SetAvailableAddons = `[Payment] Set Available Addons`,
  LoadAddonsDetails = `[Payment] Load Addons Details`,
  SetAddonsDetails = `[Payment] Set Addons Details`,
  LoadAddonEstimate = `[Payment] Load Addon Estimate`,
  SetAddonEstimate = `[Payment] Set Addon Estimate`,
  EnableAddon = `[Payment] Enable Addon`,

  // Charge
  LoadPendingCharge = `[Payment] Load Pending Charge`,
  SetPendingCharge = `[Payment] Set Pending Charge`,
  PayCharge = `[Payment] Pay Charge`,

  // Subscription
  LoadSubscriptionDetails = `[Payment] Load Subscription Details`,
  SetSubscriptionDetails = `[Payment] Set Subscription Details`,
  RenewSubscription = `[Payment] Renew Subscription`,

  // Payment
  LoadPayments = `[Payment] Load Payments`,
  SetPayments = `[Payment] Set Payments`,
  ValidatePayment = `[Payment] Validate Payment`,
  SetValidatedPayment = `[Payment] Set Validated Payment`,

  // Invoice
  DownloadInvoice = `[Payment] Download Invoice`,

  // Vault
  ApplyVault = `[Payment] Apply Vault`,

  // Voucher
  CheckVoucherCode = `[Payment] Check Voucher Code`,
  SetVoucherResponse = `[Payment] Set Voucher Response`,
}

export const loadPaymentsAction = createAction(PaymentActions.LoadPayments);

export const setPaymentsAction = createAction(PaymentActions.SetPayments, props<{ payments: Payment[] }>());

export const loadAvailablePlansAction = createAction(PaymentActions.LoadAvailablePlans);

export const setAvailablePlansAction = createAction(
  PaymentActions.SetAvailablePlans,
  props<{ availablePlans: AvailablePlans }>()
);

export const loadSubscriptionDetailsAction = createAction(PaymentActions.LoadSubscriptionDetails);

export const setSubscriptionDetailsAction = createAction(
  PaymentActions.SetSubscriptionDetails,
  props<{ subscriptionDetails: any }>()
);

export const applyVaultAction = createAction(PaymentActions.ApplyVault);

export const checkVoucherCodeAction = createAction(
  PaymentActions.CheckVoucherCode,
  props<{
    voucherCode: string;
    fiscalYear: number;
    checkoutType: CheckoutType;
    requestedPlan?: UserPlan;
    requestedAddOn?: UserAddon;
    useVault: boolean;
  }>()
);

export const upgradePlanAction = createAction(
  PaymentActions.UpgradePlan,
  props<{
    paymentMethod: PaymentMethod;
    requestedPlan: UserPlan;
    fiscalYear: number;
    papCookie?: string;
    tags?: string;
    useVault?: boolean;
    code?: string;
  }>()
);

export const validatePaymentAction = createAction(
  PaymentActions.ValidatePayment,
  props<{
    paymentId: string;
  }>()
);

export const setValidatedPaymentAction = createAction(
  PaymentActions.SetValidatedPayment,
  props<{
    payment: Payment;
  }>()
);

export const downloadInvoiceAction = createAction(PaymentActions.DownloadInvoice, props<{ paymentId: string }>());

export const renewSubscriptionAction = createAction(
  PaymentActions.RenewSubscription,
  props<{ requestedPlan: UserPlan }>()
);

export const setVoucherResponseAction = createAction(
  PaymentActions.SetVoucherResponse,
  props<{ voucherResponse: GenericResponse }>()
);

export const loadAvailableAddonsAction = createAction(PaymentActions.LoadAvailableAddons);

export const setAvailableAddonsAction = createAction(
  PaymentActions.SetAvailableAddons,
  props<{ availableAddons: AvailableAddons }>()
);

export const loadAddonsDetailsAction = createAction(PaymentActions.LoadAddonsDetails);

export const setAddonsDetailsAction = createAction(
  PaymentActions.SetAddonsDetails,
  props<{ addonsDetails: AddOnDetails[] }>()
);

export const loadAddonEstimateAction = createAction(
  PaymentActions.LoadAddonEstimate,
  props<{ requestedAddOn?: UserAddon, useVault?: boolean, code?: string; fiscalYear: number }>()
);

export const setAddonEstimateAction = createAction(
  PaymentActions.SetAddonEstimate,
  props<{ addonEstimate: PaymentEstimateV3 }>()
);

export const enableAddonAction = createAction(
  PaymentActions.EnableAddon,
  props<{ paymentMethod: PaymentMethod, requestedAddOn: UserAddon, useVault: boolean, code: string; fiscalYear: number }>()
);

export const loadPendingChargeAction = createAction(PaymentActions.LoadPendingCharge);

export const setPendingChargeAction = createAction(
  PaymentActions.SetPendingCharge,
  props<{ pendingCharge: PendingCharge }>()
);

export const payChargeAction = createAction(PaymentActions.PayCharge, props<{ paymentMethod: PaymentMethod }>());

export const loadPlanEstimateAction = createAction(
  PaymentActions.LoadPlanEstimate,
  props<{ fiscalYear: number, requestedPlan?: UserPlan, useVault?: boolean, code?: string }>()
);

export const setPlanEstimateAction = createAction(
  PaymentActions.SetPlanEstimate,
  props<{ planEstimate: PaymentEstimateV3 }>()
);