import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: `https://data.waltio.com/dev`,
  stripe: `pk_test_yGOzNQb36wgIdWf66kUKqXPG`,
  couchDbUrl: `couchdb/assessment-dev`,
  gtmId: `GTM-PP7WSH58`,
  auth0: {
    callback: `https://next.waltio.com/callback`,
    clientID: `FT4ZHslb0wlgIzj8hnj0jBdLYeq7UChL`,
    domain: `auth.waltio.com`,
  },
  intercom: {
    secret: `RYggsG21Lm43TU0KCBCbN3RaiC4wQHgQLvBgHd-C`,
  },
  features: {
    pattern: `tax:dev:`,
  },
};
