import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IzanamiResponse } from '../models/feature.model';

@Injectable({
  providedIn: `root`,
})
export class FeatureService {
  constructor(private readonly http: HttpClient) {}

  /**
   *  Get all features from Izanami
   *
   * @param page
   * @param pageSize
   * @param pattern
   * @returns
   */
  getFeatures(
    page = 1,
    pageSize = 9999,
    pattern = `*`
  ): Observable<IzanamiResponse> {
    return this.http.get<IzanamiResponse>(
      `${environment.apiUrl}/v1/tax/app/features?page=${page}&pageSize=${pageSize}&pattern=${environment.features.pattern}${pattern}`
    );
  }
}
