import { Pipe, PipeTransform } from '@angular/core';
import { UserPreferences } from '../models/user-preferences.model';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: `currencyDecimal`,
  standalone: true,
})
export class CurrencyDecimalPipe implements PipeTransform {

  constructor(
    private readonly currencyPipe: CurrencyPipe
  ) { }

  transform(
    amount: number,
    userPreferences: UserPreferences,
    stablecoins: string[],
    fiats: string[],
    currency = `EUR`
  ): string {
    if (fiats.includes(currency)) {
      const digitsInfo = `1.2-`.concat(userPreferences.numberOfDecimalsForFiat.toString());
      return this.currencyPipe.transform(amount, currency, ``, digitsInfo, userPreferences.language);
    } else {
      const decimals = stablecoins.includes(currency) ? userPreferences.numberOfDecimalsForStable : userPreferences.numberOfDecimalsForCrypto;
      return amount?.toFixed(decimals);
    }
  }
}
