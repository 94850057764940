/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { setUserAction } from '../../../authentication/store/actions/authentication.action';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import {
  Account,
  AccountListResponse,
} from '../../../taxation/models/account.model';
import { Balance } from '../../../taxation/models/balance.model';
import { GeolocationDetails } from '../../../taxation/models/geolocation-details.model';
import { TaxReport } from '../../../taxation/models/report.model';
import { TokenConfidence } from '../../../taxation/models/token-confidence.model';
import { User, UserExistence } from '../../../taxation/models/user.model';
import { AnalysisService } from '../../../taxation/services/analysis.service';
import { ShitcoinService } from '../../../taxation/services/shitcoin.service';
import { TransactionService } from '../../../taxation/services/transaction.service';
import { setGDPRConsentAction } from '../../../taxation/store/actions/analysis.action';
import * as fromReport from '../../../taxation/store/selectors/report.selector';
import { DEFAULT_CONFIGURATION } from '../../constants/configuration.constant';
import { Configuration } from '../../models/configuration.model';
import { CustomError } from '../../models/error.model';
import { Feature, IzanamiResponse } from '../../models/feature.model';
import { GenericResponse } from '../../models/generic-response.model';
import { PageTag, Tag, UserTag } from '../../models/tag.model';
import { TransferRequest } from '../../models/transfer-request.model';
import { UserPreferences } from '../../models/user-preferences.model';
import { AccountService } from '../../services/account.service';
import { AppService } from '../../services/app.service';
import { ConfigurationService } from '../../services/configuration.service';
import { FeatureService } from '../../services/feature.service';
import { IntercomService } from '../../services/intercom.service';
import { OnboardingService } from '../../services/onboarding.service';
import { ToastService } from '../../services/toast.service';
import { TrackingService } from '../../services/tracking.service';
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../services/utils.service';
import {
  addShitcoinAction,
  approveEmailTransferAction,
  cancelEmailTransferAction,
  checkUserExistenceAction,
  completeOnboardingAction,
  deleteShitcoinAction,
  getActiveEmailTransferAction,
  goToAction,
  loadAccountsAction,
  loadAnalysisCountAction,
  loadBelgianFiscalProfilesAction,
  loadCoinsAction,
  loadConfigurationAction,
  loadFeaturesAction,
  loadFiatsAction,
  loadFrenchPostCodesAction,
  loadGTMAction,
  loadShitcoinsAction,
  loadSpanishAutonomousCommunitiesAction,
  loadStablecoinsAction,
  loadTokenConfidenceAction,
  loadUserBalanceAction,
  loadUserLocationAction,
  loadUserPreferencesAction,
  pingOutUserAction,
  pingUserAction,
  pushTagAction,
  setAccountsAction,
  setAnalysisCountAction,
  setBelgianFiscalProfilesAction,
  setCoinsAction,
  setConfigurationAction,
  setEmailTransferRequestAction,
  setFeaturesAction,
  setFiatsAction,
  setFrenchPostCodesAction,
  setHasEmailTransferRequestAction,
  setOnboardingCompletedAction,
  setOnboardingErrorAction,
  setPartnerAction,
  setShitcoinsAction,
  setSpanishAutonomousCommunitiesAction,
  setStablecoinsAction,
  setTokenConfidenceAction,
  setUserAcquisitionChannelAction,
  setUserBalanceAction,
  setUserLocationAction,
  setUserPreferencesAction,
  startEmailTransferAction,
  updateConfigurationAction,
  updateUserPreferencesAction,
} from '../actions/shared.action';
import { selectQueryParams } from '../selectors/router.selector';
import * as fromShared from '../selectors/shared.selector';

@Injectable()
export class SharedEffects {

  loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAccountsAction>>(loadAccountsAction),
      withLatestFrom(
        this.sharedStore$.pipe(select(fromShared.selectAllFeatures))
      ),
      switchMap(
        ([action, features]: [
          ReturnType<typeof loadAccountsAction>,
          Feature[]
        ]) =>
          this.accountService.getAccounts().pipe(
            map((accounts: AccountListResponse) => {
              accounts.platform = this.checkAccountsFeatures(
                features,
                accounts.platform
              );
              accounts.blockchain = this.checkAccountsFeatures(
                features,
                accounts.blockchain
              );
              accounts.wallet = this.checkAccountsFeatures(
                features,
                accounts.wallet
              );
              accounts.service = this.checkAccountsFeatures(
                features,
                accounts.service
              );

              return setAccountsAction({ accounts });
            })
          )
      )
    )
  );

  loadCoins$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadCoinsAction>>(loadCoinsAction),
      switchMap(() => this.transactionService.getCoinsWithNames()),
      map((coins: Map<string, string>) => setCoinsAction({ coins }))
    )
  );

  loadConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadConfigurationAction>>(
        loadConfigurationAction
      ),
      withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
      switchMap(
        ([action, user]: [ReturnType<typeof loadConfigurationAction>, User]) =>
          this.configurationService.getConfiguration(user?.id).pipe(
            map((configuration: Configuration) => {
              const config = configuration ?? DEFAULT_CONFIGURATION;

              if (config.pagination.pageSort === `date`) {
                config.pagination.pageSort = `transactionDate`;
              }

              if (!config.pagination.pageDirection) {
                config.pagination.pageDirection = `desc`;
              }

              return setConfigurationAction({ configuration: config });
            })
          )
      )
    )
  );

  updateConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateConfigurationAction>>(
        updateConfigurationAction
      ),
      withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
      switchMap(
        ([action, user]: [
          ReturnType<typeof updateConfigurationAction>,
          User
        ]) =>
          this.configurationService
            .updateConfiguration(user?.id, action.configuration)
            .pipe(
              map(() =>
                setConfigurationAction({ configuration: action.configuration })
              )
            )
      )
    )
  );

  loadAnalysisCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAnalysisCountAction>>(
        loadAnalysisCountAction
      ),
      switchMap((action: ReturnType<typeof loadAnalysisCountAction>) =>
        this.analysisService.getAnalysisCount().pipe(
          map((analysisCount: number) =>
            setAnalysisCountAction({
              analysisCount,
            })
          )
        )
      )
    )
  );

  setAnalysisCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setAnalysisCountAction>>(setAnalysisCountAction),
      map((action: ReturnType<typeof setAnalysisCountAction>) => {
        this.intercomService.updateAnalysisCount(action.analysisCount);

        if (action.analysisCount >= 1) {
          return setGDPRConsentAction({ gdprConsent: true });
        } else {
          return setGDPRConsentAction({ gdprConsent: false });
        }
      })
    )
  );

  loadPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserPreferencesAction>>(
        loadUserPreferencesAction
      ),
      switchMap(() =>
        this.userService
          .getUserPreferences()
          .pipe(
            map((userPreferences: UserPreferences) =>
              setUserPreferencesAction({ userPreferences })
            )
          )
      )
    )
  );

  setUserPreference$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof setUserPreferencesAction>>(
          setUserPreferencesAction
        ),
        tap((action: ReturnType<typeof setUserPreferencesAction>) => {
          this.utilsService.switchLanguage(action.userPreferences.language);
        })
      ),
    { dispatch: false }
  );

  updatePreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateUserPreferencesAction>>(
        updateUserPreferencesAction
      ),
      switchMap((action: ReturnType<typeof updateUserPreferencesAction>) =>
        this.userService.updateUserPreferences(action.userPreferences).pipe(
          map((userPreferences: UserPreferences) => {
            this.utilsService.switchLanguage(action.userPreferences.language);

            if (action.showToast) {
              this.toastService.success(this.translateService.instant(`PreferencesUpdated`));
            }

            return setUserPreferencesAction({ userPreferences });
          }),
          catchError((error: CustomError) => {
            const errorMessage: string =
              this.translateService.instant(error.errorCode) === error.errorCode
                ? error.message
                : this.translateService.instant(error.errorCode);

            this.toastService.error(errorMessage);

            return EMPTY;
          })
        )
      )
    )
  );

  loadFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadFeaturesAction>>(loadFeaturesAction),
      switchMap((action: ReturnType<typeof loadFeaturesAction>) =>
        this.featureService.getFeatures()
      ),
      map((izanamiResponse: IzanamiResponse) =>
        setFeaturesAction({ features: izanamiResponse.results })
      )
    )
  );

  loadStableCoins$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadStablecoinsAction>>(loadStablecoinsAction),
      switchMap((action: ReturnType<typeof loadStablecoinsAction>) =>
        this.appService.getStableCoins()
      ),
      map((stablecoins: string[]) => setStablecoinsAction({ stablecoins }))
    )
  );

  loadFiats$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadFiatsAction>>(loadFiatsAction),
      switchMap((action: ReturnType<typeof loadFiatsAction>) =>
        this.appService.getFiats()
      ),
      map((fiats: string[]) => setFiatsAction({ fiats }))
    )
  );

  pingUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof pingUserAction>>(pingUserAction),
        switchMap(() => this.userService.pingUser())
      ),
    { dispatch: false }
  );

  pingOutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof pingOutUserAction>>(pingOutUserAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        switchMap(
          ([action, user]: [ReturnType<typeof pingOutUserAction>, User]) => {
            const data = {
              userId: user.email,
            };

            const blob: Blob = new Blob([JSON.stringify(data)], {
              type: `application/json`,
            });
            navigator.sendBeacon(
              `${environment.apiUrl}/v1/tax/user/ping-out`,
              blob
            );
            return EMPTY;
          }
        )
      ),
    { dispatch: false }
  );

  startEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof startEmailTransferAction>>(
        startEmailTransferAction
      ),
      switchMap((action: ReturnType<typeof startEmailTransferAction>) =>
        this.userService.startEmailTransfer(action.recipient).pipe(
          map((transferRequest: TransferRequest) => {
            this.router.navigateByUrl(``);
            this.toastService.success(`${this.translateService.instant(`TransferEmailSendedTo`)} ${transferRequest.recipient}`);

            return setEmailTransferRequestAction({
              emailTransferRequest: transferRequest,
            });
          }),
          catchError((error: Error) => EMPTY)
        )
      )
    )
  );

  getActiveEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getActiveEmailTransferAction>>(
        getActiveEmailTransferAction
      ),
      switchMap((action: ReturnType<typeof getActiveEmailTransferAction>) =>
        this.userService.getActiveEmailTransfer().pipe(
          map((transferRequest: TransferRequest) =>
            setEmailTransferRequestAction({
              emailTransferRequest: transferRequest,
            })
          )
        )
      )
    )
  );

  approveEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof approveEmailTransferAction>>(
        approveEmailTransferAction
      ),
      withLatestFrom(this.store$.select(selectQueryParams)),
      switchMap(
        ([action, params]: [
          ReturnType<typeof approveEmailTransferAction>,
          Params
        ]) => {
          if (!params[`id`] || !params[`code`]) {
            this.router.navigateByUrl(``);
            return EMPTY;
          } else {
            return this.userService
              .approveEmailTransfer(params[`id`], params[`code`])
              .pipe(
                map((transferRequest: TransferRequest) =>
                  setEmailTransferRequestAction({
                    emailTransferRequest: transferRequest,
                  })
                ),
                catchError((error: CustomError) => {
                  const errorMessage: string =
                    this.translateService.instant(error.errorCode) ===
                      error.errorCode
                      ? error.message
                      : this.translateService.instant(error.errorCode);

                  this.toastService.error(errorMessage);

                  this.router.navigateByUrl(``);
                  return [
                    setHasEmailTransferRequestAction({
                      hasEmailTransferError: true,
                    }),
                  ];
                })
              );
          }
        }
      )
    )
  );

  cancelEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof cancelEmailTransferAction>>(
        cancelEmailTransferAction
      ),
      switchMap((action: ReturnType<typeof cancelEmailTransferAction>) =>
        this.userService.cancelEmailTransfer(action.requestId).pipe(
          map((transferRequest: TransferRequest) => {
            this.toastService.success(`${this.translateService.instant(`TransferEmailCancelled`)}`);

            return setEmailTransferRequestAction({
              emailTransferRequest: transferRequest,
            });
          }),
          catchError((error: Error) => EMPTY)
        )
      )
    )
  );

  loadShitcoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadShitcoinsAction>>(loadShitcoinsAction),
      switchMap((action: ReturnType<typeof loadShitcoinsAction>) =>
        this.shitcoinService.getShitcoins().pipe(
          map((shitcoins: string[]) => setShitcoinsAction({ shitcoins })),
          catchError((error: any) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  addShitcoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof addShitcoinAction>>(addShitcoinAction),
      switchMap((action: ReturnType<typeof addShitcoinAction>) =>
        this.shitcoinService.addShitcoin(action.shitcoin).pipe(
          switchMap((res: GenericResponse) => {
            if (res.success) {
              this.toastService.success(`${action.shitcoin} ${this.translateService.instant(
                // eslint-disable-next-line quotes
                'TokenAdded'
              )}`);
              const tag: UserTag = {
                event: `add_shitcoin`,
                token_name: action.shitcoin
              };

              return [pushTagAction({ tag }), loadShitcoinsAction()];
            } else {
              this.toastService.error(
                this.translateService.instant(`FailedToAddTokenErrorMsg`)
              );

              return EMPTY;
            }
          }),
          catchError((error: any) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  deleteShitcoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteShitcoinAction>>(deleteShitcoinAction),
      switchMap((action: ReturnType<typeof deleteShitcoinAction>) =>
        this.shitcoinService.deleteShitcoin(action.shitcoin).pipe(
          switchMap((res: GenericResponse) => {
            if (res.success) {
              this.toastService.success(`${action.shitcoin} ${this.translateService.instant(
                // eslint-disable-next-line quotes
                'TokenRemoved'
              )}`);

              const tag: UserTag = {
                event: `remove_shitcoin`,
                token_name: action.shitcoin
              };
              return [pushTagAction({ tag }), loadShitcoinsAction()];
            } else {
              this.toastService.error(
                this.translateService.instant(`FailedToRemoveTokenErrorMsg`)
              );
              return EMPTY;
            }
          }),
          catchError((error: any) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  goTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof goToAction>>(goToAction),
        map((action: ReturnType<typeof goToAction>) => {
          this.router
            .navigate([action.url], {
              queryParams: action.queryParams,
            })
            .then(() => {
              if (action.reloadPage) {
                location.reload();
              }
            });
        })
      ),
    { dispatch: false }
  );

  completeOnboarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof completeOnboardingAction>>(
        completeOnboardingAction
      ),
      switchMap((action: ReturnType<typeof completeOnboardingAction>) =>
        this.onboardingService
          .completeOnboarding(
            action.affiliateId,
            action.user,
            action.userPreferences
          )
          .pipe(
            switchMap((user: User) => {
              const tag: UserTag = {
                event: `onboarding_success`,
                language: action.userPreferences.language,
                environment_market: user.fiscalResidency,
                local_settings: user.localSettings?.fr?.postCode || user.localSettings?.be?.fiscalProfile || user.localSettings?.es?.autonomousCommunities,
                sponsorship_code: action.affiliateId,
                accept_mails: action.userPreferences.acceptsInformativeCommunication
              };

              return [
                pingUserAction(),
                setOnboardingCompletedAction(),
                pushTagAction({ tag })
              ];
            }),
            catchError((onboardingError: Error) => {
              return [setOnboardingErrorAction({ onboardingError })];
            })
          )
      )
    )
  );

  setOnboardingCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setOnboardingCompletedAction>>(
        setOnboardingCompletedAction
      ),
      withLatestFrom(
        this.authStore$.pipe(select(fromAuth.selectUser)),
        this.sharedStore$.pipe(select(fromShared.selectResponsiveBreakpoint))
      ),
      map(([action, user, responsiveBreakpoint]: [ReturnType<typeof setOnboardingCompletedAction>, User, number]) => {
        const authCallbackUrl = localStorage.getItem(`auth_callback_url`);
        if (authCallbackUrl) {
          const { url, queryParams } =
            this.utilsService.serializeRoute(authCallbackUrl);
          return goToAction({ url, queryParams });
        } else if (responsiveBreakpoint < 2) {
          return goToAction({ url: user?.plan === `PLN000` ? `mobile-welcome` : `mobile-go-desktop` });
        } else {
          return goToAction({ url: `accounts` });
        }
      })
    )
  );


  loadUserLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserLocationAction>>(loadUserLocationAction),
      switchMap((action: ReturnType<typeof loadUserLocationAction>) =>
        this.userService.getUserLocation().pipe(
          map((userLocation: GeolocationDetails) => {
            if (userLocation.languages) {
              const lang = userLocation.languages.substring(0, 2);
              this.utilsService.switchLanguage(lang);
            }

            return setUserLocationAction({ userLocation });
          }),
          catchError((error: Error) => {
            this.toastService.error(error.message);
            return EMPTY;
          })
        )
      )
    )
  );

  loadFrenchPostCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadFrenchPostCodesAction>>(
        loadFrenchPostCodesAction
      ),
      switchMap((action: ReturnType<typeof loadFrenchPostCodesAction>) =>
        this.appService
          .getFrenchPostCodes()
          .pipe(
            map((frenchPostCodes: Map<string, string>) =>
              setFrenchPostCodesAction({ frenchPostCodes })
            )
          )
      )
    )
  );

  loadBelgianFiscalProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadBelgianFiscalProfilesAction>>(
        loadBelgianFiscalProfilesAction
      ),
      switchMap((action: ReturnType<typeof loadBelgianFiscalProfilesAction>) =>
        this.onboardingService
          .getBelgianFiscalProfiles()
          .pipe(
            map((belgianFiscalProfiles: Map<string, string>) =>
              setBelgianFiscalProfilesAction({ belgianFiscalProfiles })
            )
          )
      )
    )
  );

  loadSpanishAutonomousCommunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadSpanishAutonomousCommunitiesAction>>(
        loadSpanishAutonomousCommunitiesAction
      ),
      switchMap(
        (action: ReturnType<typeof loadSpanishAutonomousCommunitiesAction>) =>
          this.appService
            .getSpanishAutonomousCommunities()
            .pipe(
              map((spanishAutonomousCommunities: Map<string, string>) =>
                setSpanishAutonomousCommunitiesAction({
                  spanishAutonomousCommunities,
                })
              )
            )
      )
    )
  );

  loadUserBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserBalanceAction>>(loadUserBalanceAction),
      withLatestFrom(
        this.reportStore$.pipe(select(fromReport.selectTaxReport))
      ),
      switchMap(
        ([action, report]: [
          ReturnType<typeof loadUserBalanceAction>,
          TaxReport
        ]) =>
          this.userService.getUserBalance(report.started).pipe(
            map((userBalance: Balance) =>
              setUserBalanceAction({ userBalance })
            ),
            catchError((error: CustomError) => {
              const message = error.errorCode ?? error.message;

              this.toastService.error(message);

              return EMPTY;
            })
          )
      )
    )
  );

  loadTokenConfidence$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTokenConfidenceAction>>(
        loadTokenConfidenceAction
      ),

      switchMap((action: ReturnType<typeof loadTokenConfidenceAction>) =>
        this.shitcoinService.getTokenConfidence(action.currency).pipe(
          map((tokenConfidence: TokenConfidence) =>
            setTokenConfidenceAction({ tokenConfidence })
          ),
          catchError((error: CustomError) => {
            const message = error.errorCode ?? error.message;

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  checkUserExistence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof checkUserExistenceAction>>(
          checkUserExistenceAction
        ),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectAuthUser))),
        switchMap(
          ([action, user]: [
            ReturnType<typeof checkUserExistenceAction>,
            User
          ]) =>
            this.userService.checkUserExistence(action.email).pipe(
              map((userExistence: UserExistence) => {
                if (!action.email) {
                  sessionStorage.setItem(`waltio_user`, user.email);
                } else if (userExistence.exists) {
                  sessionStorage.setItem(`waltio_user`, action.email);
                }

                window.location.reload();
              }),
              catchError((error: CustomError) => {
                const message = error.errorCode ?? error.message;

                this.toastService.error(message);

                return EMPTY;
              })
            )
        )
      ),
    { dispatch: false }
  );

  loadGTM$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof loadGTMAction>>(loadGTMAction),
        map(() => {
          this.trackingService.loadGTM();
        })
      ),
    { dispatch: false }
  );

  pushTag$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof pushTagAction>>(pushTagAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        map(([action, user]: [ReturnType<typeof pushTagAction>, User]) => {
          let tag: Tag;
          if (action.tag.event === `page_view`) {
            const pageTag: PageTag = {
              event: `page_view`,
              environment_name: environment.production ? `production` : `dev`,
              environment_market: user?.fiscalResidency,
              user_login_status: user ? `logged in` : `logged out`,
              user_type: user?.plan === `PLN000` ? `registered` : `customer`,
            };

            tag = pageTag;
          } else {
            tag = action.tag;
          }

          this.trackingService.pushTag(tag);
        })
      ),
    { dispatch: false }
  );

  setUserAcquisitionChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof setUserAcquisitionChannelAction>>(setUserAcquisitionChannelAction),
        switchMap((action: ReturnType<typeof setUserAcquisitionChannelAction>) =>
          this.userService
            .setUserAcquisitionChannel(action.acquisitionChannel)
            .pipe(
              map((user: User) =>
                setUserAction({ user })
              )
            )
        )
      ),
  );


  setPartner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof setPartnerAction>>(
          setPartnerAction
        ),
        tap((action: ReturnType<typeof setPartnerAction>) => {

          if (action.partner) {
            sessionStorage.setItem(`partner`, action.partner);
          } else {
            sessionStorage.removeItem(`partner`);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly utilsService: UtilsService,
    private readonly appService: AppService,
    private readonly accountService: AccountService,
    private readonly analysisService: AnalysisService,
    private readonly transactionService: TransactionService,
    private readonly configurationService: ConfigurationService,
    private readonly featureService: FeatureService,
    private readonly translateService: TranslateService,
    private readonly onboardingService: OnboardingService,
    private readonly userService: UserService,
    private readonly trackingService: TrackingService,
    private readonly intercomService: IntercomService,
    private readonly shitcoinService: ShitcoinService,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly reportStore$: Store<fromReport.State>,
    private readonly router: Router
  ) { }

  checkAccountsFeatures(features: Feature[], accounts: Account[]): Account[] {
    return accounts.filter((account: Account) => {
      const accountFeature: Feature = features.find(
        (feature: Feature) =>
          feature.id ===
          `${environment.features.pattern}accounts:${account.key}`
      );

      if (!accountFeature?.enabled) {
        return false;
      } else {
        const fileFeature = features.find(
          (feature: Feature) =>
            feature.id ===
            `${environment.features.pattern}accounts:${account.key}:file`
        );
        const apiFeature = features.find(
          (feature: Feature) =>
            feature.id ===
            `${environment.features.pattern}accounts:${account.key}:api`
        );

        const subAccountFeature = features.find(
          (feature: Feature) =>
            feature.id ===
            `${environment.features.pattern}accounts:${account.key}:subAccount`
        );

        const oauthFeature = features.find(
          (feature: Feature) =>
            feature.id ===
            `${environment.features.pattern}accounts:${account.key}:oauth`
        );

        return (
          (fileFeature?.enabled && account.supportsFile) ||
          (apiFeature?.enabled && account.supportsAPI) ||
          (subAccountFeature?.enabled && account.supportsSubAccount) ||
          (oauthFeature?.enabled && account.supportsAPIviaOAuth2)
        );
      }
    });
  }
}
