/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUserEvent } from '../../taxation/models/auth-user-event.model';
import { environment } from '../../../environments/environment';
import { AcquisitionChannel, User, UserExistence } from '../../taxation/models/user.model';
import { GenericResponse } from '../models/generic-response.model';
import { Page } from '../models/page.model';
import { TransferRequest } from '../models/transfer-request.model';
import { UserPreferences } from '../models/user-preferences.model';
import { GeolocationDetails } from '../../taxation/models/geolocation-details.model';
import moment from 'moment';
import { Balance } from '../../taxation/models/balance.model';

@Injectable({
  providedIn: `root`,
})
export class UserService {
  constructor(private readonly http: HttpClient) { }

  /**
   * Get user profile
   */
  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/v1/tax/user`);
  }

  /**
   * Save user profile
   *
   * @param user
   */
  saveUser(user: User): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/v1/tax/user`, user);
  }

  /**
   * Reset profile
   */
  resetUser(): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/v1/tax/user/reset`, ``);
  }

  /**
   * Save disclaimer response
   */
  acceptDisclaimer(): Observable<User> {
    return this.http.post<User>(
      `${environment.apiUrl}/v1/tax/user/disclaimer`,
      ``
    );
  }

  /**
   * Get user preferences
   */
  getUserPreferences(): Observable<UserPreferences> {
    return this.http
      .get<UserPreferences>(`${environment.apiUrl}/v1/tax/user/preferences`)
      .pipe(
        map((userPreferences: UserPreferences) => {
          userPreferences.incrementalUploadEnabled = new Map<string, boolean>(
            Object.entries(userPreferences.incrementalUploadEnabled)
          );
          return userPreferences;
        })
      );
  }

  /**
   * Update user preferences
   */
  updateUserPreferences(
    userPreferences: UserPreferences
  ): Observable<UserPreferences> {
    // Convert Map to JSON object
    userPreferences = {
      ...userPreferences,
      // @ts-ignore
      incrementalUploadEnabled: Object.fromEntries(userPreferences.incrementalUploadEnabled),
    };

    return this.http
      .put<UserPreferences>(
        `${environment.apiUrl}/v1/tax/user/preferences`,
        userPreferences
      )
      .pipe(
        map((updatedPreferences: UserPreferences) => {
          updatedPreferences.incrementalUploadEnabled = new Map<
            string,
            boolean
          >(Object.entries(updatedPreferences.incrementalUploadEnabled));
          return updatedPreferences;
        })
      );
  }

  /**
   * Delete user
   *
   * @returns deletion success status
   */
  deleteUser(): Observable<GenericResponse> {
    return this.http.delete(`${environment.apiUrl}/v1/tax/user`);
  }

  /**
   * Called every time that a user logs in (paying or not).
   * Will restart automatically any API not disabled, and synchronized 7 days ago.
   *
   * @returns res
   */
  pingUser(): Observable<GenericResponse> {
    return this.http.get<GenericResponse>(
      `${environment.apiUrl}/v1/tax/user/ping`
    );
  }

  /**
   * Called when the user logs out
   *
   * @returns res
   */
  pingOutUser(): Observable<GenericResponse> {
    return this.http.get<GenericResponse>(
      `${environment.apiUrl}/v1/tax/user/ping-out`
    );
  }

  /**
   * Start email transfer process
   *
   * @param recipient
   * @returns transfer request
   */
  startEmailTransfer(recipient: string): Observable<TransferRequest> {
    return this.http.post<TransferRequest>(
      `${environment.apiUrl}/v1/tax/user/transfer?recipient=${recipient}`,
      ``
    );
  }

  /**
   * Get active email transfer
   *
   * @returns transfer request
   */
  getActiveEmailTransfer(): Observable<TransferRequest> {
    return this.http.get<TransferRequest>(
      `${environment.apiUrl}/v1/tax/user/transfer`
    );
  }

  /**
   * Approve email transfer process
   *
   * @param requestId
   * @param code
   * @returns transfer request
   */
  approveEmailTransfer(
    requestId: string,
    code: string
  ): Observable<TransferRequest> {
    return this.http.put<TransferRequest>(
      `${environment.apiUrl}/v1/tax/user/transfer/approve?requestId=${requestId}&code=${code}`,
      ``
    );
  }

  /**
   * Cancel email transfer process
   *
   * @param requestId
   * @returns transfer request
   */
  cancelEmailTransfer(requestId: string): Observable<TransferRequest> {
    return this.http.put<TransferRequest>(
      `${environment.apiUrl}/v1/tax/user/transfer/cancel?requestId=${requestId}`,
      ``
    );
  }

  /**
   * Get auth user event
   *
   * @returns auth user events
   */
  getAuthEvents(page = 0, size = 100, sort = `date,desc`): Observable<Page<AuthUserEvent>> {
    return this.http.get<Page<AuthUserEvent>>(
      `${environment.apiUrl}/v1/tax/auth-user-event?page=${page}&size=${size}&sort=${sort}`
    );
  }

  /**
   * Get user location
   *
   * @returns user location
   */
  getUserLocation(): Observable<GeolocationDetails> {
    return this.http.get<GeolocationDetails>(
      `${environment.apiUrl}/v1/tax/user/location`
    );
  }

  /**
   * Get user balance
   *
   * @returns
   */
  getUserBalance(until: string): Observable<Balance> {
    const date = moment(until).subtract(1, `days`).format(`YYYY-MM-DD`);

    return this.http.get<Balance>(
      `${environment.apiUrl}/v1/tax/balance?until=${date}`
    );
  }

  /**
   * Check if user exists
   *
   * @param email
   * @returns
   */
  checkUserExistence(email: string): Observable<UserExistence> {
    return this.http.get<UserExistence>(
      `${environment.apiUrl}/v1/tax/user/exists`,
      {
        headers: new HttpHeaders({
          waltio_user: email,
        }),
      }
    );
  }

  /**
   * Set user acquisition channel
   *
   * @param channel
   * @returns
   */
  setUserAcquisitionChannel(channel: AcquisitionChannel): Observable<User> {
    const acquisitionChannel = channel || `WEBSITE`;
    return this.http.put<User>(
      `${environment.apiUrl}/v1/tax/user/acquisition-channel/${acquisitionChannel}`,
      {}
    );
  }
}
