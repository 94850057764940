/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { CustomError } from '../../../shared/models/error.model';
import {
  loadUserBalanceAction,
} from '../../../shared/store/actions/shared.action';
import { LossHarvestingDetails } from '../../models/loss-harvesting.model';
import {
  TaxReport,
  TaxReportSummary,
} from '../../models/report.model';
import { ReportService } from '../../services/report.service';
import {
  loadLastReportsAction,
  loadReportSummaryAction,
  loadTaxLossHarvestingAction,
  loadTaxReportAction,
  setLastReportsAction,
  setReportSummaryAction,
  setTaxLossHarvestingAction,
  setTaxReportAction,
} from '../actions/report.action';
import * as fromReport from '../selectors/report.selector';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';

import { generateTaxReportAction } from './../actions/report.action';
import { User } from '../../models/user.model';
import { ToastService } from '../../../shared/services/toast.service';

@Injectable()
export class ReportEffects {
  loadTaxReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTaxReportAction>>(loadTaxReportAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
      switchMap(
        ([action, user]: [ReturnType<typeof loadTaxReportAction>, User]) =>
          this.reportService
            .getTaxReport(action.fiscalYear, action.reportId)
            .pipe(
              switchMap((taxReport: TaxReport) => {
                const actions: any[] = [setTaxReportAction({ taxReport })];

                if (taxReport) {
                  actions.push(
                    loadReportSummaryAction({
                      reportId: taxReport.id,
                    })
                  );
                }

                return actions;
              }),
              catchError((error: CustomError) => {
                const errorMessage: string =
                  this.translateService.instant(error.errorCode) ===
                    error.errorCode
                    ? error.message
                    : this.translateService.instant(error.errorCode);

                this.toastService.error(errorMessage);

                return EMPTY;
              })
            )
      )
    )
  );

  generateTaxReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof generateTaxReportAction>>(
        generateTaxReportAction
      ),
      switchMap((action: ReturnType<typeof generateTaxReportAction>) =>
        this.reportService.startTaxReport(action.fiscalYear).pipe(
          switchMap((taxReport: TaxReport) => {
            const actions = [];

            if (!taxReport.completed) {
              actions.push(
                loadTaxReportAction({ fiscalYear: taxReport.fiscalYear })
              );
            } else {
              actions.push(setTaxReportAction({ taxReport }));
              actions.push(loadUserBalanceAction());
              actions.push(
                loadReportSummaryAction({
                  reportId: taxReport.id,
                })
              );
            }

            return actions;
          }),
          catchError((error: CustomError) => {
            const errorMessage: string =
              this.translateService.instant(error.errorCode) === error.errorCode
                ? error.message
                : this.translateService.instant(error.errorCode);

            this.toastService.error(errorMessage);

            return EMPTY;
          })
        )
      )
    )
  );


  loadLastReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadLastReportsAction>>(loadLastReportsAction),
      switchMap(() =>
        this.reportService.getLastReportsByFiscalYear().pipe(
          map((lastReports: Map<string, TaxReport>) =>
            setLastReportsAction({ lastReports })
          ),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(
              error.errorCode ?? error.message
            );

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  loadTaxLossHarvesting$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTaxLossHarvestingAction>>(
        loadTaxLossHarvestingAction
      ),
      withLatestFrom(
        this.reportStore$.pipe(select(fromReport.selectTaxReport))
      ),
      switchMap(
        ([action, report]: [
          ReturnType<typeof loadTaxLossHarvestingAction>,
          TaxReport
        ]) =>
          this.reportService
            .getTaxLossHarvesting(
              action.portfolioValue,
              action.capitalLoss,
              action.buyingPrice,
              report.id
            )
            .pipe(
              map((lossHarvestingDetails: LossHarvestingDetails) =>
                setTaxLossHarvestingAction({ lossHarvestingDetails })
              ),
              catchError((error: CustomError) => {
                const message = error.errorCode ?? error.message;

                this.toastService.error(message);

                return EMPTY;
              })
            )
      )
    )
  );

  loadReportSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadReportSummaryAction>>(
        loadReportSummaryAction
      ),
      switchMap((action: ReturnType<typeof loadReportSummaryAction>) =>
        this.reportService.getReportSummary(action.reportId).pipe(
          map((reportSummary: TaxReportSummary) =>
            setReportSummaryAction({ reportSummary })
          ),
          catchError((error: CustomError) => {
            const message = error.errorCode ?? error.message;

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly reportStore$: Store<fromReport.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly reportService: ReportService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) { }
}
