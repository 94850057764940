import { Action, createReducer, on } from '@ngrx/store';
import { TaxableCession } from '../../models/taxable-cession.model';
import * as AssessmentActions from '../actions/assessment.action';
import { Assessment } from '../../models/assessment.model';

export interface State {
  assessment: Assessment;
  taxableCessions: TaxableCession[];
}

export const initialState: State = {
  assessment: null,
  taxableCessions: []
};

const reducer = createReducer(
  initialState,
  on(
    AssessmentActions.setAssessmentAction,
    (state: State, { assessment }: any) => ({
      ...state,
      assessment: assessment?.id === state.assessment?.id && state.assessment?.state === `COMPLETED` ? state.assessment : assessment,
    })
  ),
  on(
    AssessmentActions.setTaxableCessionsAction,
    (state: State, { taxableCessions }: any) => ({
      ...state,
      taxableCessions,
    })
  ),
);

export const assessmentReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
