import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ImagePreloadDirective } from '../../../shared/directives/image-preload.directive';
import { Form3916BisPlatformInfos } from '../../../shared/models/platform-adress.model';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { BannerComponent } from '../../components/banner/banner.component';
import { FrenchTaxFormInputComponent } from '../../components/french-tax-form-input/french-tax-form-input.component';
import { FrenchTaxGridCellComponent } from '../../components/french-tax-grid-cell/french-tax-grid-cell.component';
import { FORM_3916_BIS_PLATFORMS } from '../../constants/forms.constant';
import { TAXABLE_CESSIONS_DEMO } from '../../constants/taxable-cessions.constant';
import { Account } from '../../models/account.model';
import { Assessment, AssessmentFile } from '../../models/assessment.model';
import { PaymentEstimateV3 } from '../../models/payment.model';
import { TaxableCession } from '../../models/taxable-cession.model';
import { TransactionFilters } from '../../models/transaction.model';
import { downloadAssessmentFileAction } from '../../store/actions/assessment.action';
import { loadFiltersAction } from '../../store/actions/transaction.action';
import * as fromAssessment from '../../store/selectors/assessment.selector';
import * as fromPayment from '../../store/selectors/payment.selector';
import * as fromTransaction from '../../store/selectors/transaction.selector';

const MAX_CESSIONS_PER_PAGE = 5;

@Component({
  selector: `app-legal-forms-page`,
  templateUrl: `./legal-forms-page.component.html`,
  styleUrls: [`./legal-forms-page.component.scss`],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    TranslateModule,
    MatDividerModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    BannerComponent,
    FrenchTaxFormInputComponent,
    FrenchTaxGridCellComponent,
    ImagePreloadDirective,
    MatTooltipModule
  ],
})
export class LegalFormsComponent implements OnInit, OnDestroy {
  hasPaid = false;
  selectedPlatform = `BINANCE`;
  accounts: Map<string, Account> = new Map<string, Account>([]);
  platforms: Map<string, Form3916BisPlatformInfos> = FORM_3916_BIS_PLATFORMS;
  usedPlatforms: Map<string, Form3916BisPlatformInfos> = new Map<string, Form3916BisPlatformInfos>([]);

  usedPlatformsOptions$: ReplaySubject<Map<string, Form3916BisPlatformInfos>> = new ReplaySubject(1);
  platformsOptions$: ReplaySubject<Map<string, Form3916BisPlatformInfos>> = new ReplaySubject(1);

  platformSearchControl: FormControl<string> = new FormControl<string>(``);
  currentForm = `3916-bis`;
  taxableCessions = [];
  displayedCessions = [];
  cessionsPageIndex = 0;
  maxCessionsPage = 1;

  platformsMap: Map<string, Form3916BisPlatformInfos> = FORM_3916_BIS_PLATFORMS;
  assessment: Assessment;
  planEstimate: PaymentEstimateV3;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly sharedStore: Store<fromShared.State>,
    private readonly transactionStore: Store<fromTransaction.State>,
    private readonly paymentStore: Store<fromPayment.State>,
    private readonly assessmentStore: Store<fromAssessment.State>
  ) { }

  ngOnInit(): void {
    this.transactionStore.dispatch(loadFiltersAction());

    this.paymentStore.pipe(
      takeUntil(this.destroy$),
      select(fromPayment.selectPlanEstimate),
      map((planEstimate: PaymentEstimateV3) => {
        this.planEstimate = planEstimate;

        if (this.planEstimate) {
          this.hasPaid = this.planEstimate.due === 0;
          this.taxableCessions = !this.hasPaid ? TAXABLE_CESSIONS_DEMO : this.taxableCessions;
          this.displayedCessions = this.taxableCessions.slice(0, this.cessionsPageIndex + MAX_CESSIONS_PER_PAGE);
          this.maxCessionsPage = Math.ceil(this.taxableCessions.length / MAX_CESSIONS_PER_PAGE);
        }
      })
    ).subscribe();

    this.assessmentStore.pipe(
      takeUntil(this.destroy$),
      select(fromAssessment.selectTaxableCessions),
      map((taxableCessions: TaxableCession[]) => {
        if (this.taxableCessions && this.hasPaid) {
          this.taxableCessions = taxableCessions;
          this.displayedCessions = this.taxableCessions.slice(0, this.cessionsPageIndex + MAX_CESSIONS_PER_PAGE);
          this.maxCessionsPage = Math.ceil(this.taxableCessions.length / MAX_CESSIONS_PER_PAGE);
        }
      })
    ).subscribe();

    this.sharedStore.pipe(
      takeUntil(this.destroy$),
      select(fromShared.selectCurrentOpenedForm),
      map((currentOpenedForm: string) => {
        this.currentForm = currentOpenedForm;
      })
    ).subscribe();

    this.assessmentStore.pipe(
      takeUntil(this.destroy$),
      pipe((select(fromAssessment.selectAssessment))),
      map((assessment: Assessment) => {
        this.assessment = assessment;

        const fiscalAnnex = this.assessment?.files?.find((file: AssessmentFile) => file.fileType === `FISCAL_ANNEX_AS_JSON`);

        if (fiscalAnnex && this.hasPaid) {
          this.assessmentStore.dispatch(downloadAssessmentFileAction({ assessmentId: this.assessment.id, filename: fiscalAnnex.name }));
        }
      })
    ).subscribe();

    this.sharedStore.pipe(
      takeUntil(this.destroy$),
      select(fromShared.selectAccounts),
      map((accounts: Map<string, Account>) => {
        this.accounts = accounts;
      })
    ).subscribe();

    this.transactionStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromTransaction.selectFilters),
        map((filters: TransactionFilters) => {
          if (filters) {
            const otherPlatforms: Map<string, Form3916BisPlatformInfos> = this.platforms;

            for (const platform of filters.platform) {
              if (FORM_3916_BIS_PLATFORMS.get(platform)) {
                this.usedPlatforms.set(
                  platform,
                  FORM_3916_BIS_PLATFORMS.get(platform)
                );
              }

              otherPlatforms.delete(platform);
            }

            this.usedPlatformsOptions$.next(this.usedPlatforms);
            this.platformsOptions$.next(otherPlatforms);
          } else {
            this.platformsOptions$.next(this.platforms);
          }
        })
      )
      .subscribe();

    this.platformSearchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((search: string) => {
          this.selectSearch(search);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // A bit dirty but it works well
  selectSearch(
    search: string
  ): void {
    if (search) {
      search = search.toLowerCase();

      if (this.usedPlatforms.size !== 0) {
        const filteredUsedPlatforms: Map<string, Form3916BisPlatformInfos> = new Map<
          string,
          Form3916BisPlatformInfos>([]);

        this.usedPlatforms.forEach((value: Form3916BisPlatformInfos, key: string) => {
          const platform = this.accounts.get(key);
          if (platform.name.toLowerCase().startsWith(search)) {
            filteredUsedPlatforms.set(key, value);
          }
        });
        this.usedPlatformsOptions$.next(filteredUsedPlatforms);
      }

      if (this.platforms.size !== 0) {
        const filteredPlatforms: Map<string, Form3916BisPlatformInfos> = new Map<
          string,
          Form3916BisPlatformInfos>([]);



        this.platforms.forEach((value: Form3916BisPlatformInfos, key: string) => {
          const platform = this.accounts.get(key);
          if (platform.name.toLowerCase().startsWith(search)) {
            filteredPlatforms.set(key, value);
          }
        });
        this.platformsOptions$.next(filteredPlatforms);
      }
    } else {
      this.usedPlatformsOptions$.next(this.usedPlatforms);
      this.platformsOptions$.next(this.platforms);
    }
  }

  updateCessionPage(nextPage = true): void {
    if (nextPage) {
      this.cessionsPageIndex += MAX_CESSIONS_PER_PAGE;
    } else {
      this.cessionsPageIndex -= MAX_CESSIONS_PER_PAGE;
    }

    this.displayedCessions = this.taxableCessions.slice(this.cessionsPageIndex, this.cessionsPageIndex + MAX_CESSIONS_PER_PAGE);
  }
}
