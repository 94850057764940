<div
  class="link"
  [ngClass]="{
  'link--light': theme === 'light',
  'link--strong': theme === 'strong',
}"
>
  <p>{{ label }}</p>
  <div class="next-icon"></div>
</div>
