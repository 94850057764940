/* eslint-disable @typescript-eslint/naming-convention */
import { Params } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { createAction, props } from '@ngrx/store';
import { AccountListResponse } from '../../../taxation/models/account.model';
import { Balance } from '../../../taxation/models/balance.model';
import { GeolocationDetails } from '../../../taxation/models/geolocation-details.model';
import { TokenConfidence } from '../../../taxation/models/token-confidence.model';
import { AcquisitionChannel } from '../../../taxation/models/user.model';
import { Configuration } from '../../models/configuration.model';
import { CustomError } from '../../models/error.model';
import { Feature } from '../../models/feature.model';
import { Tag } from '../../models/tag.model';
import { TransferRequest } from '../../models/transfer-request.model';
import { UserPreferences } from '../../models/user-preferences.model';

interface Yolo {
  version: string;
}

// eslint-disable-next-line no-shadow
export enum SharedActions {
  LoadAccounts = `[Shared] Load Accounts`,
  SetAccounts = `[Shared] Set Accounts`,
  LoadCoins = `[Shared] Load Coins`,
  SetCoins = `[Shared] Set Coins`,
  LoadConfiguration = `[Shared] Load Configuration`,
  UpdateConfiguration = `[Shared] Update Configuration`,
  SetConfiguration = `[Shared] Set Configuration`,
  LoadAnalysisCount = `[Shared] Load Analysis Count`,
  SetAnalysisCount = `[Shared] Set Analysis Count`,
  LoadUserPreferences = `[Shared] Load User Preferences`,
  UpdateUserPreferences = `[Shared] Update User Preferences`,
  SetUserPreferences = `[Shared] Set User Preferences`,
  LoadFeatures = `[Shared] Load Features`,
  SetFeatures = `[Shared] Set Features`,
  LoadStablecoins = `[Shared] Load Stablecoins`,
  SetStablecoins = `[Shared] Set Stablecoins`,
  LoadFiats = `[Shared] Load Fiats`,
  SetFiats = `[Shared] Set Fiats`,
  PingUser = `[Shared] Ping User`,
  PingOutUser = `[Shared] Ping Out User`,
  StartEmailTransfer = `[Shared] Start Email Transfer`,
  GetActiveEmailTransfer = `[Shared] Get Active Email Transfer`,
  SetEmailTransferRequest = `[Shared] Set Email Transfer Request`,
  SetHasEmailTransferRequest = `[Shared] Set Has Email Transfer Request`,
  ApproveEmailTransfer = `[Shared] Approve Email Transfer`,
  CancelEmailTransfer = `[Shared] Cancel Email Transfer`,
  LoadShitcoins = `[Shared] Load Shitcoins`,
  SetShitcoins = `[Shared] Set Shitcoins`,
  DeleteShitcoin = `[Shared] Delete Shitcoin`,
  AddShitcoin = `[Shared] Add Shitcoin`,
  GoTo = `[Shared] Go To`,
  ValidateCGVandOnboarding = `[Shared] Validate CGV and Onboarding`,
  CompleteOnboarding = `[Shared] Complete Onboarding`,
  SetOnboardingError = `[Shared] Set Onboarding Error`,
  SetOnboardingCompleted = `[Shared] Set Onboarding Completed`,
  LoadUserLocation = `[Shared] Load User Location`,
  SetUserLocation = `[Shared] Set User Location`,
  LoadFrenchPostCodes = `[Shared] Load French Post Codes`,
  SetFrenchPostCodes = `[Shared] Set French Post Codes`,
  LoadBelgianFiscalProfiles = `[Shared] Load Belgian Fiscal Profiles`,
  SetBelgianFiscalProfiles = `[Shared] Set Belgian Fiscal Profiles`,
  LoadSpanishAutonomousCommunities = `[Shared] Load Spanish Autonomous Communities`,
  SetSpanishAutonomousCommunities = `[Shared] Set Spanish Autonomous Communities`,
  LoadUserBalance = `[Shared] Load User Balance`,
  SetUserBalance = `[Shared] Set User Balance`,
  PushTag = `[Shared] Push Tag`,
  LoadGTM = `[Shared] Load GTM`,
  LoadTokenConfidence = `[Shared] Load Token Confidence`,
  SetTokenConfidence = `[Shared] Set Token Confidence`,
  CheckUserExistence = `[Shared] Check User Existence`,
  SetUserAcquisitionChannel = `[Shared] Set User Acquisition Channel`,
  SetIsPaymentDialogOpened = `[Shared] Set Is Payment Dialog Opened`,
  SetPartner = `[Shared] Set Partner`,
  SetCurrentOpenedForm = `[Shared] Set Current Opened Form`,
}

export const loadAccountsAction = createAction(SharedActions.LoadAccounts);

export const setAccountsAction = createAction(
  SharedActions.SetAccounts,
  props<{ accounts: AccountListResponse }>()
);

export const loadCoinsAction = createAction(SharedActions.LoadCoins);

export const setCoinsAction = createAction(
  SharedActions.SetCoins,
  props<{ coins: Map<string, string> }>()
);

export const loadConfigurationAction = createAction(
  SharedActions.LoadConfiguration
);

export const setConfigurationAction = createAction(
  SharedActions.SetConfiguration,
  props<{ configuration: Configuration }>()
);

export const updateConfigurationAction = createAction(
  SharedActions.UpdateConfiguration,
  props<{ configuration: Configuration }>()
);

export const loadAnalysisCountAction = createAction(
  SharedActions.LoadAnalysisCount
);

export const setAnalysisCountAction = createAction(
  SharedActions.SetAnalysisCount,
  props<{ analysisCount: number }>()
);

export const loadUserPreferencesAction = createAction(
  SharedActions.LoadUserPreferences
);

export const updateUserPreferencesAction = createAction(
  SharedActions.UpdateUserPreferences,
  props<{ userPreferences: UserPreferences, showToast?: boolean }>()
);

export const setUserPreferencesAction = createAction(
  SharedActions.SetUserPreferences,
  props<{ userPreferences: UserPreferences }>()
);

export const loadFeaturesAction = createAction(SharedActions.LoadFeatures);

export const setFeaturesAction = createAction(
  SharedActions.SetFeatures,
  props<{ features: Feature[] }>()
);

export const loadStablecoinsAction = createAction(
  SharedActions.LoadStablecoins
);

export const setStablecoinsAction = createAction(
  SharedActions.SetStablecoins,
  props<{ stablecoins: string[] }>()
);

export const loadFiatsAction = createAction(SharedActions.LoadFiats);

export const setFiatsAction = createAction(
  SharedActions.SetFiats,
  props<{ fiats: string[] }>()
);

export const pingUserAction = createAction(SharedActions.PingUser);

export const pingOutUserAction = createAction(SharedActions.PingOutUser);

export const startEmailTransferAction = createAction(
  SharedActions.StartEmailTransfer,
  props<{ recipient: string }>()
);

export const getActiveEmailTransferAction = createAction(
  SharedActions.GetActiveEmailTransfer
);

export const approveEmailTransferAction = createAction(
  SharedActions.ApproveEmailTransfer
);

export const cancelEmailTransferAction = createAction(
  SharedActions.CancelEmailTransfer,
  props<{ requestId: string }>()
);

export const setEmailTransferRequestAction = createAction(
  SharedActions.SetEmailTransferRequest,
  props<{ emailTransferRequest: TransferRequest }>()
);

export const setHasEmailTransferRequestAction = createAction(
  SharedActions.SetHasEmailTransferRequest,
  props<{ hasEmailTransferError: boolean }>()
);

export const loadShitcoinsAction = createAction(SharedActions.LoadShitcoins);

export const setShitcoinsAction = createAction(
  SharedActions.SetShitcoins,
  props<{ shitcoins: string[] }>()
);

export const deleteShitcoinAction = createAction(
  SharedActions.DeleteShitcoin,
  props<{ shitcoin: string }>()
);

export const addShitcoinAction = createAction(
  SharedActions.AddShitcoin,
  props<{ shitcoin: string }>()
);

export const goToAction = createAction(
  SharedActions.GoTo,
  props<{ url: string; queryParams?: Params; reloadPage?: boolean }>()
);

export const completeOnboardingAction = createAction(
  SharedActions.CompleteOnboarding,
  props<{ affiliateId: string; user: User; userPreferences: UserPreferences }>()
);

export const setOnboardingErrorAction = createAction(
  SharedActions.SetOnboardingError,
  props<{ onboardingError: CustomError }>()
);

export const setOnboardingCompletedAction = createAction(
  SharedActions.SetOnboardingCompleted,
);

export const loadUserLocationAction = createAction(
  SharedActions.LoadUserLocation
);

export const setUserLocationAction = createAction(
  SharedActions.SetUserLocation,
  props<{ userLocation: GeolocationDetails }>()
);

export const loadFrenchPostCodesAction = createAction(
  SharedActions.LoadFrenchPostCodes
);

export const setFrenchPostCodesAction = createAction(
  SharedActions.SetFrenchPostCodes,
  props<{ frenchPostCodes: Map<string, string> }>()
);

export const loadBelgianFiscalProfilesAction = createAction(
  SharedActions.LoadBelgianFiscalProfiles
);

export const setBelgianFiscalProfilesAction = createAction(
  SharedActions.SetBelgianFiscalProfiles,
  props<{ belgianFiscalProfiles: Map<string, string> }>()
);

export const loadSpanishAutonomousCommunitiesAction = createAction(
  SharedActions.LoadSpanishAutonomousCommunities
);

export const setSpanishAutonomousCommunitiesAction = createAction(
  SharedActions.SetSpanishAutonomousCommunities,
  props<{ spanishAutonomousCommunities: Map<string, string> }>()
);

export const loadUserBalanceAction = createAction(
  SharedActions.LoadUserBalance
);

export const setUserBalanceAction = createAction(
  SharedActions.SetUserBalance,
  props<{ userBalance: Balance }>()
);

export const pushTagAction = createAction(
  SharedActions.PushTag,
  props<{ tag: Tag }>()
);

export const loadGTMAction = createAction(SharedActions.LoadGTM);

export const loadTokenConfidenceAction = createAction(
  SharedActions.LoadTokenConfidence,
  props<{ currency: string }>()
);

export const setTokenConfidenceAction = createAction(
  SharedActions.SetTokenConfidence,
  props<{ tokenConfidence: TokenConfidence }>()
);

export const checkUserExistenceAction = createAction(
  SharedActions.CheckUserExistence,
  props<{ email: string }>()
);

export const setUserAcquisitionChannelAction = createAction(
  SharedActions.SetUserAcquisitionChannel,
  props<{ acquisitionChannel?: AcquisitionChannel }>()
);

export const setIsPaymentDialogOpenedAction = createAction(
  SharedActions.SetIsPaymentDialogOpened,
  props<{ isPaymentDialogOpened: boolean }>()
);

export const setPartnerAction = createAction(
  SharedActions.SetPartner,
  props<{ partner: string }>()
);

export const setCurrentOpenedFormAction = createAction(
  SharedActions.SetCurrentOpenedForm,
  props<{ currentOpenedForm: string }>()
);