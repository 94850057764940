import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { Assessment } from '../../../taxation/models/assessment.model';
import { UserPlan } from '../../../taxation/models/user.model';
import { PLANS } from '../../../taxation/constants/plans.constant';

@Component({
  selector: `app-plan-status`,
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatIconModule],
  templateUrl: `./plan-status.component.html`,
  styleUrls: [`./plan-status.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanStatusComponent implements AfterViewInit {
  maximumNbOfTransactions: number;

  @ViewChild(`planProgress`, { static: false }) planProgressElement: ElementRef;

  @Input() set plan(plan: UserPlan) {
    this._plan = plan;
    this.maximumNbOfTransactions = PLANS.get(plan).maximumNbOfTransactions;
    this.updatePlanProgress();
  }

  get plan(): UserPlan {
    return this._plan;
  }

  @Input() set assessment(assessment: Assessment) {
    this._assessment = assessment;
    this.updatePlanProgress();
  }

  get assessment(): Assessment {
    return this._assessment;
  }

  private _assessment: Assessment;
  private _plan: UserPlan;

  ngAfterViewInit(): void {
    this.updatePlanProgress();
  }

  updatePlanProgress(): void {
    const transactionsCount = this.assessment?.nbOfTransactionsOnFiscalYear || this.assessment?.nbOfTransactionsOnCurrentUserYear;
    const planProgress = (transactionsCount / this.maximumNbOfTransactions) * 100;

    if (this.planProgressElement) {
      this.planProgressElement.nativeElement.style.width = `${planProgress}%`;
    }
  }
}
