import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { Page } from '../../../shared/models/page.model';
import { ToastService } from '../../../shared/services/toast.service';
import { DocumentRequest } from '../../models/document.model';
import { DocumentService } from '../../services/document.service';
import {
  createDocumentRequestAction,
  deleteDocumentRequestAction,
  downloadDocumentRequestAction,
  downloadStockSheetAction,
  downloadWaltioExportFileAction,
  getDocumentRequestAction,
  loadDocumentRequestAction,
  setDocumentRequestPageAction,
} from '../actions/document.action';
import { pushTagAction } from '../../../shared/store/actions/shared.action';

@Injectable()
export class DocumentEffects {
  loadDocumentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadDocumentRequestAction>>(
        loadDocumentRequestAction
      ),
      switchMap((action: ReturnType<typeof loadDocumentRequestAction>) =>
        this.documentService.getDocumentRequests(action.page, action.size, action.sort)
      ),
      map((documentRequestPage: Page<DocumentRequest>) =>
        setDocumentRequestPageAction({ documentRequestPage })
      )
    )
  );



  createDocumentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof createDocumentRequestAction>>(
        createDocumentRequestAction
      ),
      switchMap((action: ReturnType<typeof createDocumentRequestAction>) =>
        this.documentService
          .createDocumentRequest(action.documentRequestType)
          .pipe(
            map(() => {
              return loadDocumentRequestAction({ sort: `created,desc` });
            })
          )
      )
    )
  );

  getDocumentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getDocumentRequestAction>>(
        getDocumentRequestAction
      ),
      switchMap((action: ReturnType<typeof getDocumentRequestAction>) =>
        this.documentService
          .getDocumentRequestById(action.documentRequestId)
          .pipe(
            map((documentRequest: DocumentRequest) => {
              if (!documentRequest.completed) {
                return getDocumentRequestAction({
                  documentRequestId: action.documentRequestId,
                });
              } else {
                return downloadDocumentRequestAction({ documentRequest });
              }
            })
          )
      )
    )
  );

  downloadStockSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof downloadStockSheetAction>>(
        downloadStockSheetAction
      ),
      switchMap((action: ReturnType<typeof downloadStockSheetAction>) =>
        this.documentService
          .createDocumentRequest(`STOCK_EXPORT_FILE`)
          .pipe(
            map((documentRequest: DocumentRequest) => {

              return getDocumentRequestAction({
                documentRequestId: documentRequest.id,
              });
            })
          )
      )
    )
  );

  downloadWaltioExportFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof downloadWaltioExportFileAction>>(
        downloadWaltioExportFileAction
      ),
      switchMap((action: ReturnType<typeof downloadWaltioExportFileAction>) =>
        this.documentService
          .createDocumentRequest(`WALTIO_EXPORT_FILE`)
          .pipe(
            switchMap((documentRequest: DocumentRequest) => {
              return [
                pushTagAction({ tag: { event: `export_transactions` } }),
                getDocumentRequestAction({
                  documentRequestId: documentRequest.id,
                })];
            })
          )
      )
    )
  );

  downloadFile: any = null;

  downloadDocumentRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof downloadDocumentRequestAction>>(
          downloadDocumentRequestAction
        ),
        switchMap((action: ReturnType<typeof downloadDocumentRequestAction>) =>
          this.documentService
            .downloadDocumentRequest(action.documentRequest, action.format)
            .pipe(
              tap((data: Blob) => {
                // Doing it this way allows you to name the file
                const link = document.createElement(`a`);

                link.href = window.URL.createObjectURL(
                  new Blob([data], {
                    type: data.type,
                  })
                );

                link.download =
                  action.documentRequest.generatedDocument.name.split(`.`)[0];

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              })
            )
        )
      ),
    { dispatch: false }
  );

  deleteDocumentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteDocumentRequestAction>>(
        deleteDocumentRequestAction
      ),
      switchMap((action: ReturnType<typeof deleteDocumentRequestAction>) =>
        this.documentService
          .deleteDocumentRequest(action.documentRequestId)
          .pipe(
            switchMap((res: GenericResponse) => {
              if (res.success) {
                this.toastService.success(this.translateService.instant(`ExportDeleted`));
                return [loadDocumentRequestAction({ sort: `completed,desc` })];
              } else {
                this.toastService.error(this.translateService.instant(`ErrorOccurred`));
                return EMPTY;
              }
            }),
            catchError((error: Error) => {
              this.toastService.error(error.message);

              return EMPTY;
            })
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly documentService: DocumentService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) { }
}
