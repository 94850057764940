import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Analysis } from '../models/analysis.model';

@Injectable({
  providedIn: `root`,
})
export class AnalysisService {
  constructor(private readonly http: HttpClient) { }

  /**
   * Get analysis
   *
   * @param analysisId
   * @returns analysis
   */
  getAnalysis(analysisId?: string): Observable<Analysis> {
    let url = `${environment.apiUrl}/v1/tax/transaction/analysis`;

    if (analysisId) {
      url = url.concat(`?analysisId=${analysisId}`);
    }

    return this.http.get<Analysis>(url);
  }

  /**
   * Start analysis process
   */
  startAnalysis(): Observable<Analysis> {
    return this.http.post<Analysis>(
      `${environment.apiUrl}/v1/tax/transaction/analysis`,
      ``
    );
  }

  /**
   * Get analysis count
   *
   * @returns
   */
  getAnalysisCount(): Observable<number> {
    return this.http.get<number>(
      `${environment.apiUrl}/v1/tax/transaction/analysis/v2/count`
    );
  }

  /**
   * Get last analysis
   *
   * @returns analysis
   */
  getLastAnalysis(): Observable<Analysis> {
    return this.http.get<Analysis>(
      `${environment.apiUrl}/v1/tax/transaction/analysis/v2`
    );
  }
}
