import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResponseSuccess } from '../../shared/models/generic-response.model';
import { Page } from '../../shared/models/page.model';
import { TokenAndPlatformBalanceDetail } from '../models/balance.model';
import { CurrencyOccurences } from '../models/currency.model';
import {
  CostBasis,
  Cump,
  FileId,
  Price,
  Transaction,
  TransactionFilters,
  TransactionUpdateDescriptionDto,
  TransactionUpdateDto,
  TransactionWarningAggregation,
} from '../models/transaction.model';
import { SearchDTO } from '../models/transactions-filters.model';
import { WarningOccurences } from '../models/warning.model';
import { UpdateResult } from './../models/bulk.model';
import { TransactionStatsAggregation } from '../models/transaction-stats-aggregation.model';

@Injectable({
  providedIn: `root`,
})
export class TransactionService {
  constructor(private readonly http: HttpClient) { }

  /**
   * Get supported coins with names
   */
  getCoinsWithNames(): Observable<Map<string, string>> {
    return this.http
      .get<Record<string, string>>(
        `${environment.apiUrl}/v1/tax/transaction/coins-with-names`
      )
      .pipe(
        map(
          (coinsWithNames: Record<string, string>) =>
            new Map<string, string>(Object.entries(coinsWithNames))
        )
      );
  }

  /**
   * Search transaction
   *
   * @param searchDTO
   * @param params
   */
  getTransactions(
    searchDTO: SearchDTO,
    page = 0,
    sort = `transactionDate,desc`,
    size = 100
  ): Observable<Page<Transaction>> {
    searchDTO.startDate = searchDTO.startDate
      ? moment(searchDTO.startDate).format(`YYYY-MM-DD HH:mm:ss`)
      : null;
    searchDTO.endDate = searchDTO.endDate
      ? moment(searchDTO.endDate).format(`YYYY-MM-DD HH:mm:ss`)
      : null;

    return this.http
      .post<Page<Transaction>>(
        `${environment.apiUrl}/v1/tax/transaction/v2/query?page=${page}&size=${size}&sort=${sort}`,
        searchDTO
      )
      .pipe(
        map((transactionsPage: Page<Transaction>) => {
          transactionsPage.content.forEach((transaction: Transaction) => {
            if (transaction.prices) {
              transaction.prices = new Map<string, Price>(
                Object.entries(transaction.prices)
              );
            }

            if (transaction.cump) {
              transaction.cump = new Map<string, Cump>(
                Object.entries(transaction.cump)
              );
            }

            if (transaction.tax?.costBasis) {
              transaction.tax.costBasis = new Map<string, CostBasis>(
                Object.entries(transaction.tax.costBasis)
              );
            }
          });

          return transactionsPage;
        })
      );
  }

  getTransactionById(transactionId: string): Observable<Transaction> {
    return this.http
      .get<Transaction>(`${environment.apiUrl}/v1/tax/transaction/${transactionId}`)
      .pipe(
        map((transaction: Transaction) => {
          if (transaction.prices) {
            transaction.prices = new Map<string, Price>(
              Object.entries(transaction.prices)
            );
          }

          if (transaction.cump) {
            transaction.cump = new Map<string, Cump>(
              Object.entries(transaction.cump)
            );
          }

          if (transaction.tax?.costBasis) {
            transaction.tax.costBasis = new Map<string, CostBasis>(
              Object.entries(transaction.tax.costBasis)
            );
          }

          return transaction;
        })
      );
  }

  /**
   * Get transactions filters
   */
  getFilters(): Observable<TransactionFilters> {
    return this.http
      .get<TransactionFilters>(
        `${environment.apiUrl}/v1/tax/transaction/filters`
      )
      .pipe(
        map((filters: any) => {
          const transactionFilters: TransactionFilters = filters.results[0];

          transactionFilters.fromCurrency =
            transactionFilters.fromCurrency.filter((token: string) => token);

          transactionFilters.toCurrency = transactionFilters.toCurrency.filter(
            (token: string) => token
          );

          transactionFilters.fileId = new Map<string, Map<string, FileId>>(
            Object.entries(transactionFilters.fileId)
          );

          transactionFilters.fileId.forEach(
            (fileId: Map<string, FileId>, platform: string) => {
              transactionFilters.fileId.set(
                platform,
                new Map<string, FileId>(Object.entries(fileId))
              );
            }
          );

          return transactionFilters;
        })
      );
  }

  /**
   * Delete transactions
   *
   * @param id
   */
  deleteTransactions(transactionsIds: string[]): Observable<string[]> {
    // HttpClient delete method do not support body anymore
    return this.http.request<string[]>(
      `delete`,
      `${environment.apiUrl}/v1/tax/transaction`,
      { body: transactionsIds }
    );
  }

  /**
   * Create transaction
   *
   * @param type
   */
  createTransaction(type: string): Observable<Transaction> {
    return this.http
      .post<Transaction>(`${environment.apiUrl}/v1/tax/transaction/${type}`, ``)
      .pipe(
        map((transaction: Transaction) => {
          if (transaction.prices) {
            transaction.prices = new Map<string, Price>(
              Object.entries(transaction.prices)
            );
          }

          return transaction;
        })
      );
  }

  /**
   * Get transactions type by platform
   */
  getTransactionsTypeByPlatform(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/v1/tax/transaction/grouped/platform`
    );
  }

  /**
   * Get tokens name with no price coins
   */
  getTokensWithNoPrice(): Observable<CurrencyOccurences[]> {
    return this.http.get<CurrencyOccurences[]>(
      `${environment.apiUrl}/v1/tax/transaction/tokenWithNoPrice`
    );
  }

  /**
   * Update override for listed transaction empty prices
   *
   * @param currency
   * @param value
   */
  updateCurrencyPrice(
    currency: string,
    value: number
  ): Observable<UpdateResult> {
    return this.http.put<any>(
      `${environment.apiUrl}/v1/tax/transaction/update/bulk/price`,
      {
        currency,
        value,
      }
    );
  }

  /**
   * Get unmatched transactions of Withdraw type without subType
   */
  getUnmatchedTransactionsWithoutLabel(page = 0, size = 100, sort = `type,desc`): Observable<Page<Transaction>> {
    return this.http.get<Page<Transaction>>(
      `${environment.apiUrl}/v1/tax/transaction/to-consolidate/by-page?size=${size}&page=${page}&sort=${sort}`
    ).pipe(
      map((transactionsPage: Page<Transaction>) => {
        transactionsPage.content.forEach((transaction: Transaction) => {
          if (transaction.prices) {
            transaction.prices = new Map<string, Price>(
              Object.entries(transaction.prices)
            );
          }

          if (transaction.cump) {
            transaction.cump = new Map<string, Cump>(
              Object.entries(transaction.cump)
            );
          }

          if (transaction.tax?.costBasis) {
            transaction.tax.costBasis = new Map<string, CostBasis>(
              Object.entries(transaction.tax.costBasis)
            );
          }
        });

        return transactionsPage;
      })
    );
  }

  /**
   * Update subType for listed transaction ids
   *
   * @param transactions
   */
  updateTransactionsSubType(
    transactionUpdateDTO: TransactionUpdateDto
  ): Observable<UpdateResult> {
    return this.http.put<UpdateResult>(
      `${environment.apiUrl}/v1/tax/transaction/update/bulk/subType`,
      transactionUpdateDTO
    );
  }

  /**
   * Match transaction with another one
   *
   * @param transaction
   * @param match
   */
  matchTransactions(transactions: Transaction[]): Observable<ResponseSuccess> {
    return this.http.put<ResponseSuccess>(
      `${environment.apiUrl}/v1/tax/transaction/${transactions[0].id}/matchWith/${transactions[1].id}`,
      ``
    );
  }

  /**
   * Match transaction with another one
   *
   * @param transaction
   * @param match
   */
  unmatchTransaction(transaction: Transaction): Observable<ResponseSuccess> {
    return this.http.put<ResponseSuccess>(
      `${environment.apiUrl}/v1/tax/transaction/${transaction.id}/unmatch`,
      ``
    );
  }

  /**
   * Update description for listed transaction ids
   *
   * @param transactions
   */
  updateTransactionsDescription(
    transactionUpdateDescriptionDto: TransactionUpdateDescriptionDto
  ): Observable<UpdateResult> {
    return this.http.put<UpdateResult>(
      `${environment.apiUrl}/v1/tax/transaction/update/bulk/description`,
      transactionUpdateDescriptionDto
    );
  }

  /**
   * Save updated transaction or create a new one
   *
   * @param transaction new transaction
   * @returns updated transaction
   */
  saveTransaction(transaction: Transaction): Observable<Transaction> {
    return this.http
      .post<Transaction>(`${environment.apiUrl}/v1/tax/transaction/save`, {
        ...transaction,
        prices: transaction.prices
          ? Object.fromEntries(transaction.prices)
          : transaction.prices,
      })
      .pipe(
        map((updatedTransaction: Transaction) => {
          if (updatedTransaction.prices) {
            updatedTransaction.prices = new Map<string, Price>(
              Object.entries(updatedTransaction.prices)
            );
          }

          return updatedTransaction;
        })
      );
  }

  /**
   *
   *  Save updated transactions or create new ones
   *
   * @param transactions
   * @returns updated transactions
   */
  saveTransactions(transactions: Transaction[]): Observable<Transaction[]> {
    return this.http.post<Transaction[]>(
      `${environment.apiUrl}/v1/tax/transaction/save/bulk`,
      transactions
    );
  }

  /**
   * Get insufficient balances by coin by platform
   *
   * @returns
   */
  getNegativeBalances(): Observable<TokenAndPlatformBalanceDetail[]> {
    return this.http.get<TokenAndPlatformBalanceDetail[]>(
      `${environment.apiUrl}/v1/tax/transaction/warning/insufficient-balance/byCoinAndPlatform`
    ).pipe(
      map((tokenAndPlatformBalanceDetails: TokenAndPlatformBalanceDetail[]) => {
        tokenAndPlatformBalanceDetails.forEach((balance: TokenAndPlatformBalanceDetail) => {
          balance.earliestTransaction = {
            ...balance.earliestTransaction,
            prices: new Map<string, Price>(Object.entries(balance.earliestTransaction.prices)),
            cump: new Map<string, Cump>(Object.entries(balance.earliestTransaction.cump)),
            tax: {
              ...balance.earliestTransaction.tax,
              costBasis: balance.earliestTransaction.tax.costBasis ? new Map<string, CostBasis>(Object.entries(balance.earliestTransaction.tax.costBasis)) : null
            }
          };

          balance.previousTransactions.forEach((transaction: Transaction) => {
            transaction.prices = new Map<string, Price>(Object.entries(transaction.prices));
            transaction.cump = new Map<string, Cump>(Object.entries(transaction.cump));
            transaction.tax = {
              ...transaction.tax,
              costBasis: transaction.tax.costBasis ? new Map<string, CostBasis>(Object.entries(transaction.tax.costBasis)) : null
            };
          });

          balance.followingTransactions.forEach((transaction: Transaction) => {
            transaction.prices = new Map<string, Price>(Object.entries(transaction.prices));
            transaction.cump = new Map<string, Cump>(Object.entries(transaction.cump));
            transaction.tax = {
              ...transaction.tax,
              costBasis: transaction.tax.costBasis ? new Map<string, CostBasis>(Object.entries(transaction.tax.costBasis)) : null
            };
          });
        });

        return tokenAndPlatformBalanceDetails;
      })
    );
  }

  /**
   * Create compensating transaction for given balance
   *
   * @param balance
   * @returns compensating transaction
   */
  createCompensatingTransaction(
    balance: TokenAndPlatformBalanceDetail,
    reason: string
  ): Observable<Transaction> {
    balance.earliestDate = encodeURIComponent(balance.earliestDate);
    return this.http.post<Transaction>(
      // eslint-disable-next-line max-len
      `${environment.apiUrl}/v1/tax/transaction/compensating?platform=${balance.platform}&token=${balance.token}&amount=${balance.lowestQuantity}&date=${balance.earliestDate}&reason=${reason}`,
      ``
    );
  }

  /**
   * Get transactions warnings by type
   *
   * @returns
   */
  getTransactionsWarningsByType(): Observable<WarningOccurences[]> {
    return this.http.get<WarningOccurences[]>(
      `${environment.apiUrl}/v1/tax/transaction/warning/byType`
    );
  }

  /**
   * Get aggregated warnings
   *
   * @returns
   */
  getAggregatedWarnings(showNoneCriticalBalances: boolean): Observable<TransactionWarningAggregation[]> {
    const criticality: string = showNoneCriticalBalances ? `` : `HIGH`;
    return this.http.post<TransactionWarningAggregation[]>(
      `${environment.apiUrl}/v1/tax/transaction/v2/warnings?criticality=${criticality}`, ``
    );
  }

  /**
   * Ge transactions stats
   * 
   * @returns 
   */
  getTransactionsStats(): Observable<TransactionStatsAggregation> {
    return this.http.get<TransactionStatsAggregation>(
      `${environment.apiUrl}/v1/tax/transaction/stats`
    );
  }
}
