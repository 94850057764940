import { Form3916BisPlatformInfos } from '../../shared/models/platform-adress.model';

export const FORM_3916_BIS_PLATFORMS: Map<string, Form3916BisPlatformInfos> = new Map([
  [
    `ASCENDEX`,
    {
      designation: `AscendEx`,
      country: `Singapour`,
      additionalAddress: `2 CT Hub 2`,
      street: `114 Lavender Street`,
      zipCode: `09 88`,
      url: `https://ascendex.com/en/global-digital-asset-platform`,
    },
  ],
  [
    `BINANCE`,
    {
      designation: `Binance Europe Services Limited`,
      country: `Malte`,
      additionalAddress: ``,
      street: `75 ABATE RIGORD STREET`,
      zipCode: `TA XBIEXXBX 1120`,
      url: `https://binance.com`,
    },
  ],
  [
    `BITFINEX`,
    {
      designation: `iFinex Inc`,
      country: `Territoire du RU aux Antilles`,
      additionalAddress: `Third Floor,`,
      street: `Road Town, VG1110,`,
      zipCode: ``,
      url: `https://www.bitfinex.com`,
    },
  ],
  [
    `BITMEX`,
    {
      designation: `HDR Global Trading Limited`,
      country: `SEYCHELLES`,
      additionalAddress: `Global Gateway`,
      street: `8 rue de la Perle`,
      zipCode: `Providence`,
      url: `https://www.bitmex.com`,
    },
  ],
  [
    `BITMART`,
    {
      designation: `Bitmart`,
      country: `TERRITOIRE DU RU Cayman Islands`,
      additionalAddress: `23 Lime Tree Bay Avenue`,
      street: ``,
      zipCode: `KY1 1110 KY`,
      url: `https://www.bitmart.com`,
    },
  ],
  [
    `BITPANDA`,
    {
      designation: `Bitpanda GmbH`,
      country: `Autriche`,
      additionalAddress: `Stella-Klein-Löw Weg 17`,
      street: ``,
      zipCode: `1020 Vienna`,
      url: `https://www.bitpanda.com`,
    },
  ],
  [
    `BITSTAMP`,
    {
      designation: `Bitstamp Limited`,
      country: `Royaume Uni`,
      additionalAddress: `5 New Street Square,`,
      street: ``,
      zipCode: `Londres EC4A`,
      url: `https://www.bitstamp.net`,
    },
  ],
  [
    `BITTREX`,
    {
      designation: `Bittrex International GmbH`,
      country: `Liechtenstein`,
      additionalAddress: ``,
      street: `Dr Grass Strasse 12`,
      zipCode: `9490 Vaduz`,
      url: `https://global.bittrex.com`,
    },
  ],
  [
    `BITVAVO`,
    {
      designation: `Bitvavo B.V.`,
      country: `The Netherlands`,
      additionalAddress: ``,
      street: `Keizersgracht 281 Amsterdam North Holland`,
      zipCode: `1016 ED NL`,
      url: `https://bitvavo.com/`,
    },
  ],
  [
    `BLOCKFI`,
    {
      designation: `BlockFi Bitcoin Trust`,
      country: `Etats Unis`,
      additionalAddress: `SUITE 263`,
      street: `201 MONTGOMERY STREET`,
      zipCode: `New Jersey 07302`,
      url: `https://blockfi.com`,
    },
  ],
  [
    `BYBIT`,
    {
      designation: `Bybit Fintech Limited`,
      country: `Territoire du RU aux Antilles`,
      additionalAddress: `CO Campbells Floor 2 Romasco Place`,
      street: `Road Town Tortola`,
      zipCode: ``,
      url: `https://www.bybit.com`,
    },
  ],
  [
    `CELSIUS`,
    {
      designation: `Celsius Network Lending`,
      country: `EtatsUnis`,
      additionalAddress: `9TH FL`,
      street: `221 RIVER ST`,
      zipCode: `HOBOKEN NJ 07030`,
      url: `https://celsius.network.com`,
    },
  ],
  [
    `COINBASE_PRO`,
    {
      designation: `Coinbase Inc.`,
      country: `Irlande`,
      additionalAddress: ``,
      street: `70 Sir John Rogersons Quay`,
      zipCode: `D02 R296 Dublin`,
      url: `https://pro.coinbase.com`,
    },
  ],
  [
    `COINBASE`,
    {
      designation: `Coinbase, Inc.`,
      country: `Irlande`,
      additionalAddress: ``,
      street: `70 Sir John Rogersons Quay`,
      zipCode: `D02 R296 Dublin`,
      url: `https://www.coinbase.com`,
    },
  ],
  [
    `COINBASE_PRIME`,
    {
      designation: `Coinbase, Inc.`,
      country: `EtatsUnis`,
      additionalAddress: `Suite 1250`,
      street: `100 Pine Street`,
      zipCode: `San Francisco CA 94111`,
      url: `https://primebroker.coinbase.com`,
    },
  ],
  [
    `COINBENE`,
    {
      designation: `CoinBene`,
      country: `SINGAPOUR`,
      additionalAddress: ``,
      street: `1 Coleman Street  Adelphi`,
      zipCode: `Adelphi 179803`,
      url: `https://www.coinbene.com`,
    },
  ],
  [
    `COINLIST`,
    {
      designation: `Coinlist`,
      country: `USA`,
      additionalAddress: ``,
      street: `850 Montgomery St`,
      zipCode: `350 San Francisco`,
      url: `https://coinlist.co`,
    },
  ],
  [
    `COINLIST_PRO`,
    {
      designation: `Coinlist Pro`,
      country: `USA`,
      additionalAddress: ``,
      street: `850 Montgomery St`,
      zipCode: `350 San Francisco`,
      url: `https://pro.coinlist.co`,
    },
  ],
  [
    `COSS`,
    {
      designation: `COSS Asset Management Limited`,
      country: `SINGAPOUR`,
      additionalAddress: `1 Clementi Loop, 06-01 Singapore`,
      street: ``,
      zipCode: `Singapore 129808 SGa`,
      url: `https://www.coss.io`,
    },
  ],
  [
    `CREX24`,
    {
      designation: `Crex24`,
      country: `SEYCHELLES`,
      additionalAddress: `Level 7 Spinola Park Triq Mikiel Ang Borg`,
      street: ``,
      zipCode: `St Julians SPK 1000`,
      url: `https://crypto.com`,
    },
  ],
  [
    `CRYPTO_COM`,
    {
      designation: `Cryptocom`,
      country: `Malte`,
      additionalAddress: `Level 7 Spinola Park Triq Mikiel Ang Borg`,
      street: ``,
      zipCode: `St Julians SPK 1000`,
      url: `https://crypto.com`,
    },
  ],
  [
    `ETORO`,
    {
      designation: `eToro Europe Ltd`,
      country: `Chypre`,
      additionalAddress: `Kanika Businss Center`,
      street: `4 Profiti Ilia Germasogeia`,
      zipCode: `4046 Cyprus`,
      url: `https://www.etoro.com`,
    },
  ],
  [
    `FTX`,
    {
      designation: `FTX Trading LTD`,
      country: `antigua et barbuda`,
      additionalAddress: `Lower Factory Road`,
      street: ` ST Johns`,
      zipCode: ``,
      url: `https://ftx.com`,
    },
  ],
  [
    `GATE_IO`,
    {
      designation: `Gate.io`,
      country: `TERRITOIRE DU RU`,
      additionalAddress: `PO Box 31119`,
      street: `Grand Pavilion Hibiscus Way 802 West Bay Road Grand Cayman`,
      zipCode: `KY1 1205`,
      url: `https://www.gate.io/page/contacts`,
    },
  ],
  [
    `HITBTC`,
    {
      designation: `HitBTC`,
      country: `Chine`,
      additionalAddress: `Unit 19 7F One Midtown No11 Hoi Shing Road`,
      street: ``,
      zipCode: `Tsuen Wan New Territories Hong Kong`,
      url: `https://hitbtc.com`,
    },
  ],
  [
    `HOTBIT`,
    {
      designation: `HotBit`,
      country: `Chine`,
      additionalAddress: `200120`,
      street: `Central Ave`,
      zipCode: ``,
      url: `https://www.hotbit.io/`,
    },
  ],
  [
    `HUOBI`,
    {
      designation: `Huobi Global Ltd`,
      country: `Singapour`,
      additionalAddress: `Asia Square Tower 1`,
      street: `8 Marina View, Central Business District`,
      zipCode: `018961, Singapour`,
      url: `https://www.huobi.com`,
    },
  ],
  [
    `KRAKEN`,
    {
      designation: `Kraken PAYWARD LTD`,
      country: `Royaume Uni`,
      additionalAddress: `6th Floor One London Wall`,
      street: ``,
      zipCode: `London EC2Y 5EB`,
      url: `https://www.kraken.com`,
    },
  ],
  [
    `KUCOIN`,
    {
      designation: `Kucoin`,
      country: `SEYCHELLES`,
      additionalAddress: `Suite 23 Floor 1 Eden Plaza Eden Island Mahé`,
      street: ``,
      zipCode: ``,
      url: `https://www.kucoin.com`,
    },
  ],
  [
    `LIQUID`,
    {
      designation: `Liquid`,
      country: `Singapour`,
      additionalAddress: `80RR 08 01`,
      street: `80 Robinson Road`,
      zipCode: `068898`,
      url: `https://www.liquid.com`,
    },
  ],
  [
    `NEXO`,
    {
      designation: `Nexo Services OÜ`,
      country: `Estonie`,
      additionalAddress: ``,
      street: `Tartu Linn Tartu Maakond`,
      zipCode: `50409`,
      url: `https://nexo.io`,
    },
  ],
  [
    `PAYEER`,
    {
      designation: `PAYCORP LIMITED`,
      country: `Vanuatu`,
      additionalAddress: `T19, 1st Floor Tana Russet Plaza Kumul Highway Port Vila Efate`,
      street: ``,
      zipCode: ``,
      url: `https://payeer.com`,
    },
  ],
  [
    `POLONIEX`,
    {
      designation: `Polo Digital Assets Ltd`,
      country: `SEYCHELLES`,
      additionalAddress: `F20 1st Floor Eden Plaza`,
      street: ``,
      zipCode: ``,
      url: `https://poloniex.com`,
    },
  ],
  [
    `REVOLUT`,
    {
      designation: `Revolut Bank UAB`,
      country: `Lituanie`,
      additionalAddress: `Konstitucijos ave 21B`,
      street: ``,
      zipCode: `08130 Vilnius`,
      url: `https://www.revolut.com`,
    },
  ],
  [
    `SWISSBORG`,
    {
      designation: `SBorg SA SwissBorg Solutions`,
      country: `Suisse`,
      additionalAddress: ``,
      street: `8 rue du GrandChêne`,
      zipCode: `1003 Lausanne`,
      url: `https://swissborg.com`,
    },
  ],
  [
    `UPHOLD`,
    {
      designation: `Uphold Europe Limited`,
      country: `Etats-Unis`,
      additionalAddress: `Uphold Europe Limited Suite A `,
      street: `6 W 18th Street 3rd Floor`,
      zipCode: ` New York 10011 US`,
      url: `https://uphold.com`,
    },
  ],
  [
    `VIRWOX`,
    {
      designation: `Société fermée`,
      country: `Société fermée`,
      additionalAddress: `Société fermée`,
      street: `Société fermée`,
      zipCode: `Société fermée`,
      url: `https://www.virwox.com/?language=fr`,
    },
  ],
  [
    `WIREX`,
    {
      designation: `Wirex Limited`,
      country: `Royaume-Uni`,
      additionalAddress: ``,
      street: `34 37 Liverpool St`,
      zipCode: `London EC2M7PP`,
      url: `https://wirexapp.com`,
    },
  ],
  [
    `YIELD`,
    {
      designation: `Yield.app`,
      country: `Estonie`,
      additionalAddress: `Parnu Mnt 139c 2`,
      street: `Kesklinna Linnaosa Tallinn Harju Maakond`,
      zipCode: `11317`,
      url: `https://www.yield.app/`,
    },
  ],
  [
    `YOUHODLER`,
    {
      designation: `YouHodler`,
      country: `Chypre`,
      additionalAddress: `Arch Makariou III 172`,
      street: `Melford Tower 3027 Limassol`,
      zipCode: `3027`,
      url: `https://fr.youhodler.com/`,
    },
  ],
  [
    `COINMERCE`,
    {
      designation: `Coinmerce BV.`,
      country: `The Netherlands`,
      additionalAddress: ``,
      street: `Beechavenue 122`,
      zipCode: `1119 PR Schiphol Rijk`,
      url: `https://coinmerce.io/`,
    },
  ],
  [
    `COINMETRO`,
    {
      designation: `Coinmetro OU`,
      country: `ESTONIE`,
      additionalAddress: `Tartu mnt 84a402`,
      street: ``,
      zipCode: `Tallinn 10112`,
      url: `https://coinmetro.com/`,
    },
  ],
  [
    `PROBIT`,
    {
      designation: `ProBit`,
      country: `Seychelles`,
      additionalAddress: `Suite 9 Ansuya Estate`,
      street: `Revolution Avenue`,
      zipCode: `Victoria Mahe`,
      url: `https://www.probit.com/fr-fr/`,
    },
  ],
  [
    `COINZOOM`,
    {
      designation: `CoinZoom`,
      country: `United States`,
      additionalAddress: `Suite 206`,
      street: `4640 S Holladay Village Plaza,`,
      zipCode: `Salt Lake City UT 84117`,
      url: `https://www.coinzoom.com/`,
    },
  ],
  [
    `CRYPTO_COM_APP`,
    {
      designation: `Cryptocom app`,
      country: `MALTA`,
      additionalAddress: `Level 7 Spinola Park Triq Mikiel Ang Borg`,
      street: ``,
      zipCode: `St Julians SPK 1000`,
      url: `https://crypto.com`,
    },
  ],
  [
    `OKEX`,
    {
      designation: `OKX`,
      country: `Malaisie`,
      additionalAddress: `Unit 10-02, Level 10 Menara Binjai No2 Jalan Binjai`,
      street: ``,
      zipCode: `50450 Kuala Lumpur`,
      url: `https://www.okx.com/fr`,
    },
  ],
  [
    `CAKEDEFI`,
    {
      designation: `Cake DeFi`,
      country: `Singapour`,
      additionalAddress: ``,
      street: `71 Robinson Rd, #15-106`,
      zipCode: `St Julians SPK 1000`,
      url: `https://cakedefi.com/`,
    },
  ],
  [
    `CEX_IO`,
    {
      designation: `CEX.IO`,
      country: `Royaume-Uni`,
      additionalAddress: ``,
      street: `33 St. James's Square, London`,
      zipCode: `England, SW1Y 4JS`,
      url: `https://cex.io/`,
    },
  ],
  [
    `WALLET_BLOCKCHAIN_COM`,
    {
      designation: `WALLET_BLOCKCHAIN_COM`,
      country: `Royaume-Uni`,
      additionalAddress: ``,
      street: `86-90 Paul Street London`,
      zipCode: `EC2A 4NE GB`,
      url: `https://www.blockchain.com/`,
    },
  ],
  [
    `CRYPTOPAY`,
    {
      designation: `CPS Solutions OÜ`,
      country: `Estonie`,
      additionalAddress: ``,
      street: `Harju maakond Tallinn Haabersti linnaos  Meistri`,
      zipCode: `tn 16 13517`,
      url: `https://cryptopay.me/fr-FR`,
    },
  ],
  [
    `FAIRMINT`,
    {
      designation: `FAIRMINT`,
      country: `Etats-Unis`,
      additionalAddress: ``,
      street: `840 Battery St San Francisco`,
      zipCode: ` California 94111 US`,
      url: `https://www.fairmint.com/`,
    },
  ],
  [
    `LIVECOIN`,
    {
      designation: `LIVECOIN`,
      country: `États Unis`,
      additionalAddress: ``,
      street: `The Vue 255 w 5th st San Pedro`,
      zipCode: `California 90731 US`,
      url: `https://www.livecoinwatch.com/`,
    },
  ],
  [
    `NICEHASH`,
    {
      designation: `NICEHASH`,
      country: `SLOVENIE`,
      additionalAddress: ``,
      street: `Maribor Maribor 2000 SI`,
      zipCode: ``,
      url: `https://www.nicehash.com/`,
    }
  ]
]);

export const FORM_2086_CESSIONS_DEMO: any[] = [
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  }
];