import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ProfileHeaderComponent } from '../../components/profile-header/profile-header.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: `app-profile-page`,
  templateUrl: `./profile-page.component.html`,
  styleUrls: [`./profile-page.component.scss`],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ProfileHeaderComponent,
  ],
})
export class ProfilePageComponent { }