/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { LossHarvestingDetails } from '../../models/loss-harvesting.model';
import {
  TaxReport,
} from './../../models/report.model';

// eslint-disable-next-line no-shadow
export enum ReportActions {
  GenerateTaxReport = `[Report] Generate Tax Report`,
  LoadTaxReport = `[Report] Load Tax Report`,
  SetTaxReport = `[Report] Set Tax Report`,
  LoadLastReports = `[Report] Load Last Reports`,
  SetLastReports = `[Report] Set Last Reports`,
  LoadTaxLossHarvesting = `[Report] Load Tax Loss Harvesting`,
  SetTaxLossHarvesting = `[Report] Set Tax Loss Harvesting`,
  LoadReportSummary = `[Report] Load Report Summary`,
  SetReportSummary = `[Report] Set Report Summary`,
}

export const loadTaxReportAction = createAction(
  ReportActions.LoadTaxReport,
  props<{ fiscalYear: number; reportId?: string }>()
);

export const generateTaxReportAction = createAction(
  ReportActions.GenerateTaxReport,
  props<{ fiscalYear: number }>()
);

export const setTaxReportAction = createAction(
  ReportActions.SetTaxReport,
  props<{ taxReport: TaxReport }>()
);

export const loadLastReportsAction = createAction(
  ReportActions.LoadLastReports
);

export const setLastReportsAction = createAction(
  ReportActions.SetLastReports,
  props<{ lastReports: Map<string, TaxReport> }>()
);

export const loadTaxLossHarvestingAction = createAction(
  ReportActions.LoadTaxLossHarvesting,
  props<{
    portfolioValue: number;
    capitalLoss?: number;
    buyingPrice?: number;
  }>()
);

export const setTaxLossHarvestingAction = createAction(
  ReportActions.SetTaxLossHarvesting,
  props<{ lossHarvestingDetails: LossHarvestingDetails }>()
);

export const loadReportSummaryAction = createAction(
  ReportActions.LoadReportSummary,
  props<{ reportId: string }>()
);

export const setReportSummaryAction = createAction(
  ReportActions.SetReportSummary,
  props<{ reportSummary: TaxReport }>()
);
