/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { CustomError } from '../../../shared/models/error.model';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { RedirectionUrl } from '../../models/api.model';
import { Upload } from '../../models/upload.model';
import { APIService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { AccountService } from '../../../shared/services/account.service';
import { ToastService } from '../../../shared/services/toast.service';
import { goToAction, pushTagAction } from '../../../shared/store/actions/shared.action';
import { selectCurrentRoute, selectQueryParam } from '../../../shared/store/selectors/router.selector';
import { Account, AccountListResponse } from '../../models/account.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import { UserAccount } from '../../models/user-account.model';
import { FileService } from '../../services/file.service';
import { NameResolverService } from '../../services/name-resolver.service';
import {
  createApiAction,
  createOAuthAPIAction,
  createSelectedChainsApisAction,
  deleteAccountAction,
  downloadFileAction,
  getOAuthSyncUrlAction,
  loadAvailableChainsAction,
  loadTimezonesAction,
  loadTransactionsCountAction,
  loadUserAccountsAction,
  resolveNameAction,
  setApiConnectedAction,
  setApiErrorMessageAction,
  setAvailableChainsAction,
  setResolvedNameAction,
  setTimezonesAction,
  setTransactionsCountAction,
  setUserAccountsAction,
  renameAccountAction
} from '../actions/account.action';
import { AccountTag } from '../../../shared/models/tag.model';

@Injectable()
export class AccountEffects {
  createApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof createApiAction>>(createApiAction),
      withLatestFrom(
        this.sharedStore$.pipe(select(fromShared.selectAccountsList))
      ),
      delay(2000),
      switchMap(
        ([action, accountsList]: [ReturnType<typeof createApiAction>, AccountListResponse]) =>
          this.apiService
            .createAPI(
              action.account.key,
              action.api,
              action.alias,
              action.subAccount?.key
            )
            .pipe(
              switchMap((upload: Upload) => {
                const message = ``.concat(this.translateService.instant(`TOAST.ACCOUNT`))
                  .concat(` "${action.subAccount?.name || action.account?.name}" `)
                  .concat(this.translateService.instant(`TOAST.ADDED`));

                const tag: AccountTag = {
                  event: `import_account`,
                  account: action.subAccount?.key || action.account.key,
                  import_type: `API`,
                };

                this.toastService.success(message);

                const actions: any[] = [pushTagAction({ tag })];
                actions.push(
                  setApiConnectedAction()
                );

                const checkAvailableChains = [
                  ...accountsList.blockchain,
                  ...accountsList.wallet,
                ].map((a: Account) => a.key).includes(action.subAccount?.key || action.account.key);

                if (checkAvailableChains) {
                  actions.push(loadAvailableChainsAction({ apiId: upload.id }));
                }

                return actions;
              }),
              catchError((error: CustomError) => {
                let errorMessage = error?.message;
                if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
                  errorMessage = this.translateService.instant(error.errorCode);
                }

                const tag: AccountTag = {
                  event: `account_import_failure`,
                  account: action.subAccount?.key || action.account.key,
                  import_type: `API`,
                  error_message: errorMessage,
                };

                return [
                  pushTagAction({ tag }),
                  setApiErrorMessageAction({ apiErrorMessage: errorMessage }),
                ];
              })
            )
      )
    )
  );

  loadAvailableChains$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAvailableChainsAction>>(
        loadAvailableChainsAction
      ),
      switchMap((action: ReturnType<typeof loadAvailableChainsAction>) =>
        this.apiService.getAvailableChains(action.apiId).pipe(
          map((availableChains: string[]) =>
            setAvailableChainsAction({ availableChains })
          ),
          catchError((error: CustomError) => EMPTY)
        )
      )
    )
  );

  createSelectedChainsApis$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof createSelectedChainsApisAction>>(
          createSelectedChainsApisAction
        ),
        withLatestFrom(
          this.sharedStore$.pipe(select(fromShared.selectAccounts))
        ),
        switchMap(
          ([action, accounts]: [
            ReturnType<typeof createSelectedChainsApisAction>,
            Map<string, Account>
          ]) => {
            let apis$: Observable<Upload>[] = [];

            apis$ = action.selectedChains.map((chain: string) =>
              this.apiService.createAPI(chain, action.api, action.alias)
            );

            return combineLatest(apis$).pipe(
              mergeMap((apis: Upload[]) => {
                const timeOut = 2000;
                this.toastService.dismiss();

                apis.forEach((api: Upload, index: number) => {
                  setTimeout(() => {
                    const connected =
                      this.translateService.instant(`Connected`);

                    this.toastService.success(`${accounts.get(
                      api.platform
                      // eslint-disable-next-line quotes
                    )?.name} ${connected}`);
                  }, index * (timeOut + 500));
                });

                this.router.navigateByUrl(`/accounts`);

                return EMPTY;
              }),
              catchError((error: CustomError) => {
                const errorMessage: string =
                  this.translateService.instant(error.errorCode) ===
                    error.errorCode
                    ? error.message
                    : this.translateService.instant(error.errorCode);

                this.toastService.dismiss();

                this.toastService.error(errorMessage);

                return EMPTY;
              })
            );
          }
        )
      ),
    { dispatch: false }
  );

  resolveName$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof resolveNameAction>>(resolveNameAction),
      switchMap(
        (action: ReturnType<typeof resolveNameAction>) =>
          this.nameResolverService
            .resolveName(action.account.key, action.name)
            .pipe(
              map((resolvedName: NameResolverResponse) =>
                setResolvedNameAction({ resolvedName })
              ),
              catchError((error: CustomError) => {
                let apiErrorMessage = error?.message;
                if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
                  apiErrorMessage = this.translateService.instant(error.errorCode);
                }

                return [
                  setResolvedNameAction({ resolvedName: null }),
                  setApiErrorMessageAction({
                    apiErrorMessage,
                  }),
                ];
              })
            )
      )
    )
  );

  loadUserAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserAccountsAction>>(loadUserAccountsAction),
      switchMap(() => {
        return this.accountService.getUserAccounts(
        ).pipe(
          map((userAccounts: UserAccount[]) =>
            setUserAccountsAction({ userAccounts })
          ),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        );
      })
    )
  );

  loadTransactionsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTransactionsCountAction>>(
        loadTransactionsCountAction
      ),
      switchMap(() =>
        this.fileService.getAccountsTransactionsCount().pipe(
          map((transactionsCount: Map<string, number>) =>
            setTransactionsCountAction({
              transactionsCount,
            })
          )
        )
      )
    )
  );

  downloadFile: Upload = null;

  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof downloadFileAction>>(downloadFileAction),
        switchMap((action: ReturnType<typeof downloadFileAction>) => {
          const fileId = action.file !== null ? action.file.id : ``;
          this.downloadFile = action.file;

          return this.fileService.downloadFile(fileId);
        }),
        tap((data: any) => {
          // Doing it this way allows you to name the file
          const link = document.createElement(`a`);

          link.href = window.URL.createObjectURL(
            new Blob([data], { type: `application/zip` })
          );

          link.download = this.downloadFile.filename + `.zip`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      ),
    { dispatch: false }
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteAccountAction>>(deleteAccountAction),
      switchMap((action: ReturnType<typeof deleteAccountAction>) =>
        this.fileService.deleteFile(action.account.accountId).pipe(
          switchMap(() => {
            const tag: AccountTag = {
              event: `delete_account`,
              account: action.account.platform,
              added_date: action.account.uploaded,
              transactions_count: action.account.nbOfTransactions,
              import_type: action.account.type,
              last_sync_date: action.account.lastUpdated || action.account.uploaded,
            };

            this.toastService.success(this.translateService.instant(`AccountDeleted`));
            return [pushTagAction({ tag }), loadUserAccountsAction()];
          })
        )
      )
    )
  );

  renameAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof renameAccountAction>>(
        renameAccountAction
      ),
      switchMap((action: ReturnType<typeof renameAccountAction>) => {
        const updateAlias$: Observable<Upload> = action.account.type === `API` ?
          this.apiService.updateApiAlias(action.account.accountId, action.alias) :
          this.fileService.updateFileAlias(action.account.accountId, action.alias);

        return updateAlias$.pipe(
          switchMap(() => {
            const tag: AccountTag = {
              event: `rename_account`,
              account: action.account.platform,
              added_date: action.account.uploaded,
              transactions_count: action.account.nbOfTransactions,
              import_type: action.account.type,
              last_sync_date: action.account.lastUpdated || action.account.uploaded,
            };

            this.toastService.success(this.translateService.instant(`AccountAliasUpdated`));

            return [pushTagAction({ tag }), loadUserAccountsAction()];
          })
        );
      }
      )
    )
  );

  getOAuthSyncUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof getOAuthSyncUrlAction>>(
          getOAuthSyncUrlAction
        ),
        switchMap((action: ReturnType<typeof getOAuthSyncUrlAction>) =>
          this.apiService.getOAuthSyncUrl(action.platform).pipe(
            map((redirectionUrl: RedirectionUrl) => {

              if (action.platform === `BITSTAMP`) {
                localStorage.setItem(`codeVerifier`, redirectionUrl.codeVerifier);
              }

              window.location.href = redirectionUrl.url;
            }),
            catchError((error: CustomError) => {
              this.toastService.error(error.message);

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  createOAuthAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof createOAuthAPIAction>>(
        createOAuthAPIAction
      ),
      withLatestFrom(
        this.store$.select(selectCurrentRoute),
        this.store$.pipe(select(selectQueryParam(`code`))),
        this.sharedStore$.pipe(select(fromShared.selectAccounts)),
      ),
      switchMap(
        ([action, route, code, accounts]: [
          ReturnType<typeof createOAuthAPIAction>,
          any,
          string,
          Map<string, Account>
        ]) => {
          const account: string = route.routeConfig.path.replace(`-sync/callback`, ``).toUpperCase();

          return this.apiService.createOAuthAPI(account, code).pipe(
            switchMap((upload: Upload) => {
              const message = ``.concat(this.translateService.instant(`TOAST.ACCOUNT`))
                .concat(` "${accounts.get(upload.platform)?.name}" `)
                .concat(this.translateService.instant(`TOAST.ADDED`));

              const tag: AccountTag = {
                event: `import_account`,
                account: account,
                import_type: `OAUTH`,
              };

              this.toastService.success(message);

              return [pushTagAction({ tag }), goToAction({ url: `/accounts` })];
              // const partner = sessionStorage.getItem(`partner`);
              // if (partner) {
              //   return goToAction({ url: `/oauth-success`, queryParams: { account } });
              // } else {
              //   return goToAction({ url: `/accounts` });
              // }

            }),
            catchError((error: CustomError) => {
              const tag: AccountTag = {
                event: `account_import_failure`,
                import_type: `OAUTH`,
                account: account,
                error_message: error.message,
              };

              return [pushTagAction({ tag })];
            })
          );
        }
      )
    )
  );

  loadTimezones$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTimezonesAction>>(loadTimezonesAction),
      switchMap((action: ReturnType<typeof loadTimezonesAction>) =>
        this.fileService.getTimezones().pipe(
          map((timezones: TimezoneDetails[]) =>
            setTimezonesAction({ timezones })
          ),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly apiService: APIService,
    private readonly nameResolverService: NameResolverService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly fileService: FileService,
    private readonly toastService: ToastService,
    private readonly accountService: AccountService
  ) { }
}
