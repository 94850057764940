/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TrackingService } from '../../../shared/services/tracking.service';
import { pushTagAction } from '../../../shared/store/actions/shared.action';


import { EMPTY } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { CustomError } from '../../../shared/models/error.model';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { Tag } from '../../../shared/models/tag.model';
import { ToastService } from '../../../shared/services/toast.service';
import { AddOnDetails } from '../../models/addon.model';
import { AvailableAddons, AvailablePlans, Payment, PaymentEstimateV3, PendingCharge, VoucherUsage } from '../../models/payment.model';
import { SubscriptionDetails, SubscriptionRenewalRequest } from '../../models/subscription.model';
import { User } from '../../models/user.model';
import { PaymentService } from '../../services/payment.service';
import { loadVaultAction } from '../actions/affiliation.action';
import {
  applyVaultAction,
  checkVoucherCodeAction,
  downloadInvoiceAction, enableAddonAction, loadAddonEstimateAction, loadAddonsDetailsAction, loadAvailableAddonsAction, loadAvailablePlansAction, loadPaymentsAction, loadPendingChargeAction, loadPlanEstimateAction, loadSubscriptionDetailsAction, payChargeAction, renewSubscriptionAction, setAddonEstimateAction, setAddonsDetailsAction, setAvailableAddonsAction, setAvailablePlansAction, setPaymentsAction, setPendingChargeAction, setPlanEstimateAction, setSubscriptionDetailsAction, setValidatedPaymentAction, setVoucherResponseAction, upgradePlanAction, validatePaymentAction,
} from '../actions/payment.action';

declare const Stripe: any;

@Injectable()
export class PaymentEffects {
  upgradePlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof upgradePlanAction>>(upgradePlanAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        switchMap(([action, user]: [ReturnType<typeof upgradePlanAction>, User]) =>
          this.paymentService
            .upgradePlan(
              action.paymentMethod,
              action.requestedPlan,
              action.fiscalYear,
              action.papCookie,
              action.tags,
              action.useVault,
              action.code,
            )
            .pipe(
              tap((payment: Payment) => {
                this.pay(payment, user);
              })
            )
        )
      ),
    { dispatch: false }
  );

  loadAddonsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAddonsDetailsAction>>(loadAddonsDetailsAction),
      switchMap((action: ReturnType<typeof loadAddonsDetailsAction>) =>
        this.paymentService.getAddonsDetails().pipe(
          map((addonsDetails: AddOnDetails[]) => setAddonsDetailsAction({ addonsDetails })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  loadAddonEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAddonEstimateAction>>(loadAddonEstimateAction),
      switchMap((action: ReturnType<typeof loadAddonEstimateAction>) =>
        this.paymentService.getAddonEstimate(action.fiscalYear, action.requestedAddOn, action.useVault, action.code).pipe(
          map((addonEstimate: PaymentEstimateV3) => setAddonEstimateAction({ addonEstimate })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  loadPlanEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadPlanEstimateAction>>(loadPlanEstimateAction),
      switchMap((action: ReturnType<typeof loadPlanEstimateAction>) =>
        this.paymentService.getPlanEstimate(action.fiscalYear, action.requestedPlan, action.useVault, action.code).pipe(
          map((planEstimate: PaymentEstimateV3) => setPlanEstimateAction({ planEstimate })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  enableAddon$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof enableAddonAction>>(enableAddonAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
      switchMap(([action, user]: [ReturnType<typeof enableAddonAction>, User]) =>
        this.paymentService.enableAddon(action.paymentMethod, action.requestedAddOn, action.fiscalYear, action.useVault, action.code).pipe(
          tap((payment: Payment) => {
            this.pay(payment, user);
          })
        )
      )
    ), { dispatch: false }
  );

  loadSubscriptionDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadSubscriptionDetailsAction>>(loadSubscriptionDetailsAction),
      switchMap((action: ReturnType<typeof loadSubscriptionDetailsAction>) =>
        this.paymentService.getSubscriptionDetails().pipe(
          map((subscriptionDetails: SubscriptionDetails) => setSubscriptionDetailsAction({ subscriptionDetails })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  loadPendingCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadPendingChargeAction>>(loadPendingChargeAction),
      switchMap((action: ReturnType<typeof loadPendingChargeAction>) =>
        this.paymentService.getPendingCharge().pipe(
          map((pendingCharge: PendingCharge) => setPendingChargeAction({ pendingCharge })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  payCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof payChargeAction>>(payChargeAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
      switchMap(([action, user]: [ReturnType<typeof payChargeAction>, User]) =>
        this.paymentService.payCharge(action.paymentMethod).pipe(
          tap((payment: Payment) => {
            this.pay(payment, user);
          })
        )
      )
    ), { dispatch: false }
  );

  applyVault$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof applyVaultAction>>(applyVaultAction),
      switchMap((action: ReturnType<typeof applyVaultAction>) =>
        this.paymentService.applyVault().pipe(
          switchMap(() => {
            this.toastService.success(this.translateService.instant(`VaultUsed`));

            return [loadSubscriptionDetailsAction(), loadVaultAction()];
          }),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  checkVoucherCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof checkVoucherCodeAction>>(checkVoucherCodeAction),
      switchMap((action: ReturnType<typeof checkVoucherCodeAction>) =>
        this.paymentService.checkVoucherCode(action.voucherCode, action.fiscalYear, action.requestedPlan, action.requestedAddOn).pipe(
          switchMap((res: GenericResponse) => {
            const actions = [];
            actions.push(setVoucherResponseAction({ voucherResponse: res }));

            if (res.success) {
              if (action.checkoutType === `PLAN`) {
                actions.push(loadPlanEstimateAction({ requestedPlan: action.requestedPlan, fiscalYear: action.fiscalYear, useVault: action.useVault, code: action.voucherCode }));
              }

              if (action.checkoutType === `ADDON`) {
                actions.push(loadAddonEstimateAction({ requestedAddOn: action.requestedAddOn, fiscalYear: action.fiscalYear, useVault: action.useVault, code: action.voucherCode }));
              }

              const msg = ``.concat(
                this.translateService.instant(`Code`),
                ` ${action.voucherCode} `,
                this.translateService.instant(`Applied`)
              );

              this.toastService.success(msg);

              return actions;
            } else {
              this.toastService.error(res.details);

              return EMPTY;
            }
          }),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);
            this.toastService.error(message);

            return [setVoucherResponseAction({ voucherResponse: { success: false, details: action.voucherCode } })];
          })
        )
      )
    )
  );

  loadAvailablePlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAvailablePlansAction>>(loadAvailablePlansAction),
      switchMap(() =>
        this.paymentService.getAvailablePlans().pipe(
          map((availablePlans: AvailablePlans) => setAvailablePlansAction({ availablePlans }))
        )
      )
    )
  );

  loadAvailableAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAvailableAddonsAction>>(loadAvailableAddonsAction),
      switchMap(() =>
        this.paymentService.getAvailableAddons().pipe(
          map((availableAddons: AvailableAddons) => setAvailableAddonsAction({ availableAddons }))
        )
      )
    )
  );

  loadPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadPaymentsAction>>(loadPaymentsAction),
      switchMap(() =>
        this.paymentService.getAllPayments().pipe(
          map((payments: Payment[]) => {
            payments.sort((paymentA: Payment, paymentB: Payment) => (paymentA.paid < paymentB.paid ? 1 : -1));
            return setPaymentsAction({ payments });
          }),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  validatePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof validatePaymentAction>>(validatePaymentAction),
      switchMap((action: ReturnType<typeof validatePaymentAction>) =>
        this.paymentService.validatePayment(action.paymentId).pipe(
          delay(3000),
          map((payment: Payment) => {
            if (payment.confirmed) {
              document.getElementById(`postAffiliateImg`)?.remove();

              const postAffiliateImg = document.createElement(`div`);
              postAffiliateImg.id = `postAffiliateImg`;
              postAffiliateImg.innerHTML = payment.affiliateTrackingCode;

              document.body.appendChild(postAffiliateImg);
              return setValidatedPaymentAction({ payment });
            } else {
              return validatePaymentAction({ paymentId: payment.id });
            }
          }),
        )
      )
    )
  );

  setValidatedPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof setValidatedPaymentAction>>(setValidatedPaymentAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        map(([action, user]: [ReturnType<typeof setValidatedPaymentAction>, User]) => {
          let paymentType: string;

          if (action.payment.paymentMethod) {
            paymentType = action.payment.paymentMethod === `STRIPE` ? `card` : `crypto`;
          } else {
            paymentType = `credit`;
          }

          const tag: Tag = {
            event: `purchase`,
            environment_market: user?.fiscalResidency,
            ecommerce: {
              payment_type: paymentType,
              transaction_id: action.payment.id,
              item_name: action.payment.plan,
              price: action.payment.paidPrice,
              coupon: action.payment.vouchers?.map((voucher: VoucherUsage) => voucher.code).join(`,`),
              discount: action.payment.price - action.payment.paidPrice,
            },
          };

          this.trackingService.pushTag(tag);
        })
      ),
    { dispatch: false }
  );

  downloadInvoice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof downloadInvoiceAction>>(downloadInvoiceAction),
        switchMap((action: ReturnType<typeof downloadInvoiceAction>) =>
          this.paymentService.downloadInvoice(action.paymentId)
        ),
        map((data: string) => {
          const link = window.open(`about:blank`);
          link.document.open();
          link.document.write(data);
          link.document.close();

          return pushTagAction({
            tag: {
              event: `download_invoice`,
            },
          });
        })
      ),
  );

  renewSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof renewSubscriptionAction>>(renewSubscriptionAction),
      switchMap((action: ReturnType<typeof renewSubscriptionAction>) =>
        this.paymentService.renewSubscription(action.requestedPlan).pipe(
          map((subscriptionRenewalRequest: SubscriptionRenewalRequest) => {
            if (subscriptionRenewalRequest.success) {
              if (subscriptionRenewalRequest.needToRedirect) {
                window.open(subscriptionRenewalRequest.redirectUrl, `_blank`);
              }
            } else {
              const message = `Echec lors du réabonnement`;
              this.toastService.error(message);
            }
          }),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    ), { dispatch: false }
  );

  pay(payment: Payment, user: User): void {
    const tag: Tag = {
      event: `begin_payment`,
      environment_market: user?.fiscalResidency,
      ecommerce: {
        transaction_id: payment.id,
        payment_type: payment.paymentMethod === `STRIPE` ? `card` : `crypto`,
        item_name: payment.plan,
        price: payment.paidPrice,
        coupon: payment.vouchers?.map((voucher: VoucherUsage) => voucher.code).join(`,`),
        discount: payment.price - payment.paidPrice,
      }
    };

    this.trackingService.pushTag(tag);

    if (payment.paidPrice === 0) {
      location.reload();
    } else if (payment.paymentMethod === `STRIPE`) {
      this.redirectToStripe(payment);
    } else {
      this.redirectToCoinbase(payment);
    }
  }

  redirectToStripe(payment: Payment): void {
    const sessionId = payment.stripeId;
    const stripe = Stripe(environment.stripe);
    stripe
      .redirectToCheckout({
        sessionId,
      })
      .then((result: any) => {
        this.toastService.error(result.error.message);
      });
  }

  redirectToCoinbase(payment: Payment): void {
    window.location.href = payment.coinbaseRedirect;
  }

  constructor(
    private readonly actions$: Actions,
    private readonly paymentService: PaymentService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly trackingService: TrackingService,
    private readonly authStore$: Store<fromAuth.State>
  ) { }
}
