<div class="tile" [routerLink]="link.route" [routerLinkActive]="" #rla="routerLinkActive">
  <div *ngIf="rla.isActive" class="tile__bar"></div>

  <div
    class="tile__content"
    [ngClass]="{
      'tile__content--active': rla.isActive,
    }"
  >
    <img
      [src]="rla.isActive ? link.routeActiveIcon : link.routeIcon"
      [alt]="rla.isActive ? link.routeActiveIcon : link.routeIcon"
    />
    <p class="body-14-med">{{ link.label | translate }}</p>
    <div *ngIf="link.new" class="plus">PLUS</div>
  </div>
</div>
