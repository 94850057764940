/* eslint-disable @typescript-eslint/naming-convention */
import { Action, createReducer, on } from '@ngrx/store';
import { GeolocationDetails } from '../../../taxation/models/geolocation-details.model';
import { AccountListResponse } from '../../../taxation/models/account.model';
import { Configuration } from '../../models/configuration.model';
import { Feature } from '../../models/feature.model';
import { TransferRequest } from '../../models/transfer-request.model';
import { UserPreferences } from '../../models/user-preferences.model';
import * as SharedActions from '../actions/shared.action';
import { Balance } from '../../../taxation/models/balance.model';
import { TokenConfidence } from '../../../taxation/models/token-confidence.model';
import { CustomError } from '../../models/error.model';

export interface State {
  coins: any;
  stablecoins: string[];
  fiats: string[];
  shitcoins: string[];
  configuration: Configuration;
  isConfigurationLoaded: boolean;
  userLocation: GeolocationDetails;
  userPreferences: UserPreferences;
  intercom: {
    pageName: string;
    analysisCount: number;
  };
  accounts: AccountListResponse;
  features: Feature[];
  emailTransferRequest: TransferRequest;
  hasEmailTransferError: boolean;
  frenchPostCodes: Map<string, string>;
  belgianFiscalProfiles: Map<string, string>;
  spanishAutonomousCommunities: Map<string, string>;
  userBalance: Balance;
  tokenConfidence: TokenConfidence;
  onboarding: {
    completed: boolean;
    onboardingError: CustomError;
  },
  isPaymentDialogOpened: boolean;
  partner: string;
  currentOpenedForm: string;
}

export const initialState: State = {
  coins: null,
  stablecoins: [],
  fiats: [],
  shitcoins: [],
  isConfigurationLoaded: false,
  userLocation: null,
  userPreferences: null,
  intercom: {
    pageName: ``,
    analysisCount: -1,
  },
  accounts: null,
  configuration: {
    pagination: {
      pageSize: 25,
      pageSort: `transactionDate`,
      pageDirection: `desc`
    },
  },
  features: [],
  emailTransferRequest: null,
  hasEmailTransferError: false,
  frenchPostCodes: new Map<string, string>([]),
  belgianFiscalProfiles: new Map<string, string>([]),
  spanishAutonomousCommunities: new Map<string, string>([]),
  userBalance: null,
  tokenConfidence: null,
  onboarding: {
    completed: false,
    onboardingError: null,
  },
  isPaymentDialogOpened: false,
  partner: null,
  currentOpenedForm: `3916-bis`
};

const reducer = createReducer(
  initialState,
  on(SharedActions.setAccountsAction, (state: State, { accounts }: any) => ({
    ...state,
    accounts,
  })),
  on(SharedActions.setCoinsAction, (state: State, { coins }: any) => ({
    ...state,
    coins,
  })),
  on(
    SharedActions.setConfigurationAction,
    (state: State, { configuration }: any) => ({
      ...state,
      configuration,
      isConfigurationLoaded: true,
    })
  ),
  on(
    SharedActions.setAnalysisCountAction,
    (state: State, { analysisCount }: any) => ({
      ...state,
      intercom: {
        ...state.intercom,
        analysisCount,
      },
    })
  ),
  on(
    SharedActions.setUserPreferencesAction,
    (state: State, { userPreferences }: any) => ({
      ...state,
      userPreferences,
    })
  ),
  on(SharedActions.setFeaturesAction, (state: State, { features }: any) => ({
    ...state,
    features,
  })),
  on(
    SharedActions.setStablecoinsAction,
    (state: State, { stablecoins }: any) => ({
      ...state,
      stablecoins,
    })
  ),
  on(
    SharedActions.setFiatsAction,
    (state: State, { fiats }: any) => ({
      ...state,
      fiats,
    })
  ),
  on(
    SharedActions.setEmailTransferRequestAction,
    (state: State, { emailTransferRequest }: any) => ({
      ...state,
      emailTransferRequest,
    })
  ),
  on(
    SharedActions.setHasEmailTransferRequestAction,
    (state: State, { hasEmailTransferError }: any) => ({
      ...state,
      hasEmailTransferError,
    })
  ),
  on(SharedActions.setShitcoinsAction, (state: State, { shitcoins }: any) => ({
    ...state,
    shitcoins,
  })),
  on(
    SharedActions.setUserLocationAction,
    (state: State, { userLocation }: any) => ({
      ...state,
      userLocation,
    })
  ),
  on(
    SharedActions.setFrenchPostCodesAction,
    (state: State, { frenchPostCodes }: any) => ({
      ...state,
      frenchPostCodes,
    })
  ),
  on(
    SharedActions.setBelgianFiscalProfilesAction,
    (state: State, { belgianFiscalProfiles }: any) => ({
      ...state,
      belgianFiscalProfiles,
    })
  ),
  on(
    SharedActions.setSpanishAutonomousCommunitiesAction,
    (state: State, { spanishAutonomousCommunities }: any) => ({
      ...state,
      spanishAutonomousCommunities,
    })
  ),
  on(
    SharedActions.setUserBalanceAction,
    (state: State, { userBalance }: any) => ({
      ...state,
      userBalance,
    })
  ),
  on(SharedActions.loadTokenConfidenceAction, (state: State) => ({
    ...state,
    tokenConfidence: null,
  })),
  on(
    SharedActions.setTokenConfidenceAction,
    (state: State, { tokenConfidence }: any) => ({
      ...state,
      tokenConfidence,
    })
  ),
  on(
    SharedActions.setOnboardingCompletedAction,
    (state: State) => ({
      ...state,
      onboarding: {
        ...state.onboarding,
        completed: true,
      },
    })
  ),
  on(
    SharedActions.setOnboardingErrorAction,
    (state: State, { onboardingError }: any) => ({
      ...state,
      onboarding: {
        ...state.onboarding,
        onboardingError,
      },
    })
  ),
  on(
    SharedActions.setIsPaymentDialogOpenedAction,
    (state: State, { isPaymentDialogOpened }: any) => ({
      ...state,
      isPaymentDialogOpened
    })
  ),
  on(
    SharedActions.setPartnerAction,
    (state: State, { partner }: any) => ({
      ...state,
      partner
    })
  ),
  on(
    SharedActions.setCurrentOpenedFormAction,
    (state: State, { currentOpenedForm }: any) => ({
      ...state,
      currentOpenedForm
    })
  )
);

export const sharedReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
