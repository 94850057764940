/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { TaxableCession } from '../../models/taxable-cession.model';
import { Assessment } from '../../models/assessment.model';

// eslint-disable-next-line no-shadow
export enum AssessmentActions {
  DownloadAssessmentFile = `[Assessment] Download Assessment File`,
  DownloadAssessmentFileAsPDF = `[Assessment] Download Assessment File As PDF`,
  SetTaxableCessions = `[Assessment] Set Taxable Cessions`,
  StartAssessment = `[Assessment] Start Assessment`,
  LoadAssessment = `[User] Load Assessment`,
  FetchLastAssessment = `[User] Fetch Last Assessment`,
  UnsyncAssessment = `[User] Unsync Assessment`,
  SetAssessment = `[User] Set Assessment`,
}

export const downloadAssessmentFileAction = createAction(
  AssessmentActions.DownloadAssessmentFile,
  props<{ assessmentId: string; filename: string }>()
);

export const downloadAssessmentFileAsPDFAction = createAction(
  AssessmentActions.DownloadAssessmentFileAsPDF,
  props<{ assessmentId: string; filename: string }>()
);

export const setTaxableCessionsAction = createAction(
  AssessmentActions.SetTaxableCessions,
  props<{ taxableCessions: TaxableCession[] }>()
);

export const startAssessmentAction = createAction(
  AssessmentActions.StartAssessment,
  props<{ fiscalYear: number }>()
);

export const loadAssessmentAction = createAction(
  AssessmentActions.LoadAssessment
);

export const unsyncAssessmentAction = createAction(
  AssessmentActions.UnsyncAssessment
);

export const setAssessmentAction = createAction(
  AssessmentActions.SetAssessment,
  props<{ assessment: Assessment }>()
);

export const fetchLastAssessmentAction = createAction(
  AssessmentActions.FetchLastAssessment,
  props<{ assessmentId?: string }>()
);