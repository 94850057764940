import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DialogData } from '../../models/dialog-data.model';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: `app-secret-dialog`,
  templateUrl: `./secret-dialog.component.html`,
  styleUrls: [`./secret-dialog.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class SecretDialogComponent {
  userMail = sessionStorage.waltio_user || ``;

  constructor(
    public dialogRef: MatDialogRef<SecretDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
}
