import {
  TransactionSubType,
  TransactionWarningType,
} from '../models/transaction.model';
import { SearchDTO } from '../models/transactions-filters.model';

export const TRANSACTION_SUBTYPES: Map<string, string> = new Map([
  [`MASTERNODE_AND_STAKING`, `Vous avez reçu des revenus de masternode ou staking`],
  [`CFD_GAIN`, `Vous avez reçu des revenus liés à des futures, margin...`],
  [`CASHBACK`, `Vous avez reçu du cashback`],
  [`UNSTAKING`, `Vous avez enlevé vos jetons de staking`],
  [`DONATION`, `Vous avez reçu un don en cryptomonnaie`],
  [`AIRDROP`, `Vous avez reçu des jetons lors d'un airdrop`],
  [`FORK`, `Vous avez reçu des jetons lors d'un fork`],
  [`INCOME`, `Vous avez reçu des cryptomonnaies en guise de salaire`],
  [`BORROW`, `Vous avez emprunté des cryptomonnaies`],
  [`COLLATERAL_REIMBURSEMENT`, `Vous avez récupéré des cryptos déposées en collatéral`],
  [`OTHER_GAIN`, `Vous avez reçu des revenus passifs (farming, lending...)`],
  [`MINING`, `Vous avez reçu des revenus de minage`],
  [`ICO`, `Vous avez reçu des cryptos lors d’une ICO, IDO, IEO...`],
  [`SWAP`, `Vous avez réalisé un swap ou échange de cryptos`],
  [`CRYPTO_BUY`, `Vous avez acheté des cryptos avec des FIATs`],
  [`SCAM`, `Vous avez perdu des cryptomonnaies suite à une arnaque`],
  [`TRANSFER_BETWEEN_ACCOUNT`, `Vous avez transféré des cryptomonnaies entre vos comptes`],
  [`PLAY2EARN_GAIN`, `Vous avez reçu des cryptomonnaies en jouant à des jeux vidéo`],
  [`LIQUIDITY_WITHDRAWAL`, `Vous avez retiré des cryptos d’une pool de liquidité (LP)`],
  [`PAYMENT`, `Vous avez payé un bien ou un service en crypto`],
  [`FEE`, `Vous avez payé des frais de transaction`],
  [`ADD_STAKING`, `Vous avez staké des cryptomonnaies`],
  [`CFD_LOSS`, `Vous avez réalisé une perte liée à des futures, margin....`],
  [`HACK_OR_LOSS`, `Vous avez subi un hack ou une perte en crypto`],
  [`ADD_LIQUIDITY`, `Vous avez ajouté de la liquidité dans une pool (LP)`],
  [`LIQUIDATION`, `Votre position a été liquidée (futures, margin, borrowing...)`],
  [`ADD_COLLATERAL`, `Vous avez ajouté des crypto en collatéral`],
  [`OTHER_WITHDRAWAL`, `Vous avez envoyé des cryptos`],
  [`OTHER_LOSS`, `Vous avez subi une perte en crypto`],
  [`NFT`, `Vous avez acheté un NFT en crypto`],
]);

export const DEPOSIT_TRANSACTION_SUBTYPES: TransactionSubType[] = [
  `MASTERNODE_AND_STAKING`,
  `CFD_GAIN`,
  `CASHBACK`,
  `UNSTAKING`,
  `DONATION`,
  `AIRDROP`,
  `FORK`,
  `INCOME`,
  `BORROW`,
  `COLLATERAL_REIMBURSEMENT`,
  `OTHER_GAIN`,
  `MINING`,
  `ICO`,
  `SWAP`,
  `CRYPTO_BUY`,
  `SCAM`,
  `TRANSFER_BETWEEN_ACCOUNT`,
  `PLAY2EARN_GAIN`,
  `LIQUIDITY_WITHDRAWAL`
];

export const WITHDRAWAL_TRANSACTION_SUBTYPES: TransactionSubType[] = [
  `TRANSFER_BETWEEN_ACCOUNT`,
  `PAYMENT`,
  `FEE`,
  `ADD_STAKING`,
  `CFD_LOSS`,
  `DONATION`,
  `HACK_OR_LOSS`,
  `ADD_LIQUIDITY`,
  `LIQUIDATION`,
  `ADD_COLLATERAL`,
  `OTHER_WITHDRAWAL`,
  `OTHER_LOSS`,
  `ICO`,
  `NFT`,
  `SWAP`,
];

export const TRANSACTION_WARNINGS: TransactionWarningType[] = [
  `INSUFFICIENT_BALANCE`,
  `UNKNOWN_PRICE`,
  `WITHDRAWAL_TO_CATEGORIZE`,
];

export const TRANSACTION_WARNING_LINKS: Map<TransactionWarningType, number> =
  new Map([
    [`UNKNOWN_PRICE`, 4269998],
    [`INSUFFICIENT_BALANCE`, 5854121],
    [`WITHDRAWAL_TO_CATEGORIZE`, 4006824],
  ]);

export const DEFAULT_TRANSACTIONS_FILTERS: SearchDTO = {
  onlyTaxable: null,
  onlyWarnings: null,
  onlyModified: null,
  onlyCashin: null,
  onlyCashout: null,
  maxAmount: null,
  minAmount: null,
  startDate: null,
  endDate: null,
  platforms: {
    includes: [],
    excludes: [],
  },
  currencies: {
    includes: [],
    excludes: [],
  },
  fromCurrencies: {
    includes: [],
    excludes: [],
  },
  toCurrencies: {
    includes: [],
    excludes: [],
  },
  types: {
    includes: [],
    excludes: [],
  },
  subTypes: {
    includes: [],
    excludes: [],
  },
  warningTypes: {
    includes: [],
    excludes: [],
  },
  uploadIds: {
    includes: [],
    excludes: [],
  },
};
