/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { User as AuthUser } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import md5 from 'md5';
import PouchDB from 'pouchdb';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { CustomError } from '../../../shared/models/error.model';
import { AssessmentTag } from '../../../shared/models/tag.model';
import { ToastService } from '../../../shared/services/toast.service';
import { pushTagAction } from '../../../shared/store/actions/shared.action';
import { Assessment } from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
import { downloadAssessmentFileAction, downloadAssessmentFileAsPDFAction, fetchLastAssessmentAction, loadAssessmentAction, setAssessmentAction, setTaxableCessionsAction, startAssessmentAction, unsyncAssessmentAction } from '../actions/assessment.action';
import * as fromAssessment from '../selectors/assessment.selector';

@Injectable()
export class AssessmentEffects {

  downloadAssessmentFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof downloadAssessmentFileAction>>(downloadAssessmentFileAction),
      switchMap((action: ReturnType<typeof downloadAssessmentFileAction>) =>
        this.assessmentService.downloadAssessmentFile(action.assessmentId, action.filename).pipe(
          switchMap((data: any) => {
            const tag: AssessmentTag = {
              event: `download_document`,
              document_format: action.filename.split(`.`)[1],
              document_type: action.filename
            };

            if (action.filename.includes(`.json`)) {

              return [pushTagAction({ tag }), setTaxableCessionsAction({ taxableCessions: data })];
            } else {
              const link = document.createElement(`a`);
              link.href = window.URL.createObjectURL(
                new Blob([data], { type: data.type })
              );

              link.download = action.filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              return EMPTY;
            }
          }),
          catchError((error: CustomError) => {
            return EMPTY;
          })
        )
      ),
    )
  );

  downloadAssessmentFileAsPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof downloadAssessmentFileAsPDFAction>>(downloadAssessmentFileAsPDFAction),
      switchMap((action: ReturnType<typeof downloadAssessmentFileAsPDFAction>) =>
        this.assessmentService.downloadAssessmentFileAsPDF(action.assessmentId, action.filename).pipe(
          switchMap((data: Blob) => {
            const link = document.createElement(`a`);
            link.href = window.URL.createObjectURL(
              new Blob([data], { type: data.type })
            );

            link.download = action.filename.replace(`.xlsx`, `.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            const tag: AssessmentTag = {
              event: `download_document`,
              document_format: `pdf`,
              document_type: action.filename.replace(`.xlsx`, `.pdf`)
            };
            return [pushTagAction({ tag })];
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        ),
      ),
    ),
  );

  startAssessment$ = createEffect(() => this.actions$.pipe(
    ofType<ReturnType<typeof startAssessmentAction>>(startAssessmentAction),
    switchMap((action: ReturnType<typeof startAssessmentAction>) =>
      this.assessmentService.startAssessment(action.fiscalYear).pipe(
        map((assessment: Assessment) => setAssessmentAction({ assessment }))
      )),
  ));

  fetchLastAssessment$ = createEffect(() => this.actions$.pipe(
    ofType<ReturnType<typeof fetchLastAssessmentAction>>(fetchLastAssessmentAction),
    switchMap((action: ReturnType<typeof fetchLastAssessmentAction>) =>
      this.assessmentService.getAssessment(action.assessmentId).pipe(
        switchMap((assessment: Assessment) => {
          return [
            setAssessmentAction({ assessment })
          ];
        })
      )),
  ));

  setAssessment$ = createEffect(() => this.actions$.pipe(
    ofType<ReturnType<typeof setAssessmentAction>>(setAssessmentAction),
    switchMap((action: ReturnType<typeof setAssessmentAction>) => {
      if (action.assessment?.state === `COMPLETED`) {
        return this.assessmentService.getAssessmentCount().pipe(
          switchMap((assessmentCount: number) => {
            const tag: AssessmentTag = {
              event: `assessment_completed`,
              assessment_count: assessmentCount,
              assessment_year: action.assessment.fiscalYear,
              assessment_warnings: action.assessment.nbOfGeneratedWarning > 0
            };

            return [pushTagAction({ tag })];
          })
        );
      } else {
        return EMPTY;
      }
    })
  ));



  loadAssessment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof loadAssessmentAction>>(
          loadAssessmentAction
        ),
        withLatestFrom(
          this.authStore.pipe(select(fromAuth.selectAccessToken)),
          this.authStore.pipe(select(fromAuth.selectAuthUser))
        ),
        switchMap(
          ([action, accessToken, authUser]: [
            ReturnType<typeof loadAssessmentAction>,
            string,
            AuthUser
          ]) => {

            const user = md5(sessionStorage.waltio_user || authUser.email);

            // Init DBs
            this.pouchDB = new PouchDB(`assessment-db`);
            this.remoteCouchDB = new PouchDB(
              `${environment.apiUrl}/${environment.couchDbUrl}`,
              {
                fetch: (url, opts): any => {
                  // @ts-ignore
                  opts.headers.set(`Authorization`, `Bearer ${accessToken}`);

                  return PouchDB.fetch(url, opts);
                },
              }
            );

            // Sync both DBs
            this.pouchDB.sync(this.remoteCouchDB, {
              live: true,
              retry: true,
              doc_ids: [user],
            });

            // Start listening to DB changes
            this.pouchDB
              .changes({
                since: `now`,
                live: true,
                doc_ids: [user],
                include_docs: true
              })
              .on(`change`, (res: any) => {
                this.assessmentStore.dispatch(
                  setAssessmentAction({ assessment: res.doc })
                );
              });

            return [fetchLastAssessmentAction({})];
          }
        )
      )
  );

  unsyncAssessment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof unsyncAssessmentAction>>(
          unsyncAssessmentAction
        ),
        switchMap(() => {
          if (this.pouchDB && this.remoteCouchDB) {
            this.pouchDB.destroy();
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  // Pouch DB (local)
  private pouchDB: PouchDB.Database;

  // Couch DB (remote)
  private remoteCouchDB: PouchDB.Database;

  constructor(
    private readonly actions$: Actions,
    private readonly authStore: Store<fromAuth.State>,
    private readonly assessmentStore: Store<fromAssessment.State>,
    private readonly assessmentService: AssessmentService,
    private readonly toastService: ToastService
  ) { }
}
