import { Action, createReducer, on } from '@ngrx/store';
import { CustomError } from '../../../shared/models/error.model';
import { Page } from '../../../shared/models/page.model';
import { Analysis } from '../../models/analysis.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';
import { CurrencyOccurences } from '../../models/currency.model';
import { Transaction, TransactionWarningAggregation } from '../../models/transaction.model';
import { UploadErrors } from '../../models/upload.model';
import { WarningOccurences } from '../../models/warning.model';
import * as TaxAssistantActions from '../actions/analysis.action';

export interface State {
  currentStep: number;
  analysisProgress: number;
  uploadErrors: UploadErrors[];
  prices: {
    tokensWithNoPrice: CurrencyOccurences[];
    tokensWithPrice: string[];
  };
  insufficientBalances: TokenAndPlatformBalanceDetail[];
  unmatchedTransactionsWithoutLabel: Page<Transaction>;
  matchedTransactions: Transaction[][];
  lastAnalysis: Analysis;
  lastAnalysisWarnings: WarningOccurences[];
  analysis: Analysis;
  fetchingAnalysis: boolean;
  isAnalysisFailed: boolean;
  analysisError: CustomError;
  gdprConsent: boolean;
  aggregatedWarnings: TransactionWarningAggregation[];
  showNoneCriticalBalances: boolean;
}

export const initialState: State = {
  currentStep: 0,
  fetchingAnalysis: false,
  analysisProgress: 0,
  uploadErrors: null,
  prices: {
    tokensWithNoPrice: null,
    tokensWithPrice: null,
  },
  insufficientBalances: null,
  unmatchedTransactionsWithoutLabel: null,
  matchedTransactions: [],
  lastAnalysis: null,
  lastAnalysisWarnings: null,
  analysis: null,
  isAnalysisFailed: false,
  analysisError: null,
  gdprConsent: null,
  aggregatedWarnings: [],
  showNoneCriticalBalances: false,
};

const reducer = createReducer(
  initialState,

  on(TaxAssistantActions.resetAnalysisAction, () => ({
    ...initialState,
  })),
  on(TaxAssistantActions.startAnalysisAction, (state: State) => ({
    ...state,
    analysis: null,
    analysisProgress: 0,
    isAnalysisFailed: false,
    analysisError: null,
    fetchingAnalysis: true,
  })),
  on(
    TaxAssistantActions.getAnalysisAction,
    (state: State) => ({
      ...state,
      fetchingAnalysis: true,
    })
  ),
  on(
    TaxAssistantActions.setAnalysisAction,
    (state: State, { analysis }: any) => ({
      ...state,
      fetchingAnalysis: false,
      analysis,
    })
  ),
  on(
    TaxAssistantActions.setIsAnalysisFailedAction,
    (state: State, { isAnalysisFailed }: any) => ({
      ...state,
      isAnalysisFailed,
    })
  ),
  on(
    TaxAssistantActions.setAnalysisErrorAction,
    (state: State, { analysisError }: any) => ({
      ...state,
      analysisError,
    })
  ),
  on(
    TaxAssistantActions.setTokensWithNoPriceAction,
    (state: State, { tokensWithNoPrice }: any) => ({
      ...state,
      prices: {
        ...state.prices,
        tokensWithNoPrice,
      },
    })
  ),
  on(
    TaxAssistantActions.setTokensWithPriceAction,
    (state: State, { tokensWithPrice }: any) => ({
      ...state,
      prices: {
        ...state.prices,
        tokensWithPrice,
      },
    })
  ),
  on(
    TaxAssistantActions.setUnmatchedTransactionsWithoutLabelAction,
    (state: State, { unmatchedTransactionsWithoutLabel }: any) => ({
      ...state,
      unmatchedTransactionsWithoutLabel,
    })
  ),
  on(
    TaxAssistantActions.setAnalysisProgressAction,
    (state: State, { analysisProgress }: any) => ({
      ...state,
      analysisProgress,
    })
  ),
  on(
    TaxAssistantActions.setGDPRConsentAction,
    (state: State, { gdprConsent }: any) => ({
      ...state,
      gdprConsent,
    })
  ),
  on(
    TaxAssistantActions.setLastAnalysisWarningsAction,
    (state: State, { lastAnalysisWarnings }: any) => ({
      ...state,
      lastAnalysisWarnings,
    })
  ),
  on(
    TaxAssistantActions.setLastAnalysisAction,
    (state: State, { lastAnalysis }: any) => ({
      ...state,
      lastAnalysis,
    })
  ),
  on(
    TaxAssistantActions.setMatchedTransactionsAction,
    (state: State, { matchedTransactions }: any) => ({
      ...state,
      matchedTransactions,
    })
  ),
  on(
    TaxAssistantActions.setAggregatedWarningsAction,
    (state: State, { aggregatedWarnings }: any) => ({
      ...state,
      aggregatedWarnings,
    })
  ),
);

export const analysisReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
