import { Action, createReducer, on } from '@ngrx/store';
import { LossHarvestingDetails } from '../../models/loss-harvesting.model';
import * as ReportActions from '../actions/report.action';
import {
  TaxReport,
  TaxReportSummary,
} from './../../models/report.model';

export interface State {
  isLoading: boolean;
  isGeneratingReport: boolean;
  taxReport: TaxReport;
  lastReports: Map<string, TaxReport>;
  lossHarvestingDetails: LossHarvestingDetails;
  reportSummary: TaxReportSummary;
}

export const initialState: State = {
  isLoading: false,
  isGeneratingReport: false,
  taxReport: null,
  lastReports: null,
  lossHarvestingDetails: null,
  reportSummary: null,
};

const reducer = createReducer(
  initialState,
  on(ReportActions.loadTaxReportAction, (state: State) => ({
    ...state,
    isLoading: true,
    isGeneratingReport: false,
  })),
  on(ReportActions.generateTaxReportAction, (state: State) => ({
    ...state,
    taxReport: null,
    isLoading: true,
    isGeneratingReport: true,
  })),
  on(ReportActions.setTaxReportAction, (state: State, { taxReport }: any) => ({
    ...state,
    isLoading: !taxReport?.completed,
    taxReport,
  })),
  on(
    ReportActions.setLastReportsAction,
    (state: State, { lastReports }: any) => ({
      ...state,
      lastReports,
    })
  ),
  on(
    ReportActions.setTaxLossHarvestingAction,
    (state: State, { lossHarvestingDetails }: any) => ({
      ...state,
      lossHarvestingDetails,
    })
  ),
  on(
    ReportActions.setReportSummaryAction,
    (state: State, { reportSummary }: any) => ({
      ...state,
      reportSummary,
    })
  )
);

export const reportReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
