import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromPayment from '../../../store/selectors/payment.selector';
import { validatePaymentAction } from '../../../store/actions/payment.action';
import { takeUntil, map } from 'rxjs/operators';
import { Payment } from '../../../models/payment.model';

@Component({
  selector: `app-checkout-success-dialog`,
  standalone: true,
  imports: [CommonModule],
  templateUrl: `./checkout-success-dialog.component.html`,
  styleUrls: [`./checkout-success-dialog.component.scss`]
})
export class CheckoutSuccessDialogComponent implements OnInit, OnDestroy {
  paymentId: string;
  validatedPayment: Payment;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CheckoutSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { paymentId: string },
    private readonly paymentStore$: Store<fromPayment.State>,
  ) { }

  ngOnInit(): void {
    localStorage.removeItem(`payment_callback_url`);
    this.paymentId = this.data.paymentId;
    this.paymentStore$.dispatch(validatePaymentAction({ paymentId: this.paymentId }));

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectValidatedPayment),
        map((validatedPayment: Payment) => {
          this.validatedPayment = validatedPayment;

          if (this.validatedPayment) {
            const timeout = setTimeout(() => {
              this.dialogRef.close();
              clearTimeout(timeout);
            }, 3000);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
